import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDateTime: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type AccessListItem = {
  __typename?: 'AccessListItem';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export enum AccountType {
  Organization = 'Organization',
  Private = 'Private'
}

export type AccountTypeEvaluationInput = {
  accountType: AccountType;
};

export type AccountTypeEvaluationResponse = {
  __typename?: 'AccountTypeEvaluationResponse';
  success: Scalars['Boolean']['output'];
};

export type Alert = {
  __typename?: 'Alert';
  asset: Asset;
  policy: Policy;
  policyID: Scalars['ID']['output'];
  teamToken: Scalars['String']['output'];
  timestamp: Scalars['AWSDateTime']['output'];
};

export type AlertPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AlertResult = {
  __typename?: 'AlertResult';
  alerts: Array<Maybe<Alert>>;
  metadata: AlertResultMetadata;
};

export type AlertResultMetadata = {
  __typename?: 'AlertResultMetadata';
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type AlertsSorting = {
  direction: AlertsSortingDirection;
};

export enum AlertsSortingDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type Apex = {
  __typename?: 'Apex';
  confidence?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  origin: Scalars['String']['output'];
};

export type ApiKeyDetails = {
  __typename?: 'ApiKeyDetails';
  accessList: Array<AccessListItem>;
  apiKey: Scalars['String']['output'];
  canSignMessages: Scalars['Boolean']['output'];
  created: Scalars['Time']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  secretKey: Scalars['String']['output'];
  signMessages: Scalars['Boolean']['output'];
  updated?: Maybe<Scalars['Time']['output']>;
  updatedBy: Scalars['String']['output'];
};

export type ApiKeyListItem = {
  __typename?: 'ApiKeyListItem';
  apiKey: Scalars['String']['output'];
  created: Scalars['Time']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updated?: Maybe<Scalars['Time']['output']>;
  updatedBy: Scalars['String']['output'];
};

export type ApplicationScanHistoryFilter = {
  scanUUIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ApplicationScanHistoryItem = {
  __typename?: 'ApplicationScanHistoryItem';
  endedTime?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<ApplicationScanHistoryScanError>>>;
  fatal?: Maybe<ApplicationScanHistoryScanFatal>;
  isLatest: Scalars['Boolean']['output'];
  result?: Maybe<ApplicationScanHistoryScanResult>;
  startedByUserName: Scalars['String']['output'];
  startedMethod: ApplicationScanHistoryStartedMethod;
  startedTime: Scalars['Int']['output'];
  status: ApplicationScanHistoryScanStatus;
  stoppedByUserName: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type ApplicationScanHistoryPagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type ApplicationScanHistoryResponse = {
  __typename?: 'ApplicationScanHistoryResponse';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<ApplicationScanHistoryItem>>>;
};

export enum ApplicationScanHistoryScanError {
  RecordedCrawlingFailure = 'RecordedCrawlingFailure',
  RecordedLoginFailure = 'RecordedLoginFailure',
  ZeroCrawledUrLs = 'ZeroCrawledURLs'
}

export enum ApplicationScanHistoryScanFatal {
  UnableToReachEndpointDnsFailure = 'UnableToReachEndpointDNSFailure',
  UnableToReachEndpointNoHttpConnection = 'UnableToReachEndpointNoHTTPConnection',
  UnableToReachEndpointNoIp = 'UnableToReachEndpointNoIP',
  UnableToReachEndpointNoIPv4 = 'UnableToReachEndpointNoIPv4',
  UnableToReachEndpointNoOpenPort = 'UnableToReachEndpointNoOpenPort',
  UnableToReachEndpointUserAgentBlocked = 'UnableToReachEndpointUserAgentBlocked',
  Undefined = 'Undefined'
}

export enum ApplicationScanHistoryScanResult {
  Failure = 'Failure',
  Stopped = 'Stopped',
  Success = 'Success'
}

export enum ApplicationScanHistoryScanStatus {
  NotRunning = 'NotRunning',
  Running = 'Running',
  Stopping = 'Stopping',
  Unknown = 'Unknown'
}

export enum ApplicationScanHistoryStartedMethod {
  Api = 'API',
  Scheduled = 'Scheduled',
  Tool = 'Tool',
  Triggered = 'Triggered'
}

export type ApplicationScanProfile = {
  __typename?: 'ApplicationScanProfile';
  endpoint: Scalars['String']['output'];
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type Asset = {
  __typename?: 'Asset';
  applicationScanProfiles: Array<ApplicationScanProfile>;
  capabilities: Capabilities;
  /** The time domain appeared in the system by being added manually or via autodiscovery */
  createdAt: Scalars['Time']['output'];
  /** @deprecated use applicationScanProfiles instead */
  deepScanProfiles: Array<DeepScanProfile>;
  /** The time subdomain was discovered for the first time by autodiscovery */
  discovered?: Maybe<Scalars['Time']['output']>;
  /** Identifying if the asset is root */
  isRoot: Scalars['Boolean']['output'];
  lastSeen?: Maybe<Scalars['Time']['output']>;
  metadata: AssetMetadata;
  /** Shows if AM for the asset is enabled */
  monitored: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** Identifies if the asset currently is regarded as part of the attack surface */
  onAttackSurface: Scalars['Boolean']['output'];
  originCategories: Array<AssetOrigin>;
  /** @deprecated No longer supported */
  origins: Array<Scalars['String']['output']>;
  rootAsset?: Maybe<Asset>;
  /** The token of root asset for subdomains */
  rootAssetToken?: Maybe<Scalars['String']['output']>;
  status: AssetStatus;
  surfaceMonitoringHttpAssessment: SurfaceMonitoringHttpAssessment;
  teamToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  type: AssetType;
  /** Vulnerability severity graph for the asset */
  vulnerabilitySeverityGraph: PrioritizationSeverityGraphEntity;
};

export type AssetError = {
  __typename?: 'AssetError';
  message: Scalars['String']['output'];
  type: AssetErrorType;
};

export enum AssetErrorType {
  AssetExists = 'AssetExists',
  AssetNameIsInvalid = 'AssetNameIsInvalid',
  BlockedIp = 'BlockedIP',
  RootAssetAlreadyExists = 'RootAssetAlreadyExists',
  ServerError = 'ServerError',
  Unknown = 'Unknown'
}

export type AssetMetadata = {
  __typename?: 'AssetMetadata';
  assetToken: Scalars['String']['output'];
  countOfSubdomains: Scalars['Int']['output'];
};

export type AssetMonitoringBalance = {
  __typename?: 'AssetMonitoringBalance';
  capacity: Scalars['Int']['output'];
  currentUsage: Scalars['Int']['output'];
};

export type AssetMonitoringCustomHeader = {
  __typename?: 'AssetMonitoringCustomHeader';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AssetMonitoringCustomHeaderInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AssetMonitoringSettings = {
  __typename?: 'AssetMonitoringSettings';
  asset: Asset;
  bruteforcingEnabled: Scalars['Boolean']['output'];
  customHeaders?: Maybe<Array<AssetMonitoringCustomHeader>>;
  discardMaliciousRequests: Scalars['Boolean']['output'];
  enableActiveTLSAssessments: Scalars['Boolean']['output'];
  enableCertificateAssessments: Scalars['Boolean']['output'];
  enablePassiveTLSAssessments: Scalars['Boolean']['output'];
  enableTLSDiscovery: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  findingsEnabled: Scalars['Boolean']['output'];
  fingerprintingEnabled: Scalars['Boolean']['output'];
  includeDiscoveredPortsInSurfaceMonitoring: Scalars['Boolean']['output'];
  portScanningAvoidList: Array<Scalars['Int']['output']>;
  portScanningEnabled: Scalars['Boolean']['output'];
  portScanningForceList: Array<Scalars['Int']['output']>;
  portScanningIncludeRecommendedPorts: Scalars['Boolean']['output'];
  requestRateLimit: Scalars['Int']['output'];
  scrapingEnabled: Scalars['Boolean']['output'];
  sslAuditEnabled: Scalars['Boolean']['output'];
  subdomainTakeoverEnabled: Scalars['Boolean']['output'];
  surfaceDiscoveryThreshold: Scalars['Int']['output'];
  teamToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type AssetMonitoringSettingsInput = {
  assetName: Scalars['String']['input'];
  assetToken: Scalars['String']['input'];
  bruteforcingEnabled: Scalars['Boolean']['input'];
  customHeaders?: InputMaybe<Array<AssetMonitoringCustomHeaderInput>>;
  discardMaliciousRequests?: InputMaybe<Scalars['Boolean']['input']>;
  enableActiveTLSAssessments: Scalars['Boolean']['input'];
  enableCertificateAssessments: Scalars['Boolean']['input'];
  enablePassiveTLSAssessments: Scalars['Boolean']['input'];
  enableTLSDiscovery: Scalars['Boolean']['input'];
  enabled: Scalars['Boolean']['input'];
  findingsEnabled: Scalars['Boolean']['input'];
  fingerprintingEnabled: Scalars['Boolean']['input'];
  includeDiscoveredPortsInSurfaceMonitoring?: InputMaybe<Scalars['Boolean']['input']>;
  portScanningAvoidList: Array<Scalars['Int']['input']>;
  portScanningEnabled: Scalars['Boolean']['input'];
  portScanningForceList: Array<Scalars['Int']['input']>;
  portScanningIncludeRecommendedPorts: Scalars['Boolean']['input'];
  requestRateLimit: Scalars['Int']['input'];
  scrapingEnabled: Scalars['Boolean']['input'];
  sslAuditEnabled: Scalars['Boolean']['input'];
  subdomainTakeoverEnabled: Scalars['Boolean']['input'];
  surfaceDiscoveryThreshold?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetMonitoringSettingsResponse = {
  __typename?: 'AssetMonitoringSettingsResponse';
  defaultSettings?: Maybe<AssetMonitoringSettings>;
  settings?: Maybe<AssetMonitoringSettings>;
};

export type AssetOrigin = {
  __typename?: 'AssetOrigin';
  name: Scalars['String']['output'];
  type: AssetOriginType;
};

export enum AssetOriginType {
  Discovered = 'DISCOVERED',
  Imported = 'IMPORTED'
}

export type AssetPattern = {
  __typename?: 'AssetPattern';
  created: Scalars['Time']['output'];
  pattern: Scalars['String']['output'];
  subTeamToken: Scalars['String']['output'];
  teamToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated: Scalars['Time']['output'];
};

export type AssetPatternsResult = {
  __typename?: 'AssetPatternsResult';
  assetPatterns: Array<AssetPattern>;
};

export type AssetResult = {
  __typename?: 'AssetResult';
  assets: Array<Asset>;
  meta: AssetResultMetadata;
};

export type AssetResultMetadata = {
  __typename?: 'AssetResultMetadata';
  totalCount: Scalars['Int']['output'];
};

export type AssetSorting = {
  direction: SortingDirection;
  sortingField: AssetSortingField;
};

export enum AssetSortingField {
  Discovered = 'Discovered',
  LastSeen = 'LastSeen',
  Name = 'Name'
}

export enum AssetStatus {
  Undefined = 'Undefined',
  Unverified = 'Unverified',
  Verified = 'Verified'
}

export enum AssetType {
  Domain = 'Domain',
  Ip = 'IP'
}

export type AssetVerificationAttempt = {
  __typename?: 'AssetVerificationAttempt';
  error: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  targetUrl: Scalars['String']['output'];
};

export enum AssetVerificationType {
  DnsCname = 'DNSCname',
  DnsTxt = 'DNSTxt',
  File = 'File',
  GoogleAnalytics = 'GoogleAnalytics',
  Meta = 'Meta',
  Pattern = 'Pattern'
}

export type AutoDiscoveryTriggerResponse = {
  __typename?: 'AutoDiscoveryTriggerResponse';
  success: Scalars['Boolean']['output'];
};

export type BaseFinding = {
  __typename?: 'BaseFinding';
  affected_software: Array<BaseFindingAffectedSoftware>;
  command_line: Array<BaseFindingCommandLine>;
  correlation_id: Scalars['String']['output'];
  cwe: Scalars['Int']['output'];
  definition?: Maybe<BaseFindingDefenition>;
  details: Array<BaseFindingMatchCollection>;
  found_at: Scalars['String']['output'];
  found_by: Scalars['String']['output'];
  highlights: Array<BaseFindingHighlight2>;
  module?: Maybe<BaseFindingModule>;
  owasp: Array<BaseFindingOwasp>;
  references: Array<BaseFindingFindingReference>;
  score: Array<Score>;
  signature?: Maybe<BaseFindingSignature>;
  tags: Array<BaseFindingTag>;
  target?: Maybe<BaseFindingTarget>;
  timestamp: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  title_grouped: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
  vulnerable_resources?: Maybe<BaseFindingVulnerableResources>;
};

export type BaseFindingAffectedSoftware = {
  __typename?: 'BaseFindingAffectedSoftware';
  correlation_id: Scalars['String']['output'];
  fingerprint: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
  versions: Scalars['String']['output'];
};

export type BaseFindingCommandLine = {
  __typename?: 'BaseFindingCommandLine';
  type: Scalars['String']['output'];
  unix: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
  windows?: Maybe<Scalars['String']['output']>;
};

export type BaseFindingDefenition = {
  __typename?: 'BaseFindingDefenition';
  definition_id: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  details_title: Scalars['String']['output'];
  risk: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseFindingFindingReference = {
  __typename?: 'BaseFindingFindingReference';
  group: Scalars['String']['output'];
  link: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  source: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseFindingHighlight2 = {
  __typename?: 'BaseFindingHighlight2';
  length: Scalars['Int']['output'];
  node: BaseFindingHighlight2Node;
  offset: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseFindingHighlight2Node = {
  __typename?: 'BaseFindingHighlight2Node';
  field: Scalars['String']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type BaseFindingMatchCollection = {
  __typename?: 'BaseFindingMatchCollection';
  matches: Array<BaseFindingMatchCollectionMatch>;
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type BaseFindingMatchCollectionMatch = {
  __typename?: 'BaseFindingMatchCollectionMatch';
  type: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type BaseFindingModule = {
  __typename?: 'BaseFindingModule';
  category: Scalars['String']['output'];
  component: Scalars['String']['output'];
  crowdsourced: Scalars['Boolean']['output'];
  date_created: Scalars['String']['output'];
  date_updated: Scalars['String']['output'];
  module_id: Scalars['String']['output'];
  module_version: Scalars['String']['output'];
  product: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  version: Scalars['String']['output'];
  zeroday?: Maybe<BaseFindingModuleZeroDay>;
};

export type BaseFindingModuleZeroDay = {
  __typename?: 'BaseFindingModuleZeroDay';
  timestamp_discovered: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseFindingOwasp = {
  __typename?: 'BaseFindingOWASP';
  classification: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type BaseFindingSignature = {
  __typename?: 'BaseFindingSignature';
  current_signature_value: Scalars['String']['output'];
  current_signature_version: Scalars['String']['output'];
  legacy_signature_value: Scalars['String']['output'];
  legacy_signature_version: Scalars['String']['output'];
  type: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseFindingTag = {
  __typename?: 'BaseFindingTag';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseFindingTarget = {
  __typename?: 'BaseFindingTarget';
  action?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['Int']['output']>;
  httponly?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  port?: Maybe<Scalars['Int']['output']>;
  request_body?: Maybe<Scalars['String']['output']>;
  request_body_base64?: Maybe<Scalars['Boolean']['output']>;
  request_headers?: Maybe<Array<BaseFindingTargetHeader>>;
  request_method?: Maybe<Scalars['String']['output']>;
  request_version?: Maybe<Scalars['String']['output']>;
  response_body?: Maybe<Scalars['String']['output']>;
  response_body_base64?: Maybe<Scalars['Boolean']['output']>;
  response_encoding?: Maybe<Scalars['String']['output']>;
  response_headers?: Maybe<Array<BaseFindingTargetHeader>>;
  response_reason_phrase?: Maybe<Scalars['String']['output']>;
  response_status_code?: Maybe<Scalars['Int']['output']>;
  response_version?: Maybe<Scalars['String']['output']>;
  secure?: Maybe<Scalars['Boolean']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type BaseFindingTargetHeader = {
  __typename?: 'BaseFindingTargetHeader';
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  value: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseFindingVulnerableResources = {
  __typename?: 'BaseFindingVulnerableResources';
  expected_headers: Array<BaseFindingVulnerableResourcesExpectedHeader>;
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  version: Scalars['String']['output'];
  vulnerable_cookies: Array<BaseFindingVulnerableResourcesVulnerableCookie>;
  vulnerable_headers: Array<BaseFindingVulnerableResourcesVulnerableHeader>;
  vulnerable_variables: Array<BaseFindingVulnerableResourcesVulnerableVariable>;
};

export type BaseFindingVulnerableResourcesExpectedHeader = {
  __typename?: 'BaseFindingVulnerableResourcesExpectedHeader';
  direction: Scalars['String']['output'];
  header: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type BaseFindingVulnerableResourcesVulnerableCookie = {
  __typename?: 'BaseFindingVulnerableResourcesVulnerableCookie';
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type BaseFindingVulnerableResourcesVulnerableHeader = {
  __typename?: 'BaseFindingVulnerableResourcesVulnerableHeader';
  direction: Scalars['String']['output'];
  header: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type BaseFindingVulnerableResourcesVulnerableVariable = {
  __typename?: 'BaseFindingVulnerableResourcesVulnerableVariable';
  method: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type BasicAuth = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type BillingHistoryResponse = {
  __typename?: 'BillingHistoryResponse';
  invoices: Array<Invoice>;
};

export type BillingInternalResponse = {
  __typename?: 'BillingInternalResponse';
  customer: Customer;
};

export type BillingInvoicePdfResponse = {
  __typename?: 'BillingInvoicePDFResponse';
  downloadURL: Scalars['String']['output'];
};

export type Capabilities = {
  __typename?: 'Capabilities';
  monitoring?: Maybe<AssetMonitoringSettings>;
  patternVerification: PatternVerificationResult;
  token: Scalars['String']['output'];
};

export type CheckEmailInput = {
  email: Scalars['String']['input'];
};

export type CheckEmailResponse = {
  __typename?: 'CheckEmailResponse';
  isDisposableEmail: Scalars['Boolean']['output'];
  isFreeEmail: Scalars['Boolean']['output'];
};

export type ConnectorAwsCredBasedInput = {
  accessKey: Scalars['String']['input'];
  secretKey: Scalars['String']['input'];
};

export type ConnectorAwsRoleBasedInput = {
  externalID: Scalars['String']['input'];
  roleARN: Scalars['String']['input'];
};

export type ConnectorAlibabaInput = {
  accessKey: Scalars['String']['input'];
  accessKeySecret: Scalars['String']['input'];
  regionID: Scalars['String']['input'];
};

export type ConnectorAzureInput = {
  clientID: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  subscriptionID: Scalars['String']['input'];
  tenantID: Scalars['String']['input'];
};

export type ConnectorCloudflareInput = {
  apiToken: Scalars['String']['input'];
};

export type ConnectorConfig = {
  __typename?: 'ConnectorConfig';
  created: Scalars['String']['output'];
  intervalMinutes: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  provider: ConnectorProvider;
  status: ConnectorStatus;
  updated: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type ConnectorCreateInput = {
  name: Scalars['String']['input'];
  options: ConnectorOptionsInput;
  provider: ConnectorProvider;
};

export type ConnectorCreateResponse = {
  __typename?: 'ConnectorCreateResponse';
  uuid: Scalars['String']['output'];
};

export enum ConnectorCurrentStatus {
  Running = 'Running',
  Scheduled = 'Scheduled'
}

export type ConnectorDeleteInput = {
  uuid: Scalars['String']['input'];
};

export type ConnectorDeleteResponse = {
  __typename?: 'ConnectorDeleteResponse';
  success: Scalars['Boolean']['output'];
};

export type ConnectorDigitalOceanInput = {
  apiKey: Scalars['String']['input'];
};

export type ConnectorGcpInput = {
  accountKey: Scalars['String']['input'];
  projectID?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectorGenerateTokenInput = {
  kind: ConnectorTokenKind;
};

export type ConnectorGenerateTokenResponse = {
  __typename?: 'ConnectorGenerateTokenResponse';
  token: Scalars['String']['output'];
};

export type ConnectorGodaddyInput = {
  key: Scalars['String']['input'];
  secret: Scalars['String']['input'];
  shopperID?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectorListResponse = {
  __typename?: 'ConnectorListResponse';
  configs: Array<ConnectorConfig>;
};

export type ConnectorNs1Input = {
  key: Scalars['String']['input'];
};

export type ConnectorOptionsInput = {
  alibaba?: InputMaybe<ConnectorAlibabaInput>;
  awsCredBased?: InputMaybe<ConnectorAwsCredBasedInput>;
  awsRoleBased?: InputMaybe<ConnectorAwsRoleBasedInput>;
  azure?: InputMaybe<ConnectorAzureInput>;
  cloudflare?: InputMaybe<ConnectorCloudflareInput>;
  digitalOcean?: InputMaybe<ConnectorDigitalOceanInput>;
  gcp?: InputMaybe<ConnectorGcpInput>;
  godaddy?: InputMaybe<ConnectorGodaddyInput>;
  ns1?: InputMaybe<ConnectorNs1Input>;
};

export enum ConnectorProvider {
  AwsCredBased = 'AWSCredBased',
  AwsRoleBased = 'AWSRoleBased',
  Alibaba = 'Alibaba',
  Azure = 'Azure',
  Cloudflare = 'Cloudflare',
  DigitalOcean = 'DigitalOcean',
  Gcp = 'GCP',
  Godaddy = 'Godaddy',
  Ns1 = 'NS1'
}

export type ConnectorScheduleNowInput = {
  uuid: Scalars['String']['input'];
};

export type ConnectorScheduleNowResponse = {
  __typename?: 'ConnectorScheduleNowResponse';
  success: Scalars['Boolean']['output'];
};

export type ConnectorSetIntervalInput = {
  intervalMinutes: Scalars['Int']['input'];
  uuid: Scalars['String']['input'];
};

export type ConnectorSetIntervalResponse = {
  __typename?: 'ConnectorSetIntervalResponse';
  success: Scalars['Boolean']['output'];
};

export type ConnectorStatus = {
  __typename?: 'ConnectorStatus';
  currentStatus: ConnectorCurrentStatus;
  lastRunError?: Maybe<Scalars['String']['output']>;
  lastRunStatus: LastRunStatus;
  lastRunTimestamp?: Maybe<Scalars['String']['output']>;
  nextScheduledRun: Scalars['String']['output'];
};

export enum ConnectorTokenKind {
  AwsRoleBasedExternalId = 'AWSRoleBasedExternalID'
}

export type CreateApiKeyRequest = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateApiKeyResponse = {
  __typename?: 'CreateAPIKeyResponse';
  apiKeyDetails: ApiKeyDetails;
};

export enum CreateApplicationScanProfileError {
  /** The subdomain name is not valid according to domain naming standards */
  DomainNameIsMalformed = 'DomainNameIsMalformed',
  /** Reached number of application scanning slots for the team */
  MaximumNumberReached = 'MaximumNumberReached',
  None = 'None'
}

export type CreateApplicationScanProfileRequest = {
  /** The name (domain or IP address) of the root asset for which the scan profile is created */
  assetName: Scalars['String']['input'];
  /** The token of the root asset for which the scan profile is created */
  assetToken: Scalars['String']['input'];
  /** The scan profile name that can be empty */
  scanProfileName?: InputMaybe<Scalars['String']['input']>;
  /** The subdomain under the root asset that can be empty */
  subdomainName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateApplicationScanProfileResponse = {
  __typename?: 'CreateApplicationScanProfileResponse';
  /** If the creation failed, this is the relevant error that occurred */
  error?: Maybe<CreateApplicationScanProfileError>;
  /** If the creation succeeded, this is the (complete) scan profile endpoint for the newly created profile */
  scanProfileEndpoint?: Maybe<Scalars['String']['output']>;
  /** If the creation succeeded, this is the scan profile name for the newly created profile */
  scanProfileName?: Maybe<Scalars['String']['output']>;
  /** If the creation succeeded, this is the scan profile token for the newly created profile */
  scanProfileToken?: Maybe<Scalars['String']['output']>;
  /** Indicates whether creating the scan profile succeeded */
  success: Scalars['Boolean']['output'];
};

export enum CreateApplicationScanProfileWithScheduleError {
  /** The subdomain name is not valid according to domain naming standards */
  DomainNameIsMalformed = 'DomainNameIsMalformed',
  /** Reached number of application scanning slots for the team */
  MaximumNumberReached = 'MaximumNumberReached',
  None = 'None',
  /** User does not have access to create scan profile */
  UserNoAccessToScanProfile = 'UserNoAccessToScanProfile'
}

export type CreateApplicationScanProfileWithScheduleRequest = {
  assetName: Scalars['String']['input'];
  assetToken: Scalars['String']['input'];
  scanProfileName?: InputMaybe<Scalars['String']['input']>;
  scheduleScan: Scalars['Boolean']['input'];
  subdomainName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateApplicationScanProfileWithScheduleResponse = {
  __typename?: 'CreateApplicationScanProfileWithScheduleResponse';
  error?: Maybe<CreateApplicationScanProfileWithScheduleError>;
  nextScan?: Maybe<Scalars['Int']['output']>;
  scanProfileEndpoint?: Maybe<Scalars['String']['output']>;
  scanProfileName?: Maybe<Scalars['String']['output']>;
  scanProfileToken?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateAssetPatternInput = {
  pattern: Scalars['String']['input'];
  subTeamToken: Scalars['String']['input'];
};

export type CreateAssetPatternResponse = {
  __typename?: 'CreateAssetPatternResponse';
  assetPattern: AssetPattern;
};

export type CreateAssetResponse = {
  __typename?: 'CreateAssetResponse';
  asset?: Maybe<Asset>;
  error?: Maybe<AssetError>;
};

export type CreateFeedInput = {
  allInTeam: Scalars['Boolean']['input'];
  assetMonitoringTokens: Array<Scalars['String']['input']>;
  configuration: FeedConfigurationInput;
  events: Array<InputMaybe<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  scanProfileTokens: Array<Scalars['String']['input']>;
};

export type CreateMemberInput = {
  subTeamToken: Scalars['String']['input'];
  userToken: Scalars['String']['input'];
};

export type CreateMemberResponse = {
  __typename?: 'CreateMemberResponse';
  member: Member;
};

export type CreateMembersInput = {
  subTeamToken: Scalars['String']['input'];
  userTokens: Array<Scalars['String']['input']>;
};

export type CreateMembersResponse = {
  __typename?: 'CreateMembersResponse';
  members: Array<Member>;
};

export type CreateSubTeamInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateSubTeamResponse = {
  __typename?: 'CreateSubTeamResponse';
  subTeam: SubTeam;
};

export type CreateWorkatoCustomerResponse = {
  __typename?: 'CreateWorkatoCustomerResponse';
  success: Scalars['Boolean']['output'];
};

export enum Currency {
  Eur = 'EUR',
  Sek = 'SEK',
  Usd = 'USD'
}

export type Customer = {
  __typename?: 'Customer';
  canPayWithInvoice: Scalars['Boolean']['output'];
  canScaleAboveContract: Scalars['Boolean']['output'];
  canUseCustomPolicies: Scalars['Boolean']['output'];
  canUseEnterpriseAPI: Scalars['Boolean']['output'];
  canUseNewIntegrations: Scalars['Boolean']['output'];
  maxScanProfilesOnTrial: Scalars['Int']['output'];
  maxSurfaceMonitoringOnTrial: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  planType: PlanType;
  subscription?: Maybe<SubscriptionDetails>;
  token: Scalars['String']['output'];
};

export type DeepScanFilters = {
  text?: InputMaybe<Scalars['String']['input']>;
};

export type DeepScanMetadata = {
  __typename?: 'DeepScanMetadata';
  totalCount: Scalars['Int']['output'];
};

export type DeepScanProfile = {
  __typename?: 'DeepScanProfile';
  endpoint: Scalars['String']['output'];
  lastScan?: Maybe<Scalars['Time']['output']>;
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type DeepScanReport = {
  __typename?: 'DeepScanReport';
  assetName: Scalars['String']['output'];
  reportToken: Scalars['String']['output'];
  scanProfileName: Scalars['String']['output'];
  scanProfileToken: Scalars['String']['output'];
  startedAt: Scalars['Int']['output'];
  stoppedAt: Scalars['Int']['output'];
};

export type DeepScanReportEdge = {
  __typename?: 'DeepScanReportEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<DeepScanReport>;
};

export type DeepScanReportsConnection = {
  __typename?: 'DeepScanReportsConnection';
  edges?: Maybe<Array<Maybe<DeepScanReportEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DeepScansResult = {
  __typename?: 'DeepScansResult';
  deepScanProfiles: Array<DeepScanProfile>;
  meta: DeepScanMetadata;
};

export type DeleteApplicationScanProfileResponse = {
  __typename?: 'DeleteApplicationScanProfileResponse';
  /** Indicates whether deleting the scan profile succeeded */
  success: Scalars['Boolean']['output'];
};

export type DeleteAssetResponse = {
  __typename?: 'DeleteAssetResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DeleteFeedResponse = {
  __typename?: 'DeleteFeedResponse';
  success: Scalars['Boolean']['output'];
};

export type EmptyResponse = {
  __typename?: 'EmptyResponse';
  success: Scalars['Boolean']['output'];
};

export type Entry = {
  __typename?: 'Entry';
  category: Scalars['String']['output'];
  date: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum ExportFileFormat {
  Csv = 'CSV',
  Json = 'JSON'
}

export type Feed = {
  __typename?: 'Feed';
  allAssetMonitoring: Scalars['Boolean']['output'];
  allInTeam: Scalars['Boolean']['output'];
  allScanProfile: Scalars['Boolean']['output'];
  assetMonitoringTokens: Array<Scalars['String']['output']>;
  configuration: FeedConfigurationBroken;
  createdAt: Scalars['Int']['output'];
  createdBy: Scalars['String']['output'];
  events: Array<Scalars['String']['output']>;
  feedToken: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scanProfileTokens: Array<Scalars['String']['output']>;
  teamToken: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
  updatedBy: Scalars['String']['output'];
};

export type FeedConfiguration = {
  __typename?: 'FeedConfiguration';
  configurationToken: Scalars['String']['output'];
  createdTimestamp?: Maybe<Scalars['Int']['output']>;
  customData: FeedConfigurationCustomData;
  id?: Maybe<Scalars['Int']['output']>;
  metadata: FeedConfigurationMetadata;
  serviceData: FeedConfigurationServiceData;
  serviceName: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  teamToken?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedTimestamp?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type FeedConfigurationBroken = {
  __typename?: 'FeedConfigurationBroken';
  configurationToken: Scalars['String']['output'];
  createdTimestamp?: Maybe<Scalars['String']['output']>;
  customData: FeedConfigurationCustomData;
  id?: Maybe<Scalars['Int']['output']>;
  metadata: FeedConfigurationMetadata;
  serviceData: FeedConfigurationServiceData;
  serviceName: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  teamToken?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedTimestamp?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type FeedConfigurationCustomData = {
  __typename?: 'FeedConfigurationCustomData';
  issueId?: Maybe<Scalars['String']['output']>;
  issueName?: Maybe<Scalars['String']['output']>;
  projectKey?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
};

export type FeedConfigurationCustomDataInput = {
  issueId?: InputMaybe<Scalars['String']['input']>;
  issueName?: InputMaybe<Scalars['String']['input']>;
  projectKey?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
};

export type FeedConfigurationInput = {
  configurationToken: Scalars['String']['input'];
  createdTimestamp?: InputMaybe<Scalars['Int']['input']>;
  customData: FeedConfigurationCustomDataInput;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata: FeedConfigurationMetadataInput;
  serviceData: FeedConfigurationServiceDataInput;
  serviceName: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  teamToken?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedTimestamp?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type FeedConfigurationMetadata = {
  __typename?: 'FeedConfigurationMetadata';
  channelName?: Maybe<Scalars['String']['output']>;
  issueName?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
};

export type FeedConfigurationMetadataInput = {
  channelName?: InputMaybe<Scalars['String']['input']>;
  issueName?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export type FeedConfigurationServiceData = {
  __typename?: 'FeedConfigurationServiceData';
  apiToken?: Maybe<Scalars['String']['output']>;
  authToken?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  jiraUrl?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type FeedConfigurationServiceDataInput = {
  apiToken?: InputMaybe<Scalars['String']['input']>;
  authToken?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  jiraUrl?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type FeedConfigurationToken = {
  __typename?: 'FeedConfigurationToken';
  configurationToken: Scalars['String']['output'];
  createdTimestamp: Scalars['Int']['output'];
  slackButtonUrl?: Maybe<Scalars['String']['output']>;
  updatedTimestamp: Scalars['Int']['output'];
};

export type FeedEdge = {
  __typename?: 'FeedEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Feed;
};

export type FeedbackVulnerabilityDetailsInput = {
  feedback: FeedbackVulnerabilityFeedback;
  freeText?: InputMaybe<Scalars['String']['input']>;
  sections?: InputMaybe<Array<FeedbackVulnerabilityDetailsSection>>;
  vulnerabilityUUID: Scalars['ID']['input'];
};

export type FeedbackVulnerabilityDetailsResponse = {
  __typename?: 'FeedbackVulnerabilityDetailsResponse';
  status: FeedbackVulnerabilityStatus;
};

export enum FeedbackVulnerabilityDetailsSection {
  Description = 'DESCRIPTION',
  Details = 'DETAILS',
  References = 'REFERENCES',
  RequestResponse = 'REQUEST_RESPONSE',
  Title = 'TITLE'
}

export enum FeedbackVulnerabilityFeedback {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export enum FeedbackVulnerabilityStatus {
  BadRequest = 'BAD_REQUEST',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  Ok = 'OK'
}

export type FeedsConnection = {
  __typename?: 'FeedsConnection';
  edges: Array<FeedEdge>;
  pageInfo?: Maybe<FeedsPageInfo>;
};

export type FeedsPageInfo = {
  __typename?: 'FeedsPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum FilterAccessLevel {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Team = 'TEAM'
}

export type FilterParameters = {
  __typename?: 'FilterParameters';
  assetTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cvss2ScoreLowerBound?: Maybe<Scalars['Float']['output']>;
  cvss2ScoreUpperBound?: Maybe<Scalars['Float']['output']>;
  cvss3ScoreLowerBound?: Maybe<Scalars['Float']['output']>;
  cvss3ScoreUpperBound?: Maybe<Scalars['Float']['output']>;
  cvss3_1ScoreLowerBound?: Maybe<Scalars['Float']['output']>;
  cvss3_1ScoreUpperBound?: Maybe<Scalars['Float']['output']>;
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  endDateLowerBound?: Maybe<Scalars['String']['output']>;
  endDateUpperBound?: Maybe<Scalars['String']['output']>;
  exclude?: Maybe<FilterParametersExclude>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastSeenDuration?: Maybe<Scalars['String']['output']>;
  lastSeenLowerBound?: Maybe<Scalars['String']['output']>;
  lastSeenUpperBound?: Maybe<Scalars['String']['output']>;
  rootAssetTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  scanProfileTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  scanSource?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  scoreLowerBound?: Maybe<Scalars['Float']['output']>;
  scoreUpperBound?: Maybe<Scalars['Float']['output']>;
  searchQuery?: Maybe<Scalars['String']['output']>;
  severities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  startDateLowerBound?: Maybe<Scalars['String']['output']>;
  startDateUpperBound?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Array<VulnerabilityStatus>>;
  tagUUIDs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FilterParametersExclude = {
  __typename?: 'FilterParametersExclude';
  customTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FilterParametersExcludeInput = {
  customTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterParametersExcludeInputV2 = {
  customTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterParametersExcludeV2 = {
  __typename?: 'FilterParametersExcludeV2';
  customTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FilterParametersInput = {
  assetTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cvss2ScoreLowerBound?: InputMaybe<Scalars['Float']['input']>;
  cvss2ScoreUpperBound?: InputMaybe<Scalars['Float']['input']>;
  cvss3ScoreLowerBound?: InputMaybe<Scalars['Float']['input']>;
  cvss3ScoreUpperBound?: InputMaybe<Scalars['Float']['input']>;
  cvss3_1ScoreLowerBound?: InputMaybe<Scalars['Float']['input']>;
  cvss3_1ScoreUpperBound?: InputMaybe<Scalars['Float']['input']>;
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDateLowerBound?: InputMaybe<Scalars['String']['input']>;
  endDateUpperBound?: InputMaybe<Scalars['String']['input']>;
  exclude?: InputMaybe<FilterParametersExcludeInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeenDuration?: InputMaybe<Scalars['String']['input']>;
  lastSeenLowerBound?: InputMaybe<Scalars['String']['input']>;
  lastSeenUpperBound?: InputMaybe<Scalars['String']['input']>;
  rootAssetTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scanProfileTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scanSource?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scoreLowerBound?: InputMaybe<Scalars['Float']['input']>;
  scoreUpperBound?: InputMaybe<Scalars['Float']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  severities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateLowerBound?: InputMaybe<Scalars['String']['input']>;
  startDateUpperBound?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<VulnerabilityStatus>>;
  tagUUIDs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterParametersInputV2 = {
  assetTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cvss3_1ScoreLowerBound?: InputMaybe<Scalars['Float']['input']>;
  cvss3_1ScoreUpperBound?: InputMaybe<Scalars['Float']['input']>;
  exclude?: InputMaybe<FilterParametersExcludeInputV2>;
  lastSeenDuration?: InputMaybe<Scalars['String']['input']>;
  scanProfileTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scanSource?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  severities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<VulnerabilityStatusV2>>>;
  subteam?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterParametersV2 = {
  __typename?: 'FilterParametersV2';
  assetTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  customTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cvss3_1ScoreLowerBound?: Maybe<Scalars['Float']['output']>;
  cvss3_1ScoreUpperBound?: Maybe<Scalars['Float']['output']>;
  exclude?: Maybe<FilterParametersExcludeV2>;
  lastSeenDuration?: Maybe<Scalars['String']['output']>;
  scanProfileTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  scanSource?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  searchQuery?: Maybe<Scalars['String']['output']>;
  severities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Array<Maybe<VulnerabilityStatusV2>>>;
  subteam?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FilterValues = {
  __typename?: 'FilterValues';
  assetToken: Array<Scalars['String']['output']>;
  assets: Array<Asset>;
  customTags: Array<Scalars['String']['output']>;
  hostname: Array<Scalars['String']['output']>;
  rootAssetToken?: Maybe<Array<Scalars['String']['output']>>;
  scanSource: Array<Scalars['String']['output']>;
  targetToken: Array<Scalars['String']['output']>;
  title: Array<Scalars['String']['output']>;
};

export type Filters = {
  includeArchivedAssets?: InputMaybe<Scalars['Boolean']['input']>;
  monitored?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onAttackSurfaceOnly?: InputMaybe<Scalars['Boolean']['input']>;
  resolvableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  rootAssetToken?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AssetStatus>;
  subTeamTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<AssetType>;
};

export type FindingFilterItem = {
  name: Scalars['String']['input'];
  operator: Scalars['String']['input'];
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FindingFiltersInput = {
  filterItems?: InputMaybe<Array<InputMaybe<FindingFilterItem>>>;
};

export enum FindingScanSource {
  ApplicationScanning = 'ApplicationScanning',
  SurfaceMonitoring = 'SurfaceMonitoring'
}

export enum FindingSeverity {
  Critical = 'Critical',
  High = 'High',
  Information = 'Information',
  Low = 'Low',
  Medium = 'Medium'
}

export enum FindingStatus {
  AcceptedRisk = 'AcceptedRisk',
  Active = 'Active',
  FalsePositive = 'FalsePositive',
  New = 'New',
  Patched = 'Patched',
  Regression = 'Regression'
}

export enum FindingsSortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type GetMultipleSubDomainClusteringResultInput = {
  domain: Array<Scalars['String']['input']>;
};

export type GetMultipleSubDomainClusteringResultResponse = {
  __typename?: 'GetMultipleSubDomainClusteringResultResponse';
  subDomainClusteringResult: Array<SubDomainClusteringResult>;
};

export type GetSslLogSubDomainsCountInput = {
  age: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

export type GetSslLogSubDomainsCountResponse = {
  __typename?: 'GetSSLLogSubDomainsCountResponse';
  subDomainsCount: Scalars['Int']['output'];
};

export type GetSslLogSubDomainsInput = {
  domain: Scalars['String']['input'];
  maxNumberOfSubdomains: Scalars['Int']['input'];
};

export type GetSslLogSubDomainsListInput = {
  age: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  maxNumberOfSubdomains: Scalars['String']['input'];
};

export type GetSslLogSubDomainsListResponse = {
  __typename?: 'GetSSLLogSubDomainsListResponse';
  subDomains: Array<Scalars['String']['output']>;
};

export type GetSslLogSubDomainsResponse = {
  __typename?: 'GetSSLLogSubDomainsResponse';
  subDomains: Array<Scalars['String']['output']>;
  subDomainsCount: Scalars['Int']['output'];
};

export type GetScanUuiDsResponse = {
  __typename?: 'GetScanUUIDsResponse';
  scanUUIDs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GetSubDomainClusteringResultInput = {
  domain: Scalars['String']['input'];
};

export type GetSubDomainClusteringResultResponse = {
  __typename?: 'GetSubDomainClusteringResultResponse';
  subDomainClusteringResult: Array<SubDomainClusteringResult>;
};

export type InitMultipleSubDomainClusteringInput = {
  domain: Array<Scalars['String']['input']>;
  source: SubDomainSource;
};

export type InitSubDomainClusteringInput = {
  domain: Scalars['String']['input'];
  source: SubDomainSource;
};

export type Invite = {
  __typename?: 'Invite';
  created: Scalars['Time']['output'];
  email: Scalars['String']['output'];
  expiry: Scalars['Time']['output'];
  role: Scalars['String']['output'];
  status: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type InviteUserError = {
  __typename?: 'InviteUserError';
  email: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  role: Role;
};

export type InviteUserResponse = {
  __typename?: 'InviteUserResponse';
  errors?: Maybe<Array<Maybe<InviteUserError>>>;
  success: Scalars['Boolean']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  amountTotal: Scalars['Float']['output'];
  currency: Currency;
  issueDate: Scalars['Time']['output'];
  status: InvoiceStatus;
  token: Scalars['String']['output'];
};

export enum InvoiceStatus {
  Notpaid = 'NOTPAID',
  Paid = 'PAID',
  Paymentdue = 'PAYMENTDUE',
  Pending = 'PENDING',
  Posted = 'POSTED',
  Voided = 'VOIDED'
}

export type IsTeamActive = {
  __typename?: 'IsTeamActive';
  isActive: Scalars['Boolean']['output'];
};

export type JiraAuthRequest = {
  __typename?: 'JiraAuthRequest';
  apiToken?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type JiraIssue = {
  __typename?: 'JiraIssue';
  fields?: Maybe<JiraIssueFields>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type JiraIssueFields = {
  __typename?: 'JiraIssueFields';
  description?: Maybe<Scalars['String']['output']>;
  epicName?: Maybe<Scalars['String']['output']>;
  project?: Maybe<JiraProject>;
  summary?: Maybe<Scalars['String']['output']>;
  type?: Maybe<JiraIssueType>;
};

export type JiraIssueType = {
  __typename?: 'JiraIssueType';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type JiraProject = {
  __typename?: 'JiraProject';
  description?: Maybe<Scalars['String']['output']>;
  expand?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectTypeKey?: Maybe<Scalars['String']['output']>;
  self?: Maybe<Scalars['String']['output']>;
};

export enum LastRunStatus {
  Failure = 'Failure',
  NotAvailable = 'NotAvailable',
  Success = 'Success'
}

export type LimitOffsetPagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type ListTestsResponse = {
  __typename?: 'ListTestsResponse';
  tests: Array<Test>;
};

export type Member = {
  __typename?: 'Member';
  created: Scalars['Time']['output'];
  subTeamToken: Scalars['String']['output'];
  teamToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated: Scalars['Time']['output'];
  userToken: Scalars['String']['output'];
};

export type MembersResult = {
  __typename?: 'MembersResult';
  members: Array<Member>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addTagsToVulnerabilities: EmptyResponse;
  connectorCreate: ConnectorCreateResponse;
  connectorDelete: ConnectorDeleteResponse;
  connectorGenerateToken: ConnectorGenerateTokenResponse;
  connectorScheduleNow: ConnectorScheduleNowResponse;
  connectorSetInterval: ConnectorSetIntervalResponse;
  createAPIKey: CreateApiKeyResponse;
  createAccountTypeEvaluation: AccountTypeEvaluationResponse;
  createApplicationScanProfile: CreateApplicationScanProfileResponse;
  createApplicationScanProfileWithSchedule: CreateApplicationScanProfileWithScheduleResponse;
  createAsset: CreateAssetResponse;
  createAssetPattern: CreateAssetPatternResponse;
  createFeed: Feed;
  createFeedConfiguration?: Maybe<FeedConfigurationToken>;
  createMember: CreateMemberResponse;
  createMembers: CreateMembersResponse;
  createSubTeam: CreateSubTeamResponse;
  createVulnerabilityExport: VulnerabilityExportMetadata;
  /** Create a new vulnerability filter */
  createVulnerabilityFilter: VulnerabilityFilter;
  /** Create a new vulnerability filter */
  createVulnerabilityFilterV2: VulnerabilityFilterV2;
  /** Create a new Vulnerability Tag */
  createVulnerabilityTag?: Maybe<VulnerabilityTag>;
  createWorkatoCustomer: CreateWorkatoCustomerResponse;
  deleteAlert?: Maybe<Alert>;
  deleteApplicationScanProfile: DeleteApplicationScanProfileResponse;
  deleteAsset: DeleteAssetResponse;
  deleteFeed: DeleteFeedResponse;
  deleteVulnerabilityExport?: Maybe<VulnerabilityExportMetadata>;
  /** Delete given vulnerability filter */
  deleteVulnerabilityFilter?: Maybe<VulnerabilityFilter>;
  /** Delete given vulnerability filter */
  deleteVulnerabilityFilterV2?: Maybe<VulnerabilityFilterV2>;
  /** Delete given tag */
  deleteVulnerabilityTag?: Maybe<VulnerabilityTag>;
  initMultipleSubDomainClustering: Scalars['Boolean']['output'];
  initSubDomainClustering: Scalars['Boolean']['output'];
  inviteUser: InviteUserResponse;
  policyCreatePolicy: PolicyCreatePolicyResult;
  policyDeletePolicy: PolicyDeletePolicyResult;
  publicAPIV3CreateKey: PublicApiv3CreateKeyResponse;
  publicAPIV3DeleteKey: PublicApiv3DeleteKeyResponse;
  removeApexSuggestion?: Maybe<Apex>;
  removeAssetPattern: RemoveAssetPatternResponse;
  removeMember: RemoveMemberResponse;
  removeMembers: RemoveMembersResponse;
  removeMembership: RemoveMembershipResponse;
  removeSubTeam: RemoveSubTeamResponse;
  removeTagsFromVulnerabilities: EmptyResponse;
  reportFeedbackVulnerabilityDetails: FeedbackVulnerabilityDetailsResponse;
  revokeAPIKey: RevokeApiKeyResponse;
  revokeInvite: RevokeInviteResponse;
  saveAssetMonitoringSettings?: Maybe<AssetMonitoringSettings>;
  sendCSMExtendSPRequestEmail: Scalars['Boolean']['output'];
  startApplicationScan: StartScanResponse;
  startApplicationScansWithDelay: StartScansWithDelayResponse;
  stopApplicationScan: StopScanResponse;
  testFeedConfiguration?: Maybe<TestFeedConfigurationResponse>;
  triggerAutoDiscovery: AutoDiscoveryTriggerResponse;
  updateAPIKey: UpdateApiKeyResponse;
  updateAssetPattern: UpdateAssetPatternResponse;
  updateFeed: Feed;
  updateFeedConfiguration: FeedConfiguration;
  updateSubTeam: UpdateSubTeamResponse;
  updateUserRole: UpdateUserRoleResponse;
  updateVulnerabilitiesStatus: EmptyResponse;
  /** Update given vulnerability filter */
  updateVulnerabilityFilter: VulnerabilityFilter;
  /** Update given tag */
  updateVulnerabilityTag?: Maybe<VulnerabilityTag>;
  verifyAsset: VerifyAssetResponse;
  wafScheduleRecheck: WafScheduleRecheckResponse;
};


export type MutationAddTagsToVulnerabilitiesArgs = {
  tags: Array<Scalars['String']['input']>;
  vulnerabilities: Array<Scalars['String']['input']>;
};


export type MutationConnectorCreateArgs = {
  input: ConnectorCreateInput;
};


export type MutationConnectorDeleteArgs = {
  input: ConnectorDeleteInput;
};


export type MutationConnectorGenerateTokenArgs = {
  input: ConnectorGenerateTokenInput;
};


export type MutationConnectorScheduleNowArgs = {
  input: ConnectorScheduleNowInput;
};


export type MutationConnectorSetIntervalArgs = {
  input: ConnectorSetIntervalInput;
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyRequest;
};


export type MutationCreateAccountTypeEvaluationArgs = {
  input: AccountTypeEvaluationInput;
};


export type MutationCreateApplicationScanProfileArgs = {
  input: CreateApplicationScanProfileRequest;
};


export type MutationCreateApplicationScanProfileWithScheduleArgs = {
  input: CreateApplicationScanProfileWithScheduleRequest;
};


export type MutationCreateAssetArgs = {
  assetName: Scalars['String']['input'];
};


export type MutationCreateAssetPatternArgs = {
  input: CreateAssetPatternInput;
};


export type MutationCreateFeedArgs = {
  feed: CreateFeedInput;
};


export type MutationCreateFeedConfigurationArgs = {
  application: Scalars['String']['input'];
  customData: FeedConfigurationCustomDataInput;
  serviceData: FeedConfigurationServiceDataInput;
};


export type MutationCreateMemberArgs = {
  input: CreateMemberInput;
};


export type MutationCreateMembersArgs = {
  input: CreateMembersInput;
};


export type MutationCreateSubTeamArgs = {
  input: CreateSubTeamInput;
};


export type MutationCreateVulnerabilityExportArgs = {
  fileFormat: ExportFileFormat;
  filter?: InputMaybe<VulnerabilitiesExportFilterInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateVulnerabilityFilterArgs = {
  accessLevel: FilterAccessLevel;
  name: Scalars['String']['input'];
  params: FilterParametersInput;
};


export type MutationCreateVulnerabilityFilterV2Args = {
  filterName: Scalars['String']['input'];
  params: FilterParametersInputV2;
};


export type MutationCreateVulnerabilityTagArgs = {
  color: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationDeleteAlertArgs = {
  assetToken: Scalars['ID']['input'];
  policyID: Scalars['ID']['input'];
};


export type MutationDeleteApplicationScanProfileArgs = {
  scanProfileToken: Scalars['String']['input'];
};


export type MutationDeleteAssetArgs = {
  assetToken: Scalars['String']['input'];
};


export type MutationDeleteFeedArgs = {
  feedToken: Scalars['String']['input'];
};


export type MutationDeleteVulnerabilityExportArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationDeleteVulnerabilityFilterArgs = {
  uuid: Scalars['ID']['input'];
};


export type MutationDeleteVulnerabilityFilterV2Args = {
  filterName: Scalars['String']['input'];
};


export type MutationDeleteVulnerabilityTagArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationInitMultipleSubDomainClusteringArgs = {
  input?: InputMaybe<InitMultipleSubDomainClusteringInput>;
};


export type MutationInitSubDomainClusteringArgs = {
  input?: InputMaybe<InitSubDomainClusteringInput>;
};


export type MutationInviteUserArgs = {
  input: Array<InviteUserInput>;
};


export type MutationPolicyCreatePolicyArgs = {
  createPolicyInput: PolicyCreatePolicyInput;
};


export type MutationPolicyDeletePolicyArgs = {
  deletePolicyInput: PolicyDeletePolicyInput;
};


export type MutationPublicApiv3CreateKeyArgs = {
  input: PublicApiv3CreateKeyInput;
};


export type MutationPublicApiv3DeleteKeyArgs = {
  input: PublicApiv3DeleteKeyInput;
};


export type MutationRemoveApexSuggestionArgs = {
  name: Scalars['String']['input'];
};


export type MutationRemoveAssetPatternArgs = {
  input: RemoveAssetPatternInput;
};


export type MutationRemoveMemberArgs = {
  input: RemoveMemberInput;
};


export type MutationRemoveMembersArgs = {
  input: RemoveMembersInput;
};


export type MutationRemoveMembershipArgs = {
  input: RemoveMembershipInput;
};


export type MutationRemoveSubTeamArgs = {
  input: RemoveSubTeamInput;
};


export type MutationRemoveTagsFromVulnerabilitiesArgs = {
  tags: Array<Scalars['String']['input']>;
  vulnerabilities: Array<Scalars['String']['input']>;
};


export type MutationReportFeedbackVulnerabilityDetailsArgs = {
  feedbackData: FeedbackVulnerabilityDetailsInput;
};


export type MutationRevokeApiKeyArgs = {
  apiKey: Scalars['String']['input'];
};


export type MutationRevokeInviteArgs = {
  input: RevokeInviteInput;
};


export type MutationSaveAssetMonitoringSettingsArgs = {
  settings: AssetMonitoringSettingsInput;
};


export type MutationSendCsmExtendSpRequestEmailArgs = {
  input?: InputMaybe<SendCsmExtendSpRequestEmailInput>;
};


export type MutationStartApplicationScanArgs = {
  scanProfileToken: Scalars['String']['input'];
};


export type MutationStartApplicationScansWithDelayArgs = {
  input: StartScansWithDelayRequest;
};


export type MutationStopApplicationScanArgs = {
  scanProfileToken: Scalars['String']['input'];
};


export type MutationTestFeedConfigurationArgs = {
  application: Scalars['String']['input'];
  configurationToken: Scalars['String']['input'];
};


export type MutationTriggerAutoDiscoveryArgs = {
  assetName: Scalars['String']['input'];
  bruteforce: Scalars['Boolean']['input'];
  scrape: Scalars['Boolean']['input'];
};


export type MutationUpdateApiKeyArgs = {
  input: UpdateApiKeyInput;
};


export type MutationUpdateAssetPatternArgs = {
  input: UpdateAssetPatternInput;
};


export type MutationUpdateFeedArgs = {
  feed: CreateFeedInput;
  feedToken: Scalars['String']['input'];
};


export type MutationUpdateFeedConfigurationArgs = {
  application: Scalars['String']['input'];
  configuration: FeedConfigurationInput;
};


export type MutationUpdateSubTeamArgs = {
  input: UpdateSubTeamInput;
};


export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};


export type MutationUpdateVulnerabilitiesStatusArgs = {
  action: VulnerabilityStatusAction;
  comment?: InputMaybe<Scalars['String']['input']>;
  status: VulnerabilityStatus;
  vulnerabilities: Array<Scalars['String']['input']>;
};


export type MutationUpdateVulnerabilityFilterArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<FilterParametersInput>;
  uuid: Scalars['ID']['input'];
};


export type MutationUpdateVulnerabilityTagArgs = {
  params: UpdateVulnerabilityTagInput;
  uuid: Scalars['String']['input'];
};


export type MutationVerifyAssetArgs = {
  basicAuth?: InputMaybe<BasicAuth>;
  input: VerifyAssetRequest;
};


export type MutationWafScheduleRecheckArgs = {
  input: WafScheduleRecheckInput;
};

export type OrderBy = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export type OverviewSeverityGraph = {
  __typename?: 'OverviewSeverityGraph';
  critical: Scalars['Int']['output'];
  high: Scalars['Int']['output'];
  low: Scalars['Int']['output'];
  medium: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  /** Max allowed value for limit is 2000. If larger value is supplied the limit will be set to max allowed value. */
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PatternVerificationResult = {
  __typename?: 'PatternVerificationResult';
  assetName: Scalars['String']['output'];
  hasMatchingPattern: Scalars['Boolean']['output'];
};

export enum PaymentCycle {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY'
}

export enum PaymentMethod {
  Adyen = 'ADYEN',
  Invoice = 'INVOICE',
  Missing = 'MISSING'
}

export enum PlanType {
  Enterprise = 'ENTERPRISE',
  External = 'EXTERNAL',
  Noncommercial = 'NONCOMMERCIAL',
  Professional = 'PROFESSIONAL',
  Staff = 'STAFF',
  Starter = 'STARTER'
}

export type Policy = {
  __typename?: 'Policy';
  id: Scalars['ID']['output'];
};

export type PolicyCreatePolicyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filters: Array<PolicyDomainFilters>;
  name: Scalars['String']['input'];
  severity: PolicySeverity;
};

export type PolicyCreatePolicyResult = {
  __typename?: 'PolicyCreatePolicyResult';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type PolicyDefinition = {
  __typename?: 'PolicyDefinition';
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  filters: Array<PolicyDomainFiltersDefinition>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  severity?: Maybe<PolicySeverity>;
};

export type PolicyDeletePolicyInput = {
  id: Scalars['String']['input'];
};

export type PolicyDeletePolicyResult = {
  __typename?: 'PolicyDeletePolicyResult';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type PolicyDomainFilters = {
  name: PolicyDomainFiltersName;
  operator: PolicyFilterOperator;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PolicyDomainFiltersDefinition = {
  __typename?: 'PolicyDomainFiltersDefinition';
  name: PolicyDomainFiltersName;
  operator: PolicyFilterOperator;
  value?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum PolicyDomainFiltersName {
  ActiveAsNameCount = 'ActiveAsNameCount',
  ActiveAsNames = 'ActiveAsNames',
  ActiveCountries = 'ActiveCountries',
  ActiveCountryCount = 'ActiveCountryCount',
  ActiveIpAddresses = 'ActiveIPAddresses',
  ActiveIpAddressCount = 'ActiveIpAddressCount',
  ActiveTechCount = 'ActiveTechCount',
  ActiveTechVersions = 'ActiveTechVersions',
  ActiveTechs = 'ActiveTechs',
  DnsRecordTypes = 'DNSRecordTypes',
  DnsRecordTypesCount = 'DNSRecordTypesCount',
  DnsResponseCodes = 'DNSResponseCodes',
  Disappeared = 'Disappeared',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  OpenPortCount = 'OpenPortCount',
  OpenPorts = 'OpenPorts',
  SurfaceState = 'SurfaceState'
}

export enum PolicyFilterOperator {
  After = 'After',
  Before = 'Before',
  Contains = 'Contains',
  ContainsAllOf = 'ContainsAllOf',
  ContainsAnyOf = 'ContainsAnyOf',
  ContainsItemNotInList = 'ContainsItemNotInList',
  DoesNotContain = 'DoesNotContain',
  DoesNotContainAllOf = 'DoesNotContainAllOf',
  DoesNotContainAnyOf = 'DoesNotContainAnyOf',
  DoesNotEndWith = 'DoesNotEndWith',
  DoesNotStartWith = 'DoesNotStartWith',
  Empty = 'Empty',
  EndsWith = 'EndsWith',
  Equals = 'Equals',
  GreaterThan = 'GreaterThan',
  IsAnyOf = 'IsAnyOf',
  IsInTheLast = 'IsInTheLast',
  IsNoneOf = 'IsNoneOf',
  Last7Days = 'Last7Days',
  Last14Days = 'Last14Days',
  Last30Days = 'Last30Days',
  LessThan = 'LessThan',
  NotEmpty = 'NotEmpty',
  StartsWith = 'StartsWith'
}

export type PolicyGetPolicyResult = {
  __typename?: 'PolicyGetPolicyResult';
  pagination?: Maybe<PolicyTokenPagination>;
  policies: Array<PolicyDefinition>;
};

export type PolicyMetadata = {
  __typename?: 'PolicyMetadata';
  alertCount: Scalars['Int']['output'];
  policyID: Scalars['ID']['output'];
};

export enum PolicySeverity {
  Critical = 'Critical',
  High = 'High',
  Information = 'Information',
  Low = 'Low',
  Medium = 'Medium'
}

export type PolicyTokenPagination = {
  __typename?: 'PolicyTokenPagination';
  hasMore: Scalars['Boolean']['output'];
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type PolicyTokenPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export enum PortProtocol {
  Tcp = 'TCP',
  Udp = 'UDP'
}

export type PrioritizationAnomaliesInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeFrameStart?: InputMaybe<Scalars['String']['input']>;
};

export type PrioritizationAsset = {
  __typename?: 'PrioritizationAsset';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PrioritizationMostSevereAssetsInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeFrameStart?: InputMaybe<Scalars['String']['input']>;
};

export type PrioritizationMostVulnerableAssetsInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeFrameStart?: InputMaybe<Scalars['String']['input']>;
};

export type PrioritizationSevereAsset = {
  __typename?: 'PrioritizationSevereAsset';
  asset?: Maybe<PrioritizationAsset>;
  severityGraph?: Maybe<PrioritizationSeverityGraph>;
};

export type PrioritizationSeverityGraph = {
  __typename?: 'PrioritizationSeverityGraph';
  critical?: Maybe<Scalars['Int']['output']>;
  high?: Maybe<Scalars['Int']['output']>;
  low?: Maybe<Scalars['Int']['output']>;
  medium?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PrioritizationSeverityGraphEntity = {
  __typename?: 'PrioritizationSeverityGraphEntity';
  domain: Scalars['String']['output'];
  severityGraph: PrioritizationSeverityGraph;
  timeFrame: Scalars['String']['output'];
};

export type PrioritizationSeverityGraphInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeFrameStart?: InputMaybe<Scalars['String']['input']>;
};

export type PrioritizationVulnerabilitiesInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timeFrameStart?: InputMaybe<Scalars['String']['input']>;
};

export type PrioritizationVulnerabilitiesPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PrioritizationVulnerabilitiesResponse = {
  __typename?: 'PrioritizationVulnerabilitiesResponse';
  nodes?: Maybe<Array<PrioritizationVulnerability>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PrioritizationVulnerabilitiesSortingInput = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<FindingsSortingDirection>;
};

export type PrioritizationVulnerability = {
  __typename?: 'PrioritizationVulnerability';
  assetToken?: Maybe<Scalars['String']['output']>;
  customTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cvssScore?: Maybe<Scalars['Float']['output']>;
  lastSeen?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  scanProfileToken?: Maybe<Scalars['String']['output']>;
  scanSource: FindingScanSource;
  severity: FindingSeverity;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<FindingStatus>;
  title: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type PrioritizationVulnerableAsset = {
  __typename?: 'PrioritizationVulnerableAsset';
  asset?: Maybe<PrioritizationAsset>;
  severityGraph?: Maybe<PrioritizationSeverityGraph>;
};

export enum Product {
  As = 'AS',
  Sm = 'SM'
}

export type PublicApiv3CreateKeyInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PublicApiv3CreateKeyResponse = {
  __typename?: 'PublicAPIV3CreateKeyResponse';
  key: Scalars['String']['output'];
};

export type PublicApiv3DeleteKeyInput = {
  id: Scalars['String']['input'];
};

export type PublicApiv3DeleteKeyResponse = {
  __typename?: 'PublicAPIV3DeleteKeyResponse';
  success: Scalars['Boolean']['output'];
};

export type PublicApiv3Key = {
  __typename?: 'PublicAPIV3Key';
  created: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['String']['output'];
};

export type PublicApiv3ListKeysResponse = {
  __typename?: 'PublicAPIV3ListKeysResponse';
  keys: Array<PublicApiv3Key>;
};

export type Query = {
  __typename?: 'Query';
  alerts: AlertResult;
  allTests: ListTestsResponse;
  anomalies?: Maybe<Scalars['String']['output']>;
  apiKey: ApiKeyDetails;
  apiKeys: Array<ApiKeyListItem>;
  assetMonitoringBalance: AssetMonitoringBalance;
  assetMonitoringSettings?: Maybe<AssetMonitoringSettingsResponse>;
  assetPatterns: AssetPatternsResult;
  assetoryGetMonitoredAssetsCount: Scalars['Int']['output'];
  billingHistory: BillingHistoryResponse;
  billingInternal: BillingInternalResponse;
  billingInvoicePDF: BillingInvoicePdfResponse;
  checkEmail: CheckEmailResponse;
  connectorList: ConnectorListResponse;
  deepScanReport?: Maybe<DeepScanReport>;
  deepScanReports?: Maybe<DeepScanReportsConnection>;
  feedConfiguration?: Maybe<FeedConfiguration>;
  feeds?: Maybe<FeedsConnection>;
  /** Get all vulnerabilities filters for given user */
  filterValues: FilterValues;
  getApplicationScanHistory?: Maybe<ApplicationScanHistoryResponse>;
  getAsset?: Maybe<Asset>;
  getAssetVerificationCode: Scalars['String']['output'];
  /**
   * getAssets Query will return AssetResult with a list representing a page of filtered assets.
   * In case pagination is not supplied the first 2000 assets matching the filter will be returned.
   */
  getAssets: AssetResult;
  getCurrentTeam: Team;
  /** @deprecated use getScanProfilesForTeam instead */
  getDeepScanProfiles: DeepScansResult;
  getJiraIssueTypes?: Maybe<Array<Maybe<JiraIssueType>>>;
  getJiraProjects?: Maybe<Array<Maybe<JiraProject>>>;
  getMultipleSubDomainClusteringResult: GetMultipleSubDomainClusteringResultResponse;
  getResultCountForTeam: TeamResultResponse;
  getSSLLogSubDomains: GetSslLogSubDomainsResponse;
  getSSLLogSubDomainsCount: GetSslLogSubDomainsCountResponse;
  getSSLLogSubDomainsList: GetSslLogSubDomainsListResponse;
  getScanProfile?: Maybe<ScanProfileListItem>;
  getScanProfileSummary: Array<ScanProfileSummary>;
  getScanProfilesForTeam: ScanProfileListResponse;
  getScanResultsForTeam: ScanResultsForTeamResponse;
  getScanUUIDs?: Maybe<GetScanUuiDsResponse>;
  getSubDomainClusteringResult: GetSubDomainClusteringResultResponse;
  getVulnerabilities?: Maybe<PrioritizationVulnerabilitiesResponse>;
  invites: Array<Invite>;
  isTeamActive: IsTeamActive;
  members: MembersResult;
  mostSevereAssets?: Maybe<Array<Maybe<PrioritizationSevereAsset>>>;
  mostVulnerableAssets?: Maybe<Array<Maybe<PrioritizationVulnerableAsset>>>;
  noOp?: Maybe<Scalars['String']['output']>;
  overviewSeverityGraph?: Maybe<OverviewSeverityGraph>;
  policyGetPolicies: PolicyGetPolicyResult;
  publicAPIV3ListKeys: PublicApiv3ListKeysResponse;
  releaseLog: ReleaseLogResponse;
  scanProfile?: Maybe<ScanProfile>;
  scanProfiles?: Maybe<Array<Maybe<ScanProfile>>>;
  severityGraph?: Maybe<PrioritizationSeverityGraph>;
  subTeams: SubTeamsResult;
  subTeamsForUser: SubTeamsForUserResult;
  suggestedApexes?: Maybe<Array<Maybe<Apex>>>;
  surfaceProjectionsBreachGroupByNone?: Maybe<SurfaceProjectionsBreachGroupByNoneResponse>;
  surfaceProjectionsBreachesBySeverityOverTime?: Maybe<SurfaceProjectionsBreachesBySeverityOverTimeResponse>;
  surfaceProjectionsDNSQueryFilterValues?: Maybe<SurfaceProjectionDnsQueryFilterValuesResponse>;
  surfaceProjectionsDNSQueryGroupByNone?: Maybe<SurfaceProjectionsDnsQueryGroupByNoneResponse>;
  surfaceProjectionsDomainDetails?: Maybe<SurfaceProjectionsDomainDetailsResponse>;
  surfaceProjectionsDomainFilterValues?: Maybe<SurfaceProjectionDomainFilterValuesResponse>;
  surfaceProjectionsDomainGroupByDomain?: Maybe<SurfaceProjectionsDomainGroupByDomainResponse>;
  surfaceProjectionsIPFilterValues?: Maybe<SurfaceProjectionIpFilterValuesResponse>;
  surfaceProjectionsIPGroupByAsName?: Maybe<SurfaceProjectionsIpGroupByAsNameResponse>;
  surfaceProjectionsIPGroupByCountry?: Maybe<SurfaceProjectionsIpGroupByCountryResponse>;
  surfaceProjectionsIPGroupByIP?: Maybe<SurfaceProjectionsIpGroupByIpResponse>;
  surfaceProjectionsIPGroupByNone?: Maybe<SurfaceProjectionsIpGroupByNoneResponse>;
  surfaceProjectionsPortFilterValues?: Maybe<SurfaceProjectionPortFilterValuesResponse>;
  surfaceProjectionsPortGroupByNone?: Maybe<SurfaceProjectionsPortGroupByNoneResponse>;
  surfaceProjectionsPortGroupByPort?: Maybe<SurfaceProjectionsPortGroupByPortResponse>;
  surfaceProjectionsTechFilterValues?: Maybe<SurfaceProjectionTechFilterValuesResponse>;
  surfaceProjectionsTechGroupByName?: Maybe<SurfaceProjectionsTechGroupByNameResponse>;
  surfaceProjectionsTechGroupByNameVersion?: Maybe<SurfaceProjectionsTechGroupByNameVersionResponse>;
  surfaceProjectionsTechGroupByNone?: Maybe<SurfaceProjectionsTechGroupByNoneResponse>;
  surfaceProjectionsVulnerabilityByDomainTemp: Array<Maybe<SurfaceProjectionsVulnerabilityByDomainTemp>>;
  user: User;
  users: Array<User>;
  vulnerabilities: VulnerabilitiesResponse;
  vulnerability?: Maybe<Vulnerability>;
  vulnerabilityExport: VulnerabilityExportMetadata;
  vulnerabilityExports: Array<VulnerabilityExportMetadata>;
  /** Get all vulnerabilities filters for given user */
  vulnerabilityFilters: Array<Maybe<VulnerabilityFilter>>;
  /** Get all vulnerabilities filters for given user */
  vulnerabilityFiltersV2: Array<Maybe<VulnerabilityFilterV2>>;
  vulnerabilityProjectionsVulnerabilitiesGroupByNone?: Maybe<VulnerabilityProjectionsVulnerabilitiesGroupByNoneResponse>;
  /** Get all Vulnerability Tags available for given user */
  vulnerabilityTags?: Maybe<Array<Maybe<VulnerabilityTag>>>;
  wafDomainsForScanProfiles: WafDomainsForScanProfilesResponse;
  wafDomainsForTechnology: WafDomainsResponse;
  wafDomainsPerOrigin: WafDomainsPerOriginResponse;
  wafStatsGroupByTechnology: WafStatusResponse;
  wafTotalUniqueDomains: WafTotalUniqueDomainsResponse;
  workatoAuthenticate: WorkatoAuthenticateResponse;
  workatoAuthenticateEcho: WorkatoAuthenticateResponse;
  workatoCustomerDetails: WorkatoCustomerDetailsResponse;
  workatoCustomerExists: WorkatoCustomerExistsResponse;
  workatoRecipeTemplates: WorkatoRecipeTemplatesResponse;
};


export type QueryAlertsArgs = {
  pagination?: InputMaybe<AlertPaginationInput>;
  policyID: Scalars['ID']['input'];
  sorting?: InputMaybe<AlertsSorting>;
};


export type QueryAnomaliesArgs = {
  input?: InputMaybe<PrioritizationAnomaliesInput>;
};


export type QueryApiKeyArgs = {
  apiKey: Scalars['String']['input'];
};


export type QueryAssetMonitoringSettingsArgs = {
  assetToken: Scalars['String']['input'];
};


export type QueryAssetPatternsArgs = {
  subTeamToken: Scalars['String']['input'];
};


export type QueryBillingInvoicePdfArgs = {
  invoiceToken: Scalars['String']['input'];
};


export type QueryCheckEmailArgs = {
  input: CheckEmailInput;
};


export type QueryDeepScanReportArgs = {
  reportToken: Scalars['String']['input'];
  scanProfileToken: Scalars['String']['input'];
};


export type QueryDeepScanReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
};


export type QueryFeedConfigurationArgs = {
  application: Scalars['String']['input'];
  configurationToken: Scalars['String']['input'];
};


export type QueryGetApplicationScanHistoryArgs = {
  filters?: InputMaybe<ApplicationScanHistoryFilter>;
  pagination?: InputMaybe<ApplicationScanHistoryPagination>;
  scanProfileToken: Scalars['String']['input'];
};


export type QueryGetAssetArgs = {
  assetToken: Scalars['String']['input'];
};


export type QueryGetAssetsArgs = {
  filters?: InputMaybe<Filters>;
  pagination?: InputMaybe<Pagination>;
  sorting?: InputMaybe<AssetSorting>;
};


export type QueryGetDeepScanProfilesArgs = {
  filters?: InputMaybe<DeepScanFilters>;
  pagination?: InputMaybe<LimitOffsetPagination>;
};


export type QueryGetJiraIssueTypesArgs = {
  apiToken: Scalars['String']['input'];
  projectKey: Scalars['String']['input'];
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryGetJiraProjectsArgs = {
  apiToken: Scalars['String']['input'];
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QueryGetMultipleSubDomainClusteringResultArgs = {
  input: GetMultipleSubDomainClusteringResultInput;
};


export type QueryGetResultCountForTeamArgs = {
  filter: TeamResultFilter;
};


export type QueryGetSslLogSubDomainsArgs = {
  input: GetSslLogSubDomainsInput;
};


export type QueryGetSslLogSubDomainsCountArgs = {
  input: GetSslLogSubDomainsCountInput;
};


export type QueryGetSslLogSubDomainsListArgs = {
  input: GetSslLogSubDomainsListInput;
};


export type QueryGetScanProfileArgs = {
  scanProfileToken: Scalars['String']['input'];
};


export type QueryGetScanProfileSummaryArgs = {
  input: ScanProfileSummaryRequest;
};


export type QueryGetScanProfilesForTeamArgs = {
  filters?: InputMaybe<ScanProfileFilters>;
  pagination?: InputMaybe<LimitOffsetPagination>;
};


export type QueryGetScanUuiDsArgs = {
  scanProfileToken: Scalars['String']['input'];
};


export type QueryGetSubDomainClusteringResultArgs = {
  input: GetSubDomainClusteringResultInput;
};


export type QueryGetVulnerabilitiesArgs = {
  filters?: InputMaybe<FindingFiltersInput>;
  input?: InputMaybe<PrioritizationVulnerabilitiesInput>;
  pagination: PrioritizationVulnerabilitiesPaginationInput;
  sorting: PrioritizationVulnerabilitiesSortingInput;
};


export type QueryMembersArgs = {
  subTeamToken: Scalars['String']['input'];
};


export type QueryMostSevereAssetsArgs = {
  input?: InputMaybe<PrioritizationMostSevereAssetsInput>;
};


export type QueryMostVulnerableAssetsArgs = {
  input?: InputMaybe<PrioritizationMostVulnerableAssetsInput>;
};


export type QueryPolicyGetPoliciesArgs = {
  pagination?: InputMaybe<PolicyTokenPaginationInput>;
};


export type QueryScanProfileArgs = {
  scanProfileToken: Scalars['String']['input'];
};


export type QuerySeverityGraphArgs = {
  input?: InputMaybe<PrioritizationSeverityGraphInput>;
};


export type QuerySubTeamsForUserArgs = {
  userToken: Scalars['String']['input'];
};


export type QuerySurfaceProjectionsBreachGroupByNoneArgs = {
  input?: InputMaybe<SurfaceProjectionsBreachGroupByNoneInput>;
};


export type QuerySurfaceProjectionsBreachesBySeverityOverTimeArgs = {
  input: SurfaceProjectionsBreachesBySeverityOverTimeInput;
};


export type QuerySurfaceProjectionsDnsQueryFilterValuesArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionFilterValuesFilters>>;
};


export type QuerySurfaceProjectionsDnsQueryGroupByNoneArgs = {
  input?: InputMaybe<SurfaceProjectionsDnsQueryGroupByNoneInput>;
};


export type QuerySurfaceProjectionsDomainDetailsArgs = {
  assetToken?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySurfaceProjectionsDomainGroupByDomainArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionDomainFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionDomainSortBy>;
};


export type QuerySurfaceProjectionsIpFilterValuesArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionFilterValuesFilters>>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySurfaceProjectionsIpGroupByAsNameArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionSortBy>;
};


export type QuerySurfaceProjectionsIpGroupByCountryArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionSortBy>;
};


export type QuerySurfaceProjectionsIpGroupByIpArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionSortBy>;
};


export type QuerySurfaceProjectionsIpGroupByNoneArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionSortBy>;
};


export type QuerySurfaceProjectionsPortFilterValuesArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionFilterValuesFilters>>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySurfaceProjectionsPortGroupByNoneArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionPortFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionPortSortBy>;
};


export type QuerySurfaceProjectionsPortGroupByPortArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionPortFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionPortSortBy>;
};


export type QuerySurfaceProjectionsTechFilterValuesArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionFilterValuesFilters>>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySurfaceProjectionsTechGroupByNameArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionTechFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionTechSortBy>;
};


export type QuerySurfaceProjectionsTechGroupByNameVersionArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionTechFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionTechSortBy>;
};


export type QuerySurfaceProjectionsTechGroupByNoneArgs = {
  filters?: InputMaybe<Array<SurfaceProjectionTechFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionTechSortBy>;
};


export type QueryVulnerabilitiesArgs = {
  filter?: InputMaybe<VulnerabilitiesFilterInput>;
  pagination: VulnerabilitiesPaginationInput;
  sorting?: InputMaybe<VulnerabilitiesSortingInput>;
};


export type QueryVulnerabilityArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryVulnerabilityExportArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryVulnerabilityProjectionsVulnerabilitiesGroupByNoneArgs = {
  input?: InputMaybe<VulnerabilityProjectionsVulnerabilitiesGroupByNoneInput>;
};


export type QueryWafDomainsForScanProfilesArgs = {
  input: WafDomainsForScanProfilesInput;
};


export type QueryWafDomainsForTechnologyArgs = {
  input: WafDomainsForTechnologyInput;
};


export type QueryWafDomainsPerOriginArgs = {
  input: WafDomainsPerOriginInput;
};


export type QueryWorkatoAuthenticateEchoArgs = {
  input: WorkatoAuthenticateEchoInput;
};

export type ReleaseLogResponse = {
  __typename?: 'ReleaseLogResponse';
  entries: Array<Entry>;
};

export type RemoveAssetPatternInput = {
  subTeamToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RemoveAssetPatternResponse = {
  __typename?: 'RemoveAssetPatternResponse';
  success: Scalars['Boolean']['output'];
};

export type RemoveMemberInput = {
  subTeamToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RemoveMemberResponse = {
  __typename?: 'RemoveMemberResponse';
  success: Scalars['Boolean']['output'];
};

export type RemoveMembersInput = {
  subTeamToken: Scalars['String']['input'];
  tokens: Array<Scalars['String']['input']>;
};

export type RemoveMembersResponse = {
  __typename?: 'RemoveMembersResponse';
  success: Scalars['Boolean']['output'];
};

export type RemoveMembershipInput = {
  token: Scalars['String']['input'];
};

export type RemoveMembershipResponse = {
  __typename?: 'RemoveMembershipResponse';
  success: Scalars['Boolean']['output'];
};

export type RemoveSubTeamInput = {
  token: Scalars['String']['input'];
};

export type RemoveSubTeamResponse = {
  __typename?: 'RemoveSubTeamResponse';
  success: Scalars['Boolean']['output'];
};

export type RevokeApiKeyResponse = {
  __typename?: 'RevokeAPIKeyResponse';
  success: Scalars['Boolean']['output'];
};

export type RevokeInviteInput = {
  token: Scalars['String']['input'];
};

export type RevokeInviteResponse = {
  __typename?: 'RevokeInviteResponse';
  success: Scalars['Boolean']['output'];
};

export enum Role {
  Admin = 'Admin',
  Editor = 'Editor',
  Viewer = 'Viewer'
}

export type SpScanInfo = {
  __typename?: 'SPScanInfo';
  endedTime?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<ScanError>;
  nextScanTime: Scalars['Int']['output'];
  reportToken?: Maybe<Scalars['String']['output']>;
  result?: Maybe<ScanResult>;
  scanStatus: ScanStatus;
  schedule?: Maybe<ScanSchedule>;
  startedTime: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export enum ScanError {
  /** @deprecated EndedForcefully is deprecated. Only used in ScanProfileListItem.ScanError field. Use ScanFatal.Unspecified to indicate this issue */
  EndedForcefully = 'EndedForcefully',
  /** @deprecated FailedToResolve is deprecated. Only used in ScanProfileListItem.ScanError field. Use UnableToReachEndpoint* values instead in the ScanFatal enum. */
  FailedToResolve = 'FailedToResolve',
  /** @deprecated None is deprecated. */
  None = 'None',
  /** Indicates that one or more of recorded crawling failed for the scan */
  RecordedCrawlingFailure = 'RecordedCrawlingFailure',
  /** Indicates that the recorded login for the scan failed */
  RecordedLoginFailure = 'RecordedLoginFailure',
  /** Indicates that our crawler was not able to find or crawl any URLs */
  ZeroCrawledUrLs = 'ZeroCrawledURLs'
}

export enum ScanFatal {
  /** Indicates the scan was unable to reach the scan profile endpoint, due to DNS issues, previously ScanError.DNSFailure */
  UnableToReachEndpointDnsFailure = 'UnableToReachEndpointDNSFailure',
  /** Indicates the scan was unable to reach the scan profile endpoint, due to unable to open a HTTP connection, previously ScanError.NoHTTPConnection */
  UnableToReachEndpointNoHttpConnection = 'UnableToReachEndpointNoHTTPConnection',
  /** Indicates the scan was unable to reach the scan profile endpoint, due to lacking IP address, previously ScanError.NoIP */
  UnableToReachEndpointNoIp = 'UnableToReachEndpointNoIP',
  /** Indicates the scan was unable to reach the scan profile endpoint, due to only finding IPv6 address, previously ScanError.NoIPv4 */
  UnableToReachEndpointNoIPv4 = 'UnableToReachEndpointNoIPv4',
  /** Indicates the scan was unable to reach the scan profile endpoint, due to lacking open ports, previously ScanError.NoOpnPort */
  UnableToReachEndpointNoOpenPort = 'UnableToReachEndpointNoOpenPort',
  /** Indicates the scan was unable to reach the scan profile endpoint, due to out user agent getting blocked, previously ScanError.UserAgentBlocked */
  UnableToReachEndpointUserAgentBlocked = 'UnableToReachEndpointUserAgentBlocked',
  /** Indicates a general technical issue, previously ScanError.EndedForcefully */
  Undefined = 'Undefined'
}

export type ScanInfoRequest = {
  scanProfileTokens?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ScanProfile = {
  __typename?: 'ScanProfile';
  created?: Maybe<Scalars['Int']['output']>;
  domainToken?: Maybe<Scalars['String']['output']>;
  endpoint: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  scanProfileId?: Maybe<Scalars['Int']['output']>;
  scanProfileToken: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  teamToken: Scalars['String']['output'];
};

export type ScanProfileFilters = {
  filter?: InputMaybe<Array<InputMaybe<ScanProfileListColumnFilter>>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ScanProfileListColumnFilter = {
  column: ScanProfileListFilterColumnName;
  operator: ScanProfileListFilterOperator;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ScanProfileListFilterColumnName {
  LastScanResult = 'lastScanResult'
}

export enum ScanProfileListFilterOperator {
  IsAnyOf = 'isAnyOf'
}

export type ScanProfileListItem = {
  __typename?: 'ScanProfileListItem';
  assetToken?: Maybe<Scalars['String']['output']>;
  endpoint: Scalars['String']['output'];
  isBillingActive: Scalars['Boolean']['output'];
  lastScanEndedTime?: Maybe<Scalars['Int']['output']>;
  /** lastScanErrors contains all errors that occurred during the scan */
  lastScanErrors?: Maybe<Array<Maybe<ScanError>>>;
  /** lastScanFatal contains the fatal issue that resulted in the scan failing, only applicable if lastScanResult is Failure */
  lastScanFatal?: Maybe<ScanFatal>;
  lastScanResult?: Maybe<ScanResult>;
  /** unix timestamp */
  lastScanStartedTime?: Maybe<Scalars['Int']['output']>;
  lastScanStatus: ScanStatus;
  name: Scalars['String']['output'];
  nextScanTime?: Maybe<Scalars['Int']['output']>;
  reportToken?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<ScanSchedule>;
  token: Scalars['String']['output'];
};

export type ScanProfileListResponse = {
  __typename?: 'ScanProfileListResponse';
  scanProfiles: Array<ScanProfileListItem>;
  totalCount: Scalars['Int']['output'];
};

export type ScanProfileSummary = {
  __typename?: 'ScanProfileSummary';
  endpoint: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  lastScanInfo?: Maybe<SpScanInfo>;
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type ScanProfileSummaryRequest = {
  scanProfileTokens?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** scan results have the meaning specified in as-scan-api */
export enum ScanResult {
  /** Indicates that the scan failed due to a fatal issue */
  Failure = 'Failure',
  /** Indicates that the scan was stopped by user */
  Stopped = 'Stopped',
  /** Indicates that the scan ran to completion */
  Success = 'Success'
}

export enum ScanResultDetails {
  /** @deprecated DNSFailure is deprecated. Use ScanFatal.UnableToReachEndpointDNSFailure instead */
  DnsFailure = 'DNSFailure',
  /** @deprecated NoHTTPConnection is deprecated. Use ScanFatal.UnableToReachEndpointNoHTTPConnection instead */
  NoHttpConnection = 'NoHTTPConnection',
  /** @deprecated NoIP is deprecated. Use ScanFatal.UnableToReachEndpointNoIP instead */
  NoIp = 'NoIP',
  /** @deprecated NoIPv4 is deprecated. Use ScanFatal.UnableToReachEndpointUserAgentBlocked instead */
  NoIPv4 = 'NoIPv4',
  /** @deprecated NoOpenPort is deprecated. Use ScanFatal.UnableToReachEndpointNoOpenPort instead */
  NoOpenPort = 'NoOpenPort',
  /** @deprecated None is deprecated. */
  None = 'None',
  /** @deprecated UserAgentBlocked is deprecated. Use ScanFatal.UnableToReachEndpointDNSFailure instead */
  UserAgentBlocked = 'UserAgentBlocked'
}

export type ScanResultsForTeamResponse = {
  __typename?: 'ScanResultsForTeamResponse';
  failure: Scalars['Int']['output'];
  succeededWithErrors: Scalars['Int']['output'];
  success: Scalars['Int']['output'];
};

export enum ScanSchedule {
  BiWeekly = 'BiWeekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  None = 'None',
  Weekly = 'Weekly'
}

export enum ScanStatus {
  NotRunning = 'NotRunning',
  Running = 'Running',
  Stopping = 'Stopping',
  Unknown = 'Unknown'
}

export type Score = {
  __typename?: 'Score';
  score?: Maybe<Scalars['Float']['output']>;
  severity?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vector?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type SendCsmExtendSpRequestEmailInput = {
  customerEmail: Scalars['String']['input'];
  subDomains: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export enum Severity {
  Critical = 'Critical',
  High = 'High',
  Information = 'Information',
  Low = 'Low',
  Medium = 'Medium'
}

export enum SortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartScanResponse = {
  __typename?: 'StartScanResponse';
  error: Scalars['String']['output'];
  scanUUID: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type StartScansWithDelayRequest = {
  delayMinutes: Scalars['Int']['input'];
  scanProfileTokens?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StartScansWithDelayResponse = {
  __typename?: 'StartScansWithDelayResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type StopScanResponse = {
  __typename?: 'StopScanResponse';
  error: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubDomainClusteringResult = {
  __typename?: 'SubDomainClusteringResult';
  domain: Scalars['String']['output'];
  group: Scalars['String']['output'];
  isLogin: Scalars['Boolean']['output'];
  score: Scalars['Int']['output'];
  statusCode: Scalars['Int']['output'];
};

export enum SubDomainSource {
  Data = 'DATA',
  Ssltransparency = 'SSLTRANSPARENCY'
}

export type SubTeam = {
  __typename?: 'SubTeam';
  assetPatterns: Array<AssetPattern>;
  created: Scalars['Time']['output'];
  description?: Maybe<Scalars['String']['output']>;
  members: Array<Member>;
  name?: Maybe<Scalars['String']['output']>;
  teamToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated: Scalars['Time']['output'];
};

export type SubTeamsForUserResult = {
  __typename?: 'SubTeamsForUserResult';
  subTeams: Array<SubTeam>;
};

export type SubTeamsResult = {
  __typename?: 'SubTeamsResult';
  subTeams: Array<SubTeam>;
};

export type SubscriptionDetails = {
  __typename?: 'SubscriptionDetails';
  currency: Currency;
  paymentCycle: PaymentCycle;
  planType: PlanType;
  scanProfileCapacity: Scalars['Int']['output'];
  scanProfileCapacityEndOfTerm: Scalars['Int']['output'];
  start: Scalars['Time']['output'];
  status: SubscriptionStatus;
  surfaceMonitoringCapacity: Scalars['Int']['output'];
  surfaceMonitoringCapacityEndOfTerm: Scalars['Int']['output'];
  termEnd: Scalars['Time']['output'];
  termStart: Scalars['Time']['output'];
  token: Scalars['String']['output'];
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Future = 'FUTURE',
  Intrial = 'INTRIAL',
  Nonrenewing = 'NONRENEWING',
  Paused = 'PAUSED'
}

export type SurfaceMonitoringHttpAssessment = {
  __typename?: 'SurfaceMonitoringHttpAssessment';
  lastScan?: Maybe<Scalars['Int']['output']>;
  status: SurfaceMonitoringHttpAssessmentStatus;
};

export enum SurfaceMonitoringHttpAssessmentStatus {
  /** Blocked indicates that HTTP assessments are turned on for the root asset, but this specific asset is disallowed from being scanned */
  Blocked = 'Blocked',
  /** Off indicates that Surface Monitoring or HTTP assessments are not turned on for the root asset */
  Off = 'Off',
  /** Running indicates a scan is currently running on the asset */
  Running = 'Running',
  /** Scheduled indicates that a scan is scheduled to run on the asset */
  Scheduled = 'Scheduled'
}

export type SurfaceProjectionDnsQueryFilterValuesResponse = {
  __typename?: 'SurfaceProjectionDNSQueryFilterValuesResponse';
  answerRecordType?: Maybe<Array<Scalars['String']['output']>>;
  domainName?: Maybe<Array<Scalars['String']['output']>>;
  questionRecordType?: Maybe<Array<Scalars['String']['output']>>;
  responseCode?: Maybe<Array<Scalars['String']['output']>>;
};

export enum SurfaceProjectionDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SurfaceProjectionDomainFilterValuesResponse = {
  __typename?: 'SurfaceProjectionDomainFilterValuesResponse';
  activeCountries?: Maybe<Array<Scalars['String']['output']>>;
  activeIPAddresses?: Maybe<Array<Scalars['String']['output']>>;
  asNames?: Maybe<Array<Scalars['String']['output']>>;
  dnsRecordTypes?: Maybe<Array<Scalars['String']['output']>>;
  dnsResponseCodes?: Maybe<Array<Scalars['String']['output']>>;
  port?: Maybe<Array<Scalars['String']['output']>>;
  techs?: Maybe<Array<Scalars['String']['output']>>;
};

export type SurfaceProjectionDomainFilters = {
  name: SurfaceProjectionDomainFiltersName;
  operator: SurfaceProjectionFilterOperator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SurfaceProjectionDomainFiltersName {
  ActiveAsNameCount = 'ActiveAsNameCount',
  ActiveAsNames = 'ActiveAsNames',
  ActiveCountries = 'ActiveCountries',
  ActiveCountryCount = 'ActiveCountryCount',
  ActiveIpAddresses = 'ActiveIPAddresses',
  ActiveIpAddressCount = 'ActiveIpAddressCount',
  ActiveTechCount = 'ActiveTechCount',
  ActiveTechVersions = 'ActiveTechVersions',
  ActiveTechs = 'ActiveTechs',
  DnsRecordTypes = 'DNSRecordTypes',
  DnsRecordTypesCount = 'DNSRecordTypesCount',
  DnsResponseCodes = 'DNSResponseCodes',
  Disappeared = 'Disappeared',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  OpenPortCount = 'OpenPortCount',
  OpenPorts = 'OpenPorts',
  SurfaceState = 'SurfaceState'
}

export type SurfaceProjectionDomainSortBy = {
  direction?: InputMaybe<SurfaceProjectionDirection>;
  name: SurfaceProjectionDomainSortName;
};

export enum SurfaceProjectionDomainSortName {
  ActiveAsNameCount = 'ActiveAsNameCount',
  ActiveCountryCount = 'ActiveCountryCount',
  ActiveIpAddressCount = 'ActiveIpAddressCount',
  ActiveTechCount = 'ActiveTechCount',
  DnsRecordTypesCount = 'DNSRecordTypesCount',
  DnsResponseCodes = 'DNSResponseCodes',
  Disappeared = 'Disappeared',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  OpenPortCount = 'OpenPortCount',
  SurfaceState = 'SurfaceState'
}

export enum SurfaceProjectionFilterOperator {
  After = 'After',
  Before = 'Before',
  Contains = 'Contains',
  ContainsAllOf = 'ContainsAllOf',
  ContainsAnyOf = 'ContainsAnyOf',
  ContainsItemNotInList = 'ContainsItemNotInList',
  DoesNotContain = 'DoesNotContain',
  DoesNotContainAllOf = 'DoesNotContainAllOf',
  DoesNotContainAnyOf = 'DoesNotContainAnyOf',
  DoesNotEndWith = 'DoesNotEndWith',
  DoesNotStartWith = 'DoesNotStartWith',
  Empty = 'Empty',
  EndsWith = 'EndsWith',
  /** @deprecated Use `IsAnyOf` instead. */
  Equals = 'Equals',
  GreaterThan = 'GreaterThan',
  /** @deprecated Use `ContainsAnyOf` instead. */
  IsAnyOf = 'IsAnyOf',
  IsInTheLast = 'IsInTheLast',
  /** @deprecated Replaced by `DoesNotContainAnyOf`, `DoesNotContainAllOf` or `ContainsItemNotInList` depending on intent. */
  IsNoneOf = 'IsNoneOf',
  Last7Days = 'Last7Days',
  Last14Days = 'Last14Days',
  Last30Days = 'Last30Days',
  LessThan = 'LessThan',
  NotEmpty = 'NotEmpty',
  StartsWith = 'StartsWith'
}

export type SurfaceProjectionFilterValuesFilters = {
  name: SurfaceProjectionFilterValuesFiltersName;
  operator: SurfaceProjectionFilterValuesOperator;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum SurfaceProjectionFilterValuesFiltersName {
  DomainName = 'DomainName'
}

export enum SurfaceProjectionFilterValuesOperator {
  Equals = 'Equals'
}

export type SurfaceProjectionFilters = {
  name: SurfaceProjectionFiltersName;
  operator: SurfaceProjectionFilterOperator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SurfaceProjectionFiltersName {
  Asn = 'ASN',
  Active = 'Active',
  AsDomain = 'AsDomain',
  AsName = 'AsName',
  AsNameCount = 'AsNameCount',
  AsnCount = 'AsnCount',
  ContinentName = 'ContinentName',
  CountryName = 'CountryName',
  CountryNameCount = 'CountryNameCount',
  Disappeared = 'Disappeared',
  DomainCount = 'DomainCount',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  IpAddress = 'IPAddress',
  IpAddressCount = 'IPAddressCount',
  IpVersion = 'IPVersion'
}

export type SurfaceProjectionIpFilterValuesResponse = {
  __typename?: 'SurfaceProjectionIPFilterValuesResponse';
  asDomain: Array<Scalars['String']['output']>;
  asName: Array<Scalars['String']['output']>;
  asn: Array<Scalars['String']['output']>;
  continentName: Array<Scalars['String']['output']>;
  countryName: Array<Scalars['String']['output']>;
  domainName: Array<Scalars['String']['output']>;
  ipAddress: Array<Scalars['String']['output']>;
  ipVersion: Array<SurfaceProjectionsIpVersion>;
};

export type SurfaceProjectionPortFilterValuesResponse = {
  __typename?: 'SurfaceProjectionPortFilterValuesResponse';
  domainName?: Maybe<Array<Scalars['String']['output']>>;
  ipAddress?: Maybe<Array<Scalars['String']['output']>>;
  port?: Maybe<Array<Scalars['String']['output']>>;
  status: Array<Maybe<SurfaceProjectionPortStatus>>;
};

export type SurfaceProjectionPortFilters = {
  name: SurfaceProjectionPortFiltersName;
  operator: SurfaceProjectionFilterOperator;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum SurfaceProjectionPortFiltersName {
  Disappeared = 'Disappeared',
  DomainCount = 'DomainCount',
  DomainName = 'DomainName',
  DomainOpenCount = 'DomainOpenCount',
  FirstScanned = 'FirstScanned',
  IpAddress = 'IPAddress',
  IpAddressCount = 'IPAddressCount',
  IpAddressOpenCount = 'IPAddressOpenCount',
  Port = 'Port',
  PortStatus = 'PortStatus'
}

export type SurfaceProjectionPortSortBy = {
  direction?: InputMaybe<SurfaceProjectionDirection>;
  name: SurfaceProjectionPortSortName;
};

export enum SurfaceProjectionPortSortName {
  Disappeared = 'Disappeared',
  DomainCount = 'DomainCount',
  DomainName = 'DomainName',
  DomainOpenCount = 'DomainOpenCount',
  FirstScanned = 'FirstScanned',
  IpAddress = 'IPAddress',
  IpAddressCount = 'IPAddressCount',
  IpAddressOpenCount = 'IPAddressOpenCount',
  Port = 'Port',
  PortStatus = 'PortStatus'
}

export enum SurfaceProjectionPortStatus {
  Closed = 'Closed',
  Filtered = 'Filtered',
  Open = 'Open'
}

export type SurfaceProjectionSortBy = {
  direction?: InputMaybe<SurfaceProjectionDirection>;
  name: SurfaceProjectionSortName;
};

export enum SurfaceProjectionSortName {
  Asn = 'ASN',
  Active = 'Active',
  AsDomain = 'AsDomain',
  AsName = 'AsName',
  AsNameCount = 'AsNameCount',
  AsnCount = 'AsnCount',
  ContinentName = 'ContinentName',
  CountryName = 'CountryName',
  CountryNameCount = 'CountryNameCount',
  Disappeared = 'Disappeared',
  DomainCount = 'DomainCount',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  IpAddress = 'IPAddress',
  IpAddressCount = 'IPAddressCount',
  IpVersion = 'IPVersion'
}

export type SurfaceProjectionTechFilterValuesResponse = {
  __typename?: 'SurfaceProjectionTechFilterValuesResponse';
  domainName: Array<Scalars['String']['output']>;
  name: Array<Scalars['String']['output']>;
  port: Array<Scalars['String']['output']>;
  serviceProtocol: Array<Scalars['String']['output']>;
  version: Array<Scalars['String']['output']>;
};

export type SurfaceProjectionTechFilters = {
  name: SurfaceProjectionTechFiltersName;
  operator: SurfaceProjectionFilterOperator;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum SurfaceProjectionTechFiltersName {
  Active = 'Active',
  Disappeared = 'Disappeared',
  DomainCount = 'DomainCount',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  Name = 'Name',
  Port = 'Port',
  ServiceProtocol = 'ServiceProtocol',
  Version = 'Version',
  VersionCount = 'VersionCount'
}

export type SurfaceProjectionTechSortBy = {
  direction?: InputMaybe<SurfaceProjectionDirection>;
  name: SurfaceProjectionTechSortName;
};

export enum SurfaceProjectionTechSortName {
  Active = 'Active',
  Disappeared = 'Disappeared',
  DomainCount = 'DomainCount',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  Name = 'Name',
  Port = 'Port',
  ServiceProtocol = 'ServiceProtocol',
  Version = 'Version',
  VersionCount = 'VersionCount'
}

export enum SurfaceProjectionsBinSize {
  Day = 'Day',
  Month = 'Month',
  Week = 'Week'
}

export type SurfaceProjectionsBreachFilters = {
  name: SurfaceProjectionsBreachFiltersName;
  operator: SurfaceProjectionFilterOperator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SurfaceProjectionsBreachFiltersName {
  Disappeared = 'Disappeared',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  PolicyName = 'PolicyName',
  Severity = 'Severity',
  Status = 'Status',
  StatusUpdated = 'StatusUpdated'
}

export type SurfaceProjectionsBreachGroupByNoneEntity = {
  __typename?: 'SurfaceProjectionsBreachGroupByNoneEntity';
  assetToken: Scalars['String']['output'];
  disappeared?: Maybe<Scalars['AWSDateTime']['output']>;
  domainName: Scalars['String']['output'];
  firstSeen: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  policyId: Scalars['String']['output'];
  policyName: Scalars['String']['output'];
  severity: SurfaceProjectionsSeverities;
  status: Scalars['String']['output'];
  statusUpdated: Scalars['AWSDateTime']['output'];
};

export type SurfaceProjectionsBreachGroupByNoneInput = {
  filters?: InputMaybe<Array<SurfaceProjectionsBreachFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionsBreachSortBy>;
};

export type SurfaceProjectionsBreachGroupByNoneResponse = {
  __typename?: 'SurfaceProjectionsBreachGroupByNoneResponse';
  breaches: Array<SurfaceProjectionsBreachGroupByNoneEntity>;
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsBreachSortBy = {
  direction?: InputMaybe<SurfaceProjectionDirection>;
  name: SurfaceProjectionsBreachSortName;
};

export enum SurfaceProjectionsBreachSortName {
  Disappeared = 'Disappeared',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  PolicyName = 'PolicyName',
  Severity = 'Severity',
  Status = 'Status',
  StatusUpdated = 'StatusUpdated'
}

export type SurfaceProjectionsBreachesBySeverity = {
  __typename?: 'SurfaceProjectionsBreachesBySeverity';
  critical: Scalars['Int']['output'];
  high: Scalars['Int']['output'];
  information: Scalars['Int']['output'];
  low: Scalars['Int']['output'];
  medium: Scalars['Int']['output'];
};

export type SurfaceProjectionsBreachesBySeverityOverTimeEntry = {
  __typename?: 'SurfaceProjectionsBreachesBySeverityOverTimeEntry';
  appeared: SurfaceProjectionsBreachesBySeverity;
  currentTotal: SurfaceProjectionsBreachesBySeverity;
  reappeared: SurfaceProjectionsBreachesBySeverity;
  resolved: SurfaceProjectionsBreachesBySeverity;
  time: Scalars['String']['output'];
};

export type SurfaceProjectionsBreachesBySeverityOverTimeInput = {
  binSize?: InputMaybe<SurfaceProjectionsBinSize>;
  from: Scalars['String']['input'];
  severities?: InputMaybe<Array<SurfaceProjectionsSeverities>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type SurfaceProjectionsBreachesBySeverityOverTimeResponse = {
  __typename?: 'SurfaceProjectionsBreachesBySeverityOverTimeResponse';
  entries?: Maybe<Array<SurfaceProjectionsBreachesBySeverityOverTimeEntry>>;
};

export type SurfaceProjectionsCupsHeader = {
  __typename?: 'SurfaceProjectionsCupsHeader';
  key: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type SurfaceProjectionsDnsQueryFilters = {
  name: SurfaceProjectionsDnsQueryFiltersName;
  operator: SurfaceProjectionFilterOperator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SurfaceProjectionsDnsQueryFiltersName {
  AnswerRecordTypes = 'AnswerRecordTypes',
  AnswerRecords = 'AnswerRecords',
  DomainName = 'DomainName',
  FirstReceived = 'FirstReceived',
  FirstRecords = 'FirstRecords',
  LastChanged = 'LastChanged',
  LastProtocol = 'LastProtocol',
  LastResponse = 'LastResponse',
  LastResponseCode = 'LastResponseCode',
  LastServer = 'LastServer',
  RecordsDisappeared = 'RecordsDisappeared',
  Scope = 'Scope'
}

export type SurfaceProjectionsDnsQueryGroupByNoneEntity = {
  __typename?: 'SurfaceProjectionsDNSQueryGroupByNoneEntity';
  answerRecordTypes: Array<Scalars['String']['output']>;
  answerRecords: Array<Scalars['String']['output']>;
  assetToken: Scalars['String']['output'];
  domainName: Scalars['String']['output'];
  firstReceived: Scalars['AWSDateTime']['output'];
  firstRecords?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['String']['output'];
  lastChanged: Scalars['AWSDateTime']['output'];
  lastProtocol: Scalars['String']['output'];
  lastResponse: Scalars['String']['output'];
  lastResponseCode: Scalars['String']['output'];
  lastServer: Scalars['String']['output'];
  recordsDisappeared?: Maybe<Scalars['AWSDateTime']['output']>;
  scope: Scalars['String']['output'];
};

export type SurfaceProjectionsDnsQueryGroupByNoneInput = {
  filters?: InputMaybe<Array<SurfaceProjectionsDnsQueryFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SurfaceProjectionsDnsQuerySortBy>;
};

export type SurfaceProjectionsDnsQueryGroupByNoneResponse = {
  __typename?: 'SurfaceProjectionsDNSQueryGroupByNoneResponse';
  dnsQueries: Array<SurfaceProjectionsDnsQueryGroupByNoneEntity>;
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsDnsQuerySortBy = {
  direction?: InputMaybe<SurfaceProjectionDirection>;
  name: SurfaceProjectionsDnsQuerySortName;
};

export enum SurfaceProjectionsDnsQuerySortName {
  DomainName = 'DomainName',
  FirstReceived = 'FirstReceived',
  FirstRecords = 'FirstRecords',
  LastChanged = 'LastChanged',
  LastResponseCode = 'LastResponseCode',
  LastServer = 'LastServer',
  RecordsDisappeared = 'RecordsDisappeared',
  Scope = 'Scope'
}

export type SurfaceProjectionsDomainDetailsEntity = {
  __typename?: 'SurfaceProjectionsDomainDetailsEntity';
  assetToken: Scalars['String']['output'];
  disappeared?: Maybe<Scalars['String']['output']>;
  domainName: Scalars['String']['output'];
  firstSeen?: Maybe<Scalars['String']['output']>;
  surfaceState: SurfaceState;
};

export type SurfaceProjectionsDomainDetailsResponse = {
  __typename?: 'SurfaceProjectionsDomainDetailsResponse';
  details: SurfaceProjectionsDomainDetailsEntity;
  success: Scalars['Boolean']['output'];
};

export type SurfaceProjectionsDomainGroupByDomainEntity = {
  __typename?: 'SurfaceProjectionsDomainGroupByDomainEntity';
  activeAsNameCount: Scalars['Int']['output'];
  activeAsNames: Array<Scalars['String']['output']>;
  activeCountries: Array<Scalars['String']['output']>;
  activeCountryCount: Scalars['Int']['output'];
  activeIPAddresses: Array<Scalars['String']['output']>;
  activeIpAddressCount: Scalars['Int']['output'];
  activeTechCount: Scalars['Int']['output'];
  activeTechVersions: Array<Scalars['String']['output']>;
  activeTechs: Array<Scalars['String']['output']>;
  assetToken: Scalars['String']['output'];
  disappeared?: Maybe<Scalars['String']['output']>;
  dnsRecordTypes: Array<Scalars['String']['output']>;
  dnsRecordTypesCount: Scalars['Int']['output'];
  dnsResponseCodes: Array<Scalars['String']['output']>;
  domainName: Scalars['String']['output'];
  firstSeen?: Maybe<Scalars['String']['output']>;
  openPortCount: Scalars['Int']['output'];
  openPorts: Array<Scalars['Int']['output']>;
  surfaceState: SurfaceState;
  vulnerabilitySeverityGraph: PrioritizationSeverityGraphEntity;
};

export type SurfaceProjectionsDomainGroupByDomainResponse = {
  __typename?: 'SurfaceProjectionsDomainGroupByDomainResponse';
  domains?: Maybe<Array<SurfaceProjectionsDomainGroupByDomainEntity>>;
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsIpGroupByAsNameEntity = {
  __typename?: 'SurfaceProjectionsIPGroupByAsNameEntity';
  active: Scalars['Boolean']['output'];
  asDomain?: Maybe<Scalars['String']['output']>;
  asName?: Maybe<Scalars['String']['output']>;
  asnCount: Scalars['Int']['output'];
  countryNameCount: Scalars['Int']['output'];
  disappeared?: Maybe<Scalars['AWSDateTime']['output']>;
  domainCount: Scalars['Int']['output'];
  firstSeen: Scalars['AWSDateTime']['output'];
  ipAddressCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsIpGroupByAsNameResponse = {
  __typename?: 'SurfaceProjectionsIPGroupByAsNameResponse';
  hasMore: Scalars['Boolean']['output'];
  ips?: Maybe<Array<SurfaceProjectionsIpGroupByAsNameEntity>>;
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsIpGroupByCountryEntity = {
  __typename?: 'SurfaceProjectionsIPGroupByCountryEntity';
  active: Scalars['Boolean']['output'];
  asNameCount: Scalars['Int']['output'];
  asnCount: Scalars['Int']['output'];
  continentName?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  disappeared?: Maybe<Scalars['AWSDateTime']['output']>;
  domainCount: Scalars['Int']['output'];
  firstSeen: Scalars['AWSDateTime']['output'];
  ipAddressCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsIpGroupByCountryResponse = {
  __typename?: 'SurfaceProjectionsIPGroupByCountryResponse';
  hasMore: Scalars['Boolean']['output'];
  ips?: Maybe<Array<SurfaceProjectionsIpGroupByCountryEntity>>;
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsIpGroupByIpEntity = {
  __typename?: 'SurfaceProjectionsIPGroupByIPEntity';
  active: Scalars['Boolean']['output'];
  asDomain?: Maybe<Scalars['String']['output']>;
  asName?: Maybe<Scalars['String']['output']>;
  asn?: Maybe<Scalars['String']['output']>;
  continent?: Maybe<Scalars['String']['output']>;
  continentName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  disappeared?: Maybe<Scalars['AWSDateTime']['output']>;
  domainCount: Scalars['Int']['output'];
  enriched: Scalars['Boolean']['output'];
  firstSeen: Scalars['AWSDateTime']['output'];
  ipAddress: Scalars['String']['output'];
  ipVersion: SurfaceProjectionsIpVersion;
};

export type SurfaceProjectionsIpGroupByIpResponse = {
  __typename?: 'SurfaceProjectionsIPGroupByIPResponse';
  hasMore: Scalars['Boolean']['output'];
  ips?: Maybe<Array<SurfaceProjectionsIpGroupByIpEntity>>;
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsIpGroupByNoneEntity = {
  __typename?: 'SurfaceProjectionsIPGroupByNoneEntity';
  active: Scalars['Boolean']['output'];
  asDomain?: Maybe<Scalars['String']['output']>;
  asName?: Maybe<Scalars['String']['output']>;
  asn?: Maybe<Scalars['String']['output']>;
  assetToken: Scalars['String']['output'];
  continent?: Maybe<Scalars['String']['output']>;
  continentName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  disappeared?: Maybe<Scalars['AWSDateTime']['output']>;
  domainName: Scalars['String']['output'];
  enriched: Scalars['Boolean']['output'];
  firstSeen: Scalars['AWSDateTime']['output'];
  ipAddress: Scalars['String']['output'];
  ipVersion: SurfaceProjectionsIpVersion;
};

export type SurfaceProjectionsIpGroupByNoneResponse = {
  __typename?: 'SurfaceProjectionsIPGroupByNoneResponse';
  hasMore: Scalars['Boolean']['output'];
  ips?: Maybe<Array<SurfaceProjectionsIpGroupByNoneEntity>>;
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export enum SurfaceProjectionsIpVersion {
  IPv4 = 'IPv4',
  IPv6 = 'IPv6'
}

export type SurfaceProjectionsPortGroupByNoneEntity = {
  __typename?: 'SurfaceProjectionsPortGroupByNoneEntity';
  assetToken: Scalars['String']['output'];
  disappeared?: Maybe<Scalars['String']['output']>;
  domainName: Scalars['String']['output'];
  firstScanned: Scalars['String']['output'];
  ipAddress: Scalars['String']['output'];
  port: Scalars['Int']['output'];
  status: SurfaceProjectionPortStatus;
  uuid: Scalars['String']['output'];
};

export type SurfaceProjectionsPortGroupByNoneResponse = {
  __typename?: 'SurfaceProjectionsPortGroupByNoneResponse';
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  ports?: Maybe<Array<SurfaceProjectionsPortGroupByNoneEntity>>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsPortGroupByPortEntity = {
  __typename?: 'SurfaceProjectionsPortGroupByPortEntity';
  disappeared?: Maybe<Scalars['String']['output']>;
  domainCount: Scalars['Int']['output'];
  domainOpenCount: Scalars['Int']['output'];
  firstScanned: Scalars['String']['output'];
  ipAddressCount: Scalars['Int']['output'];
  ipAddressOpenCount: Scalars['Int']['output'];
  port: Scalars['Int']['output'];
  status: SurfaceProjectionPortStatus;
  uuid: Scalars['String']['output'];
};

export type SurfaceProjectionsPortGroupByPortResponse = {
  __typename?: 'SurfaceProjectionsPortGroupByPortResponse';
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  ports?: Maybe<Array<SurfaceProjectionsPortGroupByPortEntity>>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export enum SurfaceProjectionsSeverities {
  Critical = 'Critical',
  High = 'High',
  Information = 'Information',
  Low = 'Low',
  Medium = 'Medium'
}

export type SurfaceProjectionsTechGroupByNameEntity = {
  __typename?: 'SurfaceProjectionsTechGroupByNameEntity';
  active: Scalars['Boolean']['output'];
  categories: Array<Scalars['String']['output']>;
  disappeared?: Maybe<Scalars['String']['output']>;
  domainCount: Scalars['Int']['output'];
  firstSeen: Scalars['String']['output'];
  name: Scalars['String']['output'];
  versionCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsTechGroupByNameResponse = {
  __typename?: 'SurfaceProjectionsTechGroupByNameResponse';
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  techs: Array<SurfaceProjectionsTechGroupByNameEntity>;
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsTechGroupByNameVersionEntity = {
  __typename?: 'SurfaceProjectionsTechGroupByNameVersionEntity';
  active: Scalars['Boolean']['output'];
  categories: Array<Scalars['String']['output']>;
  disappeared?: Maybe<Scalars['String']['output']>;
  domainCount: Scalars['Int']['output'];
  firstSeen: Scalars['String']['output'];
  name: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type SurfaceProjectionsTechGroupByNameVersionResponse = {
  __typename?: 'SurfaceProjectionsTechGroupByNameVersionResponse';
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  techs: Array<SurfaceProjectionsTechGroupByNameVersionEntity>;
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsTechGroupByNoneEntity = {
  __typename?: 'SurfaceProjectionsTechGroupByNoneEntity';
  active: Scalars['Boolean']['output'];
  assetToken: Scalars['String']['output'];
  categories: Array<Scalars['String']['output']>;
  disappeared?: Maybe<Scalars['String']['output']>;
  domainName: Scalars['String']['output'];
  firstSeen: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  port?: Maybe<Scalars['String']['output']>;
  serviceProtocol?: Maybe<Scalars['String']['output']>;
  techId: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type SurfaceProjectionsTechGroupByNoneResponse = {
  __typename?: 'SurfaceProjectionsTechGroupByNoneResponse';
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  techs: Array<SurfaceProjectionsTechGroupByNoneEntity>;
  totalCount: Scalars['Int']['output'];
};

export type SurfaceProjectionsVulnerabilityByDomainTemp = {
  __typename?: 'SurfaceProjectionsVulnerabilityByDomainTemp';
  active: Scalars['Boolean']['output'];
  assetToken: Scalars['String']['output'];
  cupsVersion: Scalars['String']['output'];
  domainName: Scalars['String']['output'];
  firstSeen: Scalars['AWSDateTime']['output'];
  httpHeaders: Array<SurfaceProjectionsCupsHeader>;
  httpMethod: Scalars['String']['output'];
  httpPath: Scalars['String']['output'];
  httpVersion: Scalars['String']['output'];
  id: Scalars['String']['output'];
  ipAddress: Scalars['String']['output'];
  port: Scalars['Int']['output'];
  protocol: Scalars['String']['output'];
};

export enum SurfaceState {
  ActiveDns = 'ActiveDNS',
  ActiveIPs = 'ActiveIPs',
  NoDns = 'NoDNS',
  OpenPorts = 'OpenPorts',
  ServiceDetected = 'ServiceDetected'
}

export type SyncAllowedAppsResponse = {
  __typename?: 'SyncAllowedAppsResponse';
  allowedApps: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Team = {
  __typename?: 'Team';
  name: Scalars['String']['output'];
  segment: Scalars['String']['output'];
  teamToken: Scalars['String']['output'];
};

export type TeamInfo = {
  __typename?: 'TeamInfo';
  canChange: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  memberToken: Scalars['String']['output'];
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export enum TeamResultFilter {
  Failure = 'Failure',
  Success = 'Success'
}

export type TeamResultResponse = {
  __typename?: 'TeamResultResponse';
  count: Scalars['Int']['output'];
};

export type Test = {
  __typename?: 'Test';
  CVSS: Scalars['Float']['output'];
  product: Array<Product>;
  published: Scalars['String']['output'];
  severity: Severity;
  vulnerability: Scalars['String']['output'];
};

export type TestFeedConfigurationResponse = {
  __typename?: 'TestFeedConfigurationResponse';
  success: Scalars['Boolean']['output'];
};

export type UpdateApiKeyInput = {
  accessList: Array<Scalars['String']['input']>;
  apiKey: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  signMessages: Scalars['Boolean']['input'];
};

export type UpdateApiKeyResponse = {
  __typename?: 'UpdateAPIKeyResponse';
  apiKeyDetails: ApiKeyDetails;
};

export type UpdateAssetPatternInput = {
  pattern: Scalars['String']['input'];
  subTeamToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type UpdateAssetPatternResponse = {
  __typename?: 'UpdateAssetPatternResponse';
  success: Scalars['Boolean']['output'];
};

export type UpdateSubTeamInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type UpdateSubTeamResponse = {
  __typename?: 'UpdateSubTeamResponse';
  success: Scalars['Boolean']['output'];
};

export type UpdateUserRoleInput = {
  role: Role;
  token: Scalars['String']['input'];
};

export type UpdateUserRoleResponse = {
  __typename?: 'UpdateUserRoleResponse';
  success: Scalars['Boolean']['output'];
};

export type UpdateVulnerabilityTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  authentication: Scalars['String']['output'];
  created: Scalars['Time']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['Time']['output']>;
  lastName: Scalars['String']['output'];
  role: Scalars['String']['output'];
  teams: Array<TeamInfo>;
  token: Scalars['String']['output'];
};

export type VerifyAssetRequest = {
  assetToken: Scalars['String']['input'];
  verificationType: AssetVerificationType;
};

export type VerifyAssetResponse = {
  __typename?: 'VerifyAssetResponse';
  attempts: Array<AssetVerificationAttempt>;
  authRequested: Scalars['Boolean']['output'];
  verified: Scalars['Boolean']['output'];
};

export type VulnerabilitiesExportFilterInput = {
  assetToken?: InputMaybe<Array<Scalars['String']['input']>>;
  customTag?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cvss2ScoreLower?: InputMaybe<Scalars['Float']['input']>;
  cvss2ScoreUpper?: InputMaybe<Scalars['Float']['input']>;
  cvss3ScoreLower?: InputMaybe<Scalars['Float']['input']>;
  cvss3ScoreUpper?: InputMaybe<Scalars['Float']['input']>;
  cvss3_1ScoreLower?: InputMaybe<Scalars['Float']['input']>;
  cvss3_1ScoreUpper?: InputMaybe<Scalars['Float']['input']>;
  domain?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exclude?: InputMaybe<VulnerabilitiesExportFilterInputExclude>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeenLower?: InputMaybe<Scalars['Int']['input']>;
  rootAssetToken?: InputMaybe<Array<Scalars['String']['input']>>;
  scanSource?: InputMaybe<Scalars['String']['input']>;
  scanSources?: InputMaybe<Array<Scalars['String']['input']>>;
  searchParam?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  severityV3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<VulnerabilityStatus>>;
  subteam?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetToken?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VulnerabilitiesExportFilterInputExclude = {
  customTag?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  domain?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scanSource?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  severityV3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetToken?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VulnerabilitiesFilterInput = {
  assetToken?: InputMaybe<Array<Scalars['String']['input']>>;
  customTag?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cvss2ScoreLower?: InputMaybe<Scalars['Float']['input']>;
  cvss2ScoreUpper?: InputMaybe<Scalars['Float']['input']>;
  cvss3ScoreLower?: InputMaybe<Scalars['Float']['input']>;
  cvss3ScoreUpper?: InputMaybe<Scalars['Float']['input']>;
  cvss3_1ScoreLower?: InputMaybe<Scalars['Float']['input']>;
  cvss3_1ScoreUpper?: InputMaybe<Scalars['Float']['input']>;
  domain?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exclude?: InputMaybe<VulnerabilitiesFilterInputExclude>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeenLower?: InputMaybe<Scalars['Int']['input']>;
  rootAssetToken?: InputMaybe<Array<Scalars['String']['input']>>;
  scanSource?: InputMaybe<Scalars['String']['input']>;
  scanSources?: InputMaybe<Array<Scalars['String']['input']>>;
  searchParam?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  severityV3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<VulnerabilityStatus>>;
  subteam?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetToken?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VulnerabilitiesFilterInputExclude = {
  customTag?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  domain?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scanSource?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  severityV3?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetToken?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VulnerabilitiesPaginationInput = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type VulnerabilitiesResponse = {
  __typename?: 'VulnerabilitiesResponse';
  nodes: Array<Vulnerability>;
  total: Scalars['Int']['output'];
  totalByState?: Maybe<VulnerabilitiesTotalByState>;
};

export type VulnerabilitiesSortingInput = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<SortingDirection>;
};

export type VulnerabilitiesTotalByState = {
  __typename?: 'VulnerabilitiesTotalByState';
  acceptedRisk: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  falsePositive: Scalars['Int']['output'];
  new: Scalars['Int']['output'];
  patched: Scalars['Int']['output'];
  regression: Scalars['Int']['output'];
};

export type Vulnerability = {
  __typename?: 'Vulnerability';
  asset?: Maybe<Asset>;
  asset_token?: Maybe<Scalars['String']['output']>;
  base_finding?: Maybe<BaseFinding>;
  basefinding_uuid: Scalars['String']['output'];
  custom_tags?: Maybe<Array<Scalars['String']['output']>>;
  finding_score?: Maybe<Array<Score>>;
  finding_title: Scalars['String']['output'];
  hostname?: Maybe<Scalars['String']['output']>;
  isAI: Scalars['Boolean']['output'];
  last_seen: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  module_id?: Maybe<Scalars['String']['output']>;
  root_asset_token?: Maybe<Scalars['String']['output']>;
  scan_source?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['String']['output']>;
  start_date: Scalars['String']['output'];
  status: VulnerabilityStatus;
  target_token: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type VulnerabilityExportMetadata = {
  __typename?: 'VulnerabilityExportMetadata';
  createdAt: Scalars['String']['output'];
  fileFormat: ExportFileFormat;
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  teamToken: Scalars['String']['output'];
  userToken: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type VulnerabilityFilter = {
  __typename?: 'VulnerabilityFilter';
  accessLevel?: Maybe<FilterAccessLevel>;
  name: Scalars['String']['output'];
  parameters: FilterParameters;
  teamToken: Scalars['String']['output'];
  userToken: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type VulnerabilityFilterV2 = {
  __typename?: 'VulnerabilityFilterV2';
  filterName: Scalars['String']['output'];
  params: FilterParametersV2;
};

export enum VulnerabilityProjectionDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum VulnerabilityProjectionFilterOperator {
  After = 'After',
  Before = 'Before',
  Contains = 'Contains',
  ContainsAllOf = 'ContainsAllOf',
  ContainsAnyOf = 'ContainsAnyOf',
  ContainsItemNotInList = 'ContainsItemNotInList',
  DoesNotContain = 'DoesNotContain',
  DoesNotContainAllOf = 'DoesNotContainAllOf',
  DoesNotContainAnyOf = 'DoesNotContainAnyOf',
  DoesNotEndWith = 'DoesNotEndWith',
  DoesNotStartWith = 'DoesNotStartWith',
  Empty = 'Empty',
  EndsWith = 'EndsWith',
  GreaterThan = 'GreaterThan',
  IsInTheLast = 'IsInTheLast',
  Last7Days = 'Last7Days',
  Last14Days = 'Last14Days',
  Last30Days = 'Last30Days',
  LessThan = 'LessThan',
  NotEmpty = 'NotEmpty',
  StartsWith = 'StartsWith'
}

export enum VulnerabilityProjectionFiltersName {
  Cve = 'CVE',
  Cvss = 'CVSS',
  Cwe = 'CWE',
  Disappeared = 'Disappeared',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  LastSeen = 'LastSeen',
  Path = 'Path',
  ScanSource = 'ScanSource',
  Status = 'Status',
  Title = 'Title'
}

export enum VulnerabilityProjectionSortName {
  Cve = 'CVE',
  Cvss = 'CVSS',
  Cwe = 'CWE',
  Disappeared = 'Disappeared',
  DomainName = 'DomainName',
  FirstSeen = 'FirstSeen',
  LastSeen = 'LastSeen',
  Path = 'Path',
  ScanSource = 'ScanSource',
  Status = 'Status',
  Title = 'Title'
}

export type VulnerabilityProjectionsVulnerabilitiesFilters = {
  name: VulnerabilityProjectionFiltersName;
  operator: VulnerabilityProjectionFilterOperator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type VulnerabilityProjectionsVulnerabilitiesGroupByNoneEntity = {
  __typename?: 'VulnerabilityProjectionsVulnerabilitiesGroupByNoneEntity';
  CVE: Scalars['String']['output'];
  CVSS: Scalars['String']['output'];
  CWE: Scalars['String']['output'];
  assetToken: Scalars['String']['output'];
  description: Scalars['String']['output'];
  disappeared?: Maybe<Scalars['AWSDateTime']['output']>;
  domainName: Scalars['String']['output'];
  firstSeen: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  lastSeen: Scalars['AWSDateTime']['output'];
  path: Scalars['String']['output'];
  port?: Maybe<Scalars['Int']['output']>;
  scanSource: Scalars['String']['output'];
  scheme?: Maybe<Scalars['String']['output']>;
  status: VulnerabilityProjectionsVulnerabilityStatus;
  title: Scalars['String']['output'];
};

export type VulnerabilityProjectionsVulnerabilitiesGroupByNoneInput = {
  filters?: InputMaybe<Array<VulnerabilityProjectionsVulnerabilitiesFilters>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<VulnerabilityProjectionsVulnerabilitiesSortBy>;
};

export type VulnerabilityProjectionsVulnerabilitiesGroupByNoneResponse = {
  __typename?: 'VulnerabilityProjectionsVulnerabilitiesGroupByNoneResponse';
  hasMore: Scalars['Boolean']['output'];
  offset: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
  vulnerabilities: Array<VulnerabilityProjectionsVulnerabilitiesGroupByNoneEntity>;
};

export type VulnerabilityProjectionsVulnerabilitiesSortBy = {
  direction?: InputMaybe<VulnerabilityProjectionDirection>;
  name: VulnerabilityProjectionSortName;
};

export enum VulnerabilityProjectionsVulnerabilityStatus {
  Lowered = 'Lowered',
  Raised = 'Raised'
}

export enum VulnerabilityStatus {
  AcceptedRisk = 'accepted_risk',
  Active = 'active',
  FalsePositive = 'false_positive',
  New = 'new',
  Patched = 'patched',
  Regression = 'regression'
}

export enum VulnerabilityStatusAction {
  Added = 'added',
  Removed = 'removed'
}

export enum VulnerabilityStatusV2 {
  AcceptedRisk = 'accepted_risk',
  Active = 'active',
  FalsePositive = 'false_positive',
  New = 'new',
  Patched = 'patched',
  Regression = 'regression'
}

export type VulnerabilityTag = {
  __typename?: 'VulnerabilityTag';
  TagType?: Maybe<Scalars['String']['output']>;
  /** Only rgb and hex colors are supported */
  color: Scalars['String']['output'];
  createdByToken?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  teamToken?: Maybe<Scalars['String']['output']>;
  updatedByToken?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};

export enum WafCheckOrigin {
  ApplicationScanning = 'APPLICATION_SCANNING',
  SurfaceMonitoring = 'SURFACE_MONITORING'
}

export type WafDomain = {
  __typename?: 'WAFDomain';
  domainName: Scalars['String']['output'];
  lastDetected: Scalars['AWSDateTime']['output'];
  status: WafDomainStatus;
};

export type WafDomainPerOrigin = {
  __typename?: 'WAFDomainPerOrigin';
  domainName: Scalars['String']['output'];
  lastDetected: Scalars['AWSDateTime']['output'];
  status: WafDomainStatus;
  technology: WafTechnology;
};

export type WafDomainPerScanProfile = {
  __typename?: 'WAFDomainPerScanProfile';
  domainName: Scalars['String']['output'];
  lastDetected: Scalars['AWSDateTime']['output'];
  scanProfile: Scalars['String']['output'];
  status: WafDomainStatus;
  technology: WafTechnology;
};

export enum WafDomainStatus {
  Active = 'ACTIVE',
  Rechecking = 'RECHECKING'
}

export type WafDomainsForScanProfilesInput = {
  scanProfiles: Array<Scalars['String']['input']>;
};

export type WafDomainsForScanProfilesResponse = {
  __typename?: 'WAFDomainsForScanProfilesResponse';
  domains: Array<WafDomainPerScanProfile>;
};

export type WafDomainsForTechnologyInput = {
  technology: WafTechnology;
};

export type WafDomainsPerOriginInput = {
  origin: WafCheckOrigin;
};

export type WafDomainsPerOriginResponse = {
  __typename?: 'WAFDomainsPerOriginResponse';
  domains: Array<WafDomainPerOrigin>;
};

export type WafDomainsResponse = {
  __typename?: 'WAFDomainsResponse';
  domains: Array<WafDomain>;
};

export type WafScheduleRecheckInput = {
  domainName: Scalars['String']['input'];
};

export type WafScheduleRecheckResponse = {
  __typename?: 'WAFScheduleRecheckResponse';
  success: Scalars['Boolean']['output'];
};

export type WafStatusResponse = {
  __typename?: 'WAFStatusResponse';
  technologies: Array<WafTechnologyStatus>;
};

export enum WafTechnology {
  Aesecure = 'AESECURE',
  Airlock = 'AIRLOCK',
  Akamai = 'AKAMAI',
  Alertlogic = 'ALERTLOGIC',
  Alibaba = 'ALIBABA',
  Anyu = 'ANYU',
  Apache = 'APACHE',
  Applicure = 'APPLICURE',
  Awscloudfront = 'AWSCLOUDFRONT',
  Cloudflare = 'CLOUDFLARE',
  Generic = 'GENERIC',
  Godaddy = 'GODADDY',
  Imperva = 'IMPERVA',
  Nginx = 'NGINX',
  Tencent = 'TENCENT',
  Unknown = 'UNKNOWN',
  Wordfence = 'WORDFENCE'
}

export type WafTechnologyStatus = {
  __typename?: 'WAFTechnologyStatus';
  domainCount: Scalars['Int']['output'];
  firstSeenAt: Scalars['AWSDateTime']['output'];
  lastSeenAt: Scalars['AWSDateTime']['output'];
  technology: WafTechnology;
};

export type WafTotalUniqueDomainsResponse = {
  __typename?: 'WAFTotalUniqueDomainsResponse';
  domainsBlockedInAS: Scalars['Int']['output'];
  domainsBlockedInSM: Scalars['Int']['output'];
  totalUniqueDomains: Scalars['Int']['output'];
};

export type WorkatoAuthenticateEchoInput = {
  mode: WorkatoAuthenticateEchoModeEnum;
};

export enum WorkatoAuthenticateEchoModeEnum {
  Development = 'DEVELOPMENT',
  Local = 'LOCAL',
  Normal = 'NORMAL'
}

export type WorkatoAuthenticateResponse = {
  __typename?: 'WorkatoAuthenticateResponse';
  token: Scalars['String']['output'];
};

export type WorkatoCustomerDetailsResponse = {
  __typename?: 'WorkatoCustomerDetailsResponse';
  connectionCount: Scalars['Int']['output'];
  recipeCount: Scalars['Int']['output'];
};

export type WorkatoCustomerExistsResponse = {
  __typename?: 'WorkatoCustomerExistsResponse';
  exists: Scalars['Boolean']['output'];
};

export type WorkatoRecipeTemplate = {
  __typename?: 'WorkatoRecipeTemplate';
  connection: Scalars['String']['output'];
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type WorkatoRecipeTemplatesResponse = {
  __typename?: 'WorkatoRecipeTemplatesResponse';
  recipeTemplates: Array<WorkatoRecipeTemplate>;
};

export type CreateApplicationScanProfileWithScheduleMutationVariables = Exact<{
  input: CreateApplicationScanProfileWithScheduleRequest;
}>;


export type CreateApplicationScanProfileWithScheduleMutation = { __typename?: 'Mutation', createApplicationScanProfileWithSchedule: { __typename?: 'CreateApplicationScanProfileWithScheduleResponse', success: boolean, scanProfileToken?: string | null, scanProfileName?: string | null, scanProfileEndpoint?: string | null, nextScan?: number | null, error?: CreateApplicationScanProfileWithScheduleError | null } };

export type GetScanProfilesForTeamQueryVariables = Exact<{
  pagination?: InputMaybe<LimitOffsetPagination>;
  filters?: InputMaybe<ScanProfileFilters>;
}>;


export type GetScanProfilesForTeamQuery = { __typename?: 'Query', getScanProfilesForTeam: { __typename?: 'ScanProfileListResponse', totalCount: number, scanProfiles: Array<{ __typename?: 'ScanProfileListItem', assetToken?: string | null, token: string, endpoint: string, name: string, lastScanStatus: ScanStatus, lastScanEndedTime?: number | null, lastScanResult?: ScanResult | null, lastScanErrors?: Array<ScanError | null> | null, lastScanFatal?: ScanFatal | null, nextScanTime?: number | null, schedule?: ScanSchedule | null, reportToken?: string | null, isBillingActive: boolean }> } };

export type TeamSurfaceMonitoringBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamSurfaceMonitoringBalanceQuery = { __typename?: 'Query', assetMonitoringBalance: { __typename?: 'AssetMonitoringBalance', capacity: number, currentUsage: number } };

export type BaseAssetFragment = { __typename?: 'Asset', name: string, type: AssetType, token: string, status: AssetStatus, createdAt: any };

export type OriginCategoriesOnAssetFragment = { __typename?: 'Asset', originCategories: Array<{ __typename?: 'AssetOrigin', name: string, type: AssetOriginType }> };

export type GetAssetDetailsQueryVariables = Exact<{
  assetToken: Scalars['String']['input'];
}>;


export type GetAssetDetailsQuery = { __typename?: 'Query', getAsset?: { __typename?: 'Asset', createdAt: any, lastSeen?: any | null, discovered?: any | null, isRoot: boolean, status: AssetStatus, name: string, type: AssetType, token: string, rootAsset?: { __typename?: 'Asset', token: string, name: string } | null, vulnerabilitySeverityGraph: { __typename?: 'PrioritizationSeverityGraphEntity', domain: string, timeFrame: string, severityGraph: { __typename?: 'PrioritizationSeverityGraph', high?: number | null, critical?: number | null, low?: number | null, medium?: number | null, total?: number | null } }, originCategories: Array<{ __typename?: 'AssetOrigin', name: string, type: AssetOriginType }> } | null };

export type GetBillingInternalForTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBillingInternalForTeamQuery = { __typename?: 'Query', billingInternal: { __typename?: 'BillingInternalResponse', customer: { __typename?: 'Customer', canScaleAboveContract: boolean, maxScanProfilesOnTrial: number, paymentMethod: PaymentMethod, planType: PlanType, token: string, subscription?: { __typename?: 'SubscriptionDetails', token: string, planType: PlanType, status: SubscriptionStatus, scanProfileCapacity: number, surfaceMonitoringCapacity: number } | null } } };

export type DnsQueryGroupByNoneQueryVariables = Exact<{
  input?: InputMaybe<SurfaceProjectionsDnsQueryGroupByNoneInput>;
}>;


export type DnsQueryGroupByNoneQuery = { __typename?: 'Query', surfaceProjectionsDNSQueryGroupByNone?: { __typename?: 'SurfaceProjectionsDNSQueryGroupByNoneResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, dnsQueries: Array<{ __typename?: 'SurfaceProjectionsDNSQueryGroupByNoneEntity', id: string, scope: string, domainName: string, assetToken: string, firstReceived: any, lastChanged: any, lastServer: string, lastResponse: string, lastResponseCode: string, lastProtocol: string, answerRecords: Array<string>, answerRecordTypes: Array<string>, firstRecords?: any | null, recordsDisappeared?: any | null }> } | null };

export type DnsQueryFilterValuesQueryVariables = Exact<{
  filters?: InputMaybe<Array<SurfaceProjectionFilterValuesFilters> | SurfaceProjectionFilterValuesFilters>;
}>;


export type DnsQueryFilterValuesQuery = { __typename?: 'Query', surfaceProjectionsDNSQueryFilterValues?: { __typename?: 'SurfaceProjectionDNSQueryFilterValuesResponse', domainName?: Array<string> | null, questionRecordType?: Array<string> | null, responseCode?: Array<string> | null, answerRecordType?: Array<string> | null } | null };

export type DnsQueryGroupByNoneQuickFilterQueryVariables = Exact<{
  filters?: InputMaybe<Array<SurfaceProjectionsDnsQueryFilters> | SurfaceProjectionsDnsQueryFilters>;
}>;


export type DnsQueryGroupByNoneQuickFilterQuery = { __typename?: 'Query', surfaceProjectionsDNSQueryGroupByNone?: { __typename?: 'SurfaceProjectionsDNSQueryGroupByNoneResponse', success: boolean, totalCount: number } | null };

export type DomainsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionDomainFilters> | SurfaceProjectionDomainFilters>;
  sortBy?: InputMaybe<SurfaceProjectionDomainSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type DomainsQuery = { __typename?: 'Query', surfaceProjectionsDomainGroupByDomain?: { __typename?: 'SurfaceProjectionsDomainGroupByDomainResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, domains?: Array<{ __typename?: 'SurfaceProjectionsDomainGroupByDomainEntity', domainName: string, assetToken: string, surfaceState: SurfaceState, activeTechCount: number, activeTechs: Array<string>, openPortCount: number, openPorts: Array<number>, activeAsNameCount: number, activeAsNames: Array<string>, activeIpAddressCount: number, activeCountryCount: number, firstSeen?: string | null, disappeared?: string | null, activeCountries: Array<string>, dnsRecordTypes: Array<string>, dnsRecordTypesCount: number, dnsResponseCodes: Array<string> }> | null } | null };

export type SurfaceProjectionsDomainDetailsQueryVariables = Exact<{
  assetToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SurfaceProjectionsDomainDetailsQuery = { __typename?: 'Query', surfaceProjectionsDomainDetails?: { __typename?: 'SurfaceProjectionsDomainDetailsResponse', success: boolean, details: { __typename?: 'SurfaceProjectionsDomainDetailsEntity', firstSeen?: string | null, disappeared?: string | null, domainName: string, assetToken: string, surfaceState: SurfaceState } } | null };

export type AttackSurfaceDomainsPolicyQueryVariables = Exact<{
  filters?: InputMaybe<Array<SurfaceProjectionDomainFilters> | SurfaceProjectionDomainFilters>;
}>;


export type AttackSurfaceDomainsPolicyQuery = { __typename?: 'Query', surfaceProjectionsDomainGroupByDomain?: { __typename?: 'SurfaceProjectionsDomainGroupByDomainResponse', success: boolean, totalCount: number } | null };

export type DomainFilterValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type DomainFilterValuesQuery = { __typename?: 'Query', surfaceProjectionsDomainFilterValues?: { __typename?: 'SurfaceProjectionDomainFilterValuesResponse', port?: Array<string> | null, techs?: Array<string> | null, asNames?: Array<string> | null, activeCountries?: Array<string> | null, dnsRecordTypes?: Array<string> | null, dnsResponseCodes?: Array<string> | null } | null };

export type DomainsQuickFilterQueryVariables = Exact<{
  filters?: InputMaybe<Array<SurfaceProjectionDomainFilters> | SurfaceProjectionDomainFilters>;
}>;


export type DomainsQuickFilterQuery = { __typename?: 'Query', surfaceProjectionsDomainGroupByDomain?: { __typename?: 'SurfaceProjectionsDomainGroupByDomainResponse', success: boolean, totalCount: number } | null };

export type IPsGroupedByIpQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionFilters> | SurfaceProjectionFilters>;
  sortBy?: InputMaybe<SurfaceProjectionSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type IPsGroupedByIpQuery = { __typename?: 'Query', surfaceProjectionsIPGroupByIP?: { __typename?: 'SurfaceProjectionsIPGroupByIPResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, ips?: Array<{ __typename?: 'SurfaceProjectionsIPGroupByIPEntity', active: boolean, ipAddress: string, domainCount: number, firstSeen: any, disappeared?: any | null, enriched: boolean, countryName?: string | null, asn?: string | null, asDomain?: string | null, asName?: string | null, continentName?: string | null, ipVersion: SurfaceProjectionsIpVersion }> | null } | null };

export type IPsGroupedByAsNameQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionFilters> | SurfaceProjectionFilters>;
  sortBy?: InputMaybe<SurfaceProjectionSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type IPsGroupedByAsNameQuery = { __typename?: 'Query', surfaceProjectionsIPGroupByAsName?: { __typename?: 'SurfaceProjectionsIPGroupByAsNameResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, ips?: Array<{ __typename?: 'SurfaceProjectionsIPGroupByAsNameEntity', asName?: string | null, asnCount: number, ipAddressCount: number, countryNameCount: number, domainCount: number, active: boolean, firstSeen: any, disappeared?: any | null, asDomain?: string | null }> | null } | null };

export type IPsGroupedByCountryQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionFilters> | SurfaceProjectionFilters>;
  sortBy?: InputMaybe<SurfaceProjectionSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type IPsGroupedByCountryQuery = { __typename?: 'Query', surfaceProjectionsIPGroupByCountry?: { __typename?: 'SurfaceProjectionsIPGroupByCountryResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, ips?: Array<{ __typename?: 'SurfaceProjectionsIPGroupByCountryEntity', active: boolean, asNameCount: number, asnCount: number, continentName?: string | null, countryName?: string | null, disappeared?: any | null, domainCount: number, firstSeen: any, ipAddressCount: number }> | null } | null };

export type IPsGroupedByNoneQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionFilters> | SurfaceProjectionFilters>;
  sortBy?: InputMaybe<SurfaceProjectionSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type IPsGroupedByNoneQuery = { __typename?: 'Query', surfaceProjectionsIPGroupByNone?: { __typename?: 'SurfaceProjectionsIPGroupByNoneResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, ips?: Array<{ __typename?: 'SurfaceProjectionsIPGroupByNoneEntity', active: boolean, ipAddress: string, domainName: string, firstSeen: any, disappeared?: any | null, enriched: boolean, countryName?: string | null, asn?: string | null, asDomain?: string | null, asName?: string | null, continentName?: string | null, ipVersion: SurfaceProjectionsIpVersion, assetToken: string }> | null } | null };

export type IpFilterValuesQueryVariables = Exact<{
  filters?: InputMaybe<Array<SurfaceProjectionFilterValuesFilters> | SurfaceProjectionFilterValuesFilters>;
  newFlow?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type IpFilterValuesQuery = { __typename?: 'Query', surfaceProjectionsIPFilterValues?: { __typename?: 'SurfaceProjectionIPFilterValuesResponse', ipVersion: Array<SurfaceProjectionsIpVersion>, asDomain: Array<string>, asn: Array<string>, asName: Array<string>, domainName: Array<string>, countryName: Array<string>, ipAddress: Array<string>, continentName: Array<string> } | null };

export type OverviewAssetNetworkQueryVariables = Exact<{
  asset: Scalars['String']['input'];
}>;


export type OverviewAssetNetworkQuery = { __typename?: 'Query', surfaceProjectionsIPGroupByNone?: { __typename?: 'SurfaceProjectionsIPGroupByNoneResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, ips?: Array<{ __typename?: 'SurfaceProjectionsIPGroupByNoneEntity', ipAddress: string, ipVersion: SurfaceProjectionsIpVersion, asName?: string | null, countryName?: string | null }> | null } | null, surfaceProjectionsPortGroupByNone?: { __typename?: 'SurfaceProjectionsPortGroupByNoneResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, ports?: Array<{ __typename?: 'SurfaceProjectionsPortGroupByNoneEntity', ipAddress: string, port: number }> | null } | null };

export type GetOverviewSeverityGraphQueryVariables = Exact<{
  input?: InputMaybe<PrioritizationSeverityGraphInput>;
}>;


export type GetOverviewSeverityGraphQuery = { __typename?: 'Query', severityGraph?: { __typename?: 'PrioritizationSeverityGraph', critical?: number | null, high?: number | null, low?: number | null, medium?: number | null, total?: number | null } | null };

export type GetOverviewMostSevereAssetsQueryVariables = Exact<{
  input?: InputMaybe<PrioritizationMostSevereAssetsInput>;
}>;


export type GetOverviewMostSevereAssetsQuery = { __typename?: 'Query', mostSevereAssets?: Array<{ __typename?: 'PrioritizationSevereAsset', asset?: { __typename?: 'PrioritizationAsset', id?: string | null, name?: string | null } | null, severityGraph?: { __typename?: 'PrioritizationSeverityGraph', critical?: number | null, high?: number | null, low?: number | null, medium?: number | null } | null } | null> | null };

export type GetPoliciesV2QueryVariables = Exact<{
  pagination?: InputMaybe<PolicyTokenPaginationInput>;
}>;


export type GetPoliciesV2Query = { __typename?: 'Query', policyGetPolicies: { __typename?: 'PolicyGetPolicyResult', policies: Array<{ __typename?: 'PolicyDefinition', name: string, id: string, severity?: PolicySeverity | null, description: string, filters: Array<{ __typename?: 'PolicyDomainFiltersDefinition', name: PolicyDomainFiltersName, operator: PolicyFilterOperator, value?: Array<string | null> | null }> }>, pagination?: { __typename?: 'PolicyTokenPagination', hasMore: boolean, nextToken?: string | null } | null } };

export type GetBreachesBySeverityOverTimeQueryVariables = Exact<{
  input: SurfaceProjectionsBreachesBySeverityOverTimeInput;
}>;


export type GetBreachesBySeverityOverTimeQuery = { __typename?: 'Query', surfaceProjectionsBreachesBySeverityOverTime?: { __typename?: 'SurfaceProjectionsBreachesBySeverityOverTimeResponse', entries?: Array<{ __typename?: 'SurfaceProjectionsBreachesBySeverityOverTimeEntry', time: string, appeared: { __typename?: 'SurfaceProjectionsBreachesBySeverity', critical: number, high: number, medium: number, low: number, information: number }, reappeared: { __typename?: 'SurfaceProjectionsBreachesBySeverity', critical: number, high: number, medium: number, low: number, information: number }, resolved: { __typename?: 'SurfaceProjectionsBreachesBySeverity', critical: number, high: number, medium: number, low: number, information: number }, currentTotal: { __typename?: 'SurfaceProjectionsBreachesBySeverity', critical: number, high: number, medium: number, low: number, information: number } }> | null } | null };

export type GetPoliciesQueryVariables = Exact<{
  pagination?: InputMaybe<PolicyTokenPaginationInput>;
}>;


export type GetPoliciesQuery = { __typename?: 'Query', policyGetPolicies: { __typename?: 'PolicyGetPolicyResult', policies: Array<{ __typename?: 'PolicyDefinition', name: string, id: string, severity?: PolicySeverity | null, description: string, filters: Array<{ __typename?: 'PolicyDomainFiltersDefinition', name: PolicyDomainFiltersName, operator: PolicyFilterOperator, value?: Array<string | null> | null }> }>, pagination?: { __typename?: 'PolicyTokenPagination', hasMore: boolean, nextToken?: string | null } | null } };

export type DeletePolicyMutationVariables = Exact<{
  input: PolicyDeletePolicyInput;
}>;


export type DeletePolicyMutation = { __typename?: 'Mutation', policyDeletePolicy: { __typename?: 'PolicyDeletePolicyResult', success: boolean, error?: string | null } };

export type CreatePolicyMutationVariables = Exact<{
  input: PolicyCreatePolicyInput;
}>;


export type CreatePolicyMutation = { __typename?: 'Mutation', policyCreatePolicy: { __typename?: 'PolicyCreatePolicyResult', success: boolean, error?: string | null } };

export type GetPolicyBreachesQueryVariables = Exact<{
  input?: InputMaybe<SurfaceProjectionsBreachGroupByNoneInput>;
}>;


export type GetPolicyBreachesQuery = { __typename?: 'Query', surfaceProjectionsBreachGroupByNone?: { __typename?: 'SurfaceProjectionsBreachGroupByNoneResponse', hasMore: boolean, offset: number, success: boolean, totalCount: number, breaches: Array<{ __typename?: 'SurfaceProjectionsBreachGroupByNoneEntity', assetToken: string, disappeared?: any | null, domainName: string, firstSeen: any, id: string, policyId: string, policyName: string, severity: SurfaceProjectionsSeverities, status: string, statusUpdated: any }> } | null };

export type PortGroupByPortQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionPortFilters> | SurfaceProjectionPortFilters>;
  sortBy?: InputMaybe<SurfaceProjectionPortSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type PortGroupByPortQuery = { __typename?: 'Query', surfaceProjectionsPortGroupByPort?: { __typename?: 'SurfaceProjectionsPortGroupByPortResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, ports?: Array<{ __typename?: 'SurfaceProjectionsPortGroupByPortEntity', uuid: string, port: number, ipAddressCount: number, ipAddressOpenCount: number, domainCount: number, domainOpenCount: number, status: SurfaceProjectionPortStatus, firstScanned: string, disappeared?: string | null }> | null } | null };

export type PortGroupByNoneQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionPortFilters> | SurfaceProjectionPortFilters>;
  sortBy?: InputMaybe<SurfaceProjectionPortSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type PortGroupByNoneQuery = { __typename?: 'Query', surfaceProjectionsPortGroupByNone?: { __typename?: 'SurfaceProjectionsPortGroupByNoneResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, ports?: Array<{ __typename?: 'SurfaceProjectionsPortGroupByNoneEntity', uuid: string, port: number, assetToken: string, ipAddress: string, domainName: string, status: SurfaceProjectionPortStatus, firstScanned: string, disappeared?: string | null }> | null } | null };

export type PortFilterValuesQueryVariables = Exact<{
  filters?: InputMaybe<Array<SurfaceProjectionFilterValuesFilters> | SurfaceProjectionFilterValuesFilters>;
}>;


export type PortFilterValuesQuery = { __typename?: 'Query', surfaceProjectionsPortFilterValues?: { __typename?: 'SurfaceProjectionPortFilterValuesResponse', domainName?: Array<string> | null, ipAddress?: Array<string> | null, port?: Array<string> | null, status: Array<SurfaceProjectionPortStatus | null> } | null };

export type GetCurrentTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentTeamQuery = { __typename?: 'Query', getCurrentTeam: { __typename?: 'Team', teamToken: string, name: string, segment: string } };

export type TechGroupedByNoneQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionTechFilters> | SurfaceProjectionTechFilters>;
  sortBy?: InputMaybe<SurfaceProjectionTechSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type TechGroupedByNoneQuery = { __typename?: 'Query', surfaceProjectionsTechGroupByNone?: { __typename?: 'SurfaceProjectionsTechGroupByNoneResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, techs: Array<{ __typename?: 'SurfaceProjectionsTechGroupByNoneEntity', name?: string | null, active: boolean, assetToken: string, domainName: string, firstSeen: string, techId: string, version?: string | null, serviceProtocol?: string | null, port?: string | null, categories: Array<string>, disappeared?: string | null }> } | null };

export type TechGroupedByNameVersionQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionTechFilters> | SurfaceProjectionTechFilters>;
  sortBy?: InputMaybe<SurfaceProjectionTechSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type TechGroupedByNameVersionQuery = { __typename?: 'Query', surfaceProjectionsTechGroupByNameVersion?: { __typename?: 'SurfaceProjectionsTechGroupByNameVersionResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, techs: Array<{ __typename?: 'SurfaceProjectionsTechGroupByNameVersionEntity', name: string, active: boolean, firstSeen: string, domainCount: number, version?: string | null, categories: Array<string>, disappeared?: string | null }> } | null };

export type TechGroupedByNameQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Array<SurfaceProjectionTechFilters> | SurfaceProjectionTechFilters>;
  sortBy?: InputMaybe<SurfaceProjectionTechSortBy>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type TechGroupedByNameQuery = { __typename?: 'Query', surfaceProjectionsTechGroupByName?: { __typename?: 'SurfaceProjectionsTechGroupByNameResponse', success: boolean, hasMore: boolean, offset: number, totalCount: number, techs: Array<{ __typename?: 'SurfaceProjectionsTechGroupByNameEntity', name: string, active: boolean, firstSeen: string, domainCount: number, versionCount: number, categories: Array<string>, disappeared?: string | null }> } | null };

export type TechFilterValuesQueryVariables = Exact<{
  filters?: InputMaybe<Array<SurfaceProjectionFilterValuesFilters> | SurfaceProjectionFilterValuesFilters>;
}>;


export type TechFilterValuesQuery = { __typename?: 'Query', surfaceProjectionsTechFilterValues?: { __typename?: 'SurfaceProjectionTechFilterValuesResponse', name: Array<string>, domainName: Array<string>, port: Array<string>, serviceProtocol: Array<string>, version: Array<string> } | null };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', token: string, firstName: string, lastName: string, email: string, role: string, lastLogin?: any | null }> };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', token: string, role: string, teams: Array<{ __typename?: 'TeamInfo', name: string, token: string, isActive: boolean, memberToken: string, canChange: boolean }> } };

export type GetVulnerabilitiesByDomainQueryVariables = Exact<{
  pagination: VulnerabilitiesPaginationInput;
  sorting?: InputMaybe<VulnerabilitiesSortingInput>;
  filter?: InputMaybe<VulnerabilitiesFilterInput>;
}>;


export type GetVulnerabilitiesByDomainQuery = { __typename?: 'Query', vulnerabilities: { __typename?: 'VulnerabilitiesResponse', total: number, nodes: Array<{ __typename?: 'Vulnerability', uuid: string, finding_title: string, last_seen: string, location?: string | null, severity?: string | null, status: VulnerabilityStatus, hostname?: string | null, finding_score?: Array<{ __typename?: 'Score', score?: number | null, severity?: string | null, version?: string | null }> | null }> } };

export type GetVulnerabilitiesByDomainFilterValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVulnerabilitiesByDomainFilterValuesQuery = { __typename?: 'Query', filterValues: { __typename?: 'FilterValues', title: Array<string> } };

export const BaseAssetFragmentDoc = gql`
    fragment BaseAsset on Asset {
  name
  type
  token
  status
  createdAt
}
    `;
export const OriginCategoriesOnAssetFragmentDoc = gql`
    fragment OriginCategoriesOnAsset on Asset {
  originCategories {
    name
    type
  }
}
    `;
export const CreateApplicationScanProfileWithScheduleDocument = gql`
    mutation CreateApplicationScanProfileWithSchedule($input: CreateApplicationScanProfileWithScheduleRequest!) {
  createApplicationScanProfileWithSchedule(input: $input) {
    success
    scanProfileToken
    scanProfileName
    scanProfileEndpoint
    nextScan
    error
  }
}
    `;
export type CreateApplicationScanProfileWithScheduleMutationFn = Apollo.MutationFunction<CreateApplicationScanProfileWithScheduleMutation, CreateApplicationScanProfileWithScheduleMutationVariables>;

/**
 * __useCreateApplicationScanProfileWithScheduleMutation__
 *
 * To run a mutation, you first call `useCreateApplicationScanProfileWithScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationScanProfileWithScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationScanProfileWithScheduleMutation, { data, loading, error }] = useCreateApplicationScanProfileWithScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationScanProfileWithScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateApplicationScanProfileWithScheduleMutation, CreateApplicationScanProfileWithScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApplicationScanProfileWithScheduleMutation, CreateApplicationScanProfileWithScheduleMutationVariables>(CreateApplicationScanProfileWithScheduleDocument, options);
      }
export type CreateApplicationScanProfileWithScheduleMutationHookResult = ReturnType<typeof useCreateApplicationScanProfileWithScheduleMutation>;
export type CreateApplicationScanProfileWithScheduleMutationResult = Apollo.MutationResult<CreateApplicationScanProfileWithScheduleMutation>;
export type CreateApplicationScanProfileWithScheduleMutationOptions = Apollo.BaseMutationOptions<CreateApplicationScanProfileWithScheduleMutation, CreateApplicationScanProfileWithScheduleMutationVariables>;
export const GetScanProfilesForTeamDocument = gql`
    query GetScanProfilesForTeam($pagination: LimitOffsetPagination, $filters: ScanProfileFilters) {
  getScanProfilesForTeam(pagination: $pagination, filters: $filters) {
    scanProfiles {
      assetToken
      token
      endpoint
      name
      lastScanStatus
      lastScanEndedTime
      lastScanResult
      lastScanErrors
      lastScanFatal
      nextScanTime
      schedule
      reportToken
      isBillingActive
    }
    totalCount
  }
}
    `;

/**
 * __useGetScanProfilesForTeamQuery__
 *
 * To run a query within a React component, call `useGetScanProfilesForTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanProfilesForTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanProfilesForTeamQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetScanProfilesForTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetScanProfilesForTeamQuery, GetScanProfilesForTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScanProfilesForTeamQuery, GetScanProfilesForTeamQueryVariables>(GetScanProfilesForTeamDocument, options);
      }
export function useGetScanProfilesForTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScanProfilesForTeamQuery, GetScanProfilesForTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScanProfilesForTeamQuery, GetScanProfilesForTeamQueryVariables>(GetScanProfilesForTeamDocument, options);
        }
export function useGetScanProfilesForTeamSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetScanProfilesForTeamQuery, GetScanProfilesForTeamQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScanProfilesForTeamQuery, GetScanProfilesForTeamQueryVariables>(GetScanProfilesForTeamDocument, options);
        }
export type GetScanProfilesForTeamQueryHookResult = ReturnType<typeof useGetScanProfilesForTeamQuery>;
export type GetScanProfilesForTeamLazyQueryHookResult = ReturnType<typeof useGetScanProfilesForTeamLazyQuery>;
export type GetScanProfilesForTeamSuspenseQueryHookResult = ReturnType<typeof useGetScanProfilesForTeamSuspenseQuery>;
export type GetScanProfilesForTeamQueryResult = Apollo.QueryResult<GetScanProfilesForTeamQuery, GetScanProfilesForTeamQueryVariables>;
export const TeamSurfaceMonitoringBalanceDocument = gql`
    query TeamSurfaceMonitoringBalance {
  assetMonitoringBalance {
    capacity
    currentUsage
  }
}
    `;

/**
 * __useTeamSurfaceMonitoringBalanceQuery__
 *
 * To run a query within a React component, call `useTeamSurfaceMonitoringBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamSurfaceMonitoringBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamSurfaceMonitoringBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamSurfaceMonitoringBalanceQuery(baseOptions?: Apollo.QueryHookOptions<TeamSurfaceMonitoringBalanceQuery, TeamSurfaceMonitoringBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamSurfaceMonitoringBalanceQuery, TeamSurfaceMonitoringBalanceQueryVariables>(TeamSurfaceMonitoringBalanceDocument, options);
      }
export function useTeamSurfaceMonitoringBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamSurfaceMonitoringBalanceQuery, TeamSurfaceMonitoringBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamSurfaceMonitoringBalanceQuery, TeamSurfaceMonitoringBalanceQueryVariables>(TeamSurfaceMonitoringBalanceDocument, options);
        }
export function useTeamSurfaceMonitoringBalanceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamSurfaceMonitoringBalanceQuery, TeamSurfaceMonitoringBalanceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamSurfaceMonitoringBalanceQuery, TeamSurfaceMonitoringBalanceQueryVariables>(TeamSurfaceMonitoringBalanceDocument, options);
        }
export type TeamSurfaceMonitoringBalanceQueryHookResult = ReturnType<typeof useTeamSurfaceMonitoringBalanceQuery>;
export type TeamSurfaceMonitoringBalanceLazyQueryHookResult = ReturnType<typeof useTeamSurfaceMonitoringBalanceLazyQuery>;
export type TeamSurfaceMonitoringBalanceSuspenseQueryHookResult = ReturnType<typeof useTeamSurfaceMonitoringBalanceSuspenseQuery>;
export type TeamSurfaceMonitoringBalanceQueryResult = Apollo.QueryResult<TeamSurfaceMonitoringBalanceQuery, TeamSurfaceMonitoringBalanceQueryVariables>;
export const GetAssetDetailsDocument = gql`
    query GetAssetDetails($assetToken: String!) {
  getAsset(assetToken: $assetToken) {
    ...BaseAsset
    ...OriginCategoriesOnAsset
    createdAt
    lastSeen
    discovered
    isRoot
    status
    rootAsset {
      token
      name
    }
    vulnerabilitySeverityGraph {
      domain
      timeFrame
      severityGraph {
        high
        critical
        low
        medium
        total
      }
    }
  }
}
    ${BaseAssetFragmentDoc}
${OriginCategoriesOnAssetFragmentDoc}`;

/**
 * __useGetAssetDetailsQuery__
 *
 * To run a query within a React component, call `useGetAssetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDetailsQuery({
 *   variables: {
 *      assetToken: // value for 'assetToken'
 *   },
 * });
 */
export function useGetAssetDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAssetDetailsQuery, GetAssetDetailsQueryVariables> & ({ variables: GetAssetDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetDetailsQuery, GetAssetDetailsQueryVariables>(GetAssetDetailsDocument, options);
      }
export function useGetAssetDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetDetailsQuery, GetAssetDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetDetailsQuery, GetAssetDetailsQueryVariables>(GetAssetDetailsDocument, options);
        }
export function useGetAssetDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssetDetailsQuery, GetAssetDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssetDetailsQuery, GetAssetDetailsQueryVariables>(GetAssetDetailsDocument, options);
        }
export type GetAssetDetailsQueryHookResult = ReturnType<typeof useGetAssetDetailsQuery>;
export type GetAssetDetailsLazyQueryHookResult = ReturnType<typeof useGetAssetDetailsLazyQuery>;
export type GetAssetDetailsSuspenseQueryHookResult = ReturnType<typeof useGetAssetDetailsSuspenseQuery>;
export type GetAssetDetailsQueryResult = Apollo.QueryResult<GetAssetDetailsQuery, GetAssetDetailsQueryVariables>;
export const GetBillingInternalForTeamDocument = gql`
    query GetBillingInternalForTeam {
  billingInternal {
    customer {
      canScaleAboveContract
      maxScanProfilesOnTrial
      paymentMethod
      planType
      subscription {
        token
        planType
        status
        scanProfileCapacity
        surfaceMonitoringCapacity
      }
      token
    }
  }
}
    `;

/**
 * __useGetBillingInternalForTeamQuery__
 *
 * To run a query within a React component, call `useGetBillingInternalForTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingInternalForTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingInternalForTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingInternalForTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetBillingInternalForTeamQuery, GetBillingInternalForTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingInternalForTeamQuery, GetBillingInternalForTeamQueryVariables>(GetBillingInternalForTeamDocument, options);
      }
export function useGetBillingInternalForTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingInternalForTeamQuery, GetBillingInternalForTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingInternalForTeamQuery, GetBillingInternalForTeamQueryVariables>(GetBillingInternalForTeamDocument, options);
        }
export function useGetBillingInternalForTeamSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBillingInternalForTeamQuery, GetBillingInternalForTeamQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBillingInternalForTeamQuery, GetBillingInternalForTeamQueryVariables>(GetBillingInternalForTeamDocument, options);
        }
export type GetBillingInternalForTeamQueryHookResult = ReturnType<typeof useGetBillingInternalForTeamQuery>;
export type GetBillingInternalForTeamLazyQueryHookResult = ReturnType<typeof useGetBillingInternalForTeamLazyQuery>;
export type GetBillingInternalForTeamSuspenseQueryHookResult = ReturnType<typeof useGetBillingInternalForTeamSuspenseQuery>;
export type GetBillingInternalForTeamQueryResult = Apollo.QueryResult<GetBillingInternalForTeamQuery, GetBillingInternalForTeamQueryVariables>;
export const DnsQueryGroupByNoneDocument = gql`
    query DnsQueryGroupByNone($input: SurfaceProjectionsDNSQueryGroupByNoneInput) {
  surfaceProjectionsDNSQueryGroupByNone(input: $input) {
    success
    dnsQueries {
      id
      scope
      domainName
      assetToken
      firstReceived
      lastChanged
      lastServer
      lastResponse
      lastResponseCode
      lastProtocol
      answerRecords
      answerRecordTypes
      firstRecords
      recordsDisappeared
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useDnsQueryGroupByNoneQuery__
 *
 * To run a query within a React component, call `useDnsQueryGroupByNoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDnsQueryGroupByNoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDnsQueryGroupByNoneQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDnsQueryGroupByNoneQuery(baseOptions?: Apollo.QueryHookOptions<DnsQueryGroupByNoneQuery, DnsQueryGroupByNoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DnsQueryGroupByNoneQuery, DnsQueryGroupByNoneQueryVariables>(DnsQueryGroupByNoneDocument, options);
      }
export function useDnsQueryGroupByNoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DnsQueryGroupByNoneQuery, DnsQueryGroupByNoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DnsQueryGroupByNoneQuery, DnsQueryGroupByNoneQueryVariables>(DnsQueryGroupByNoneDocument, options);
        }
export function useDnsQueryGroupByNoneSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DnsQueryGroupByNoneQuery, DnsQueryGroupByNoneQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DnsQueryGroupByNoneQuery, DnsQueryGroupByNoneQueryVariables>(DnsQueryGroupByNoneDocument, options);
        }
export type DnsQueryGroupByNoneQueryHookResult = ReturnType<typeof useDnsQueryGroupByNoneQuery>;
export type DnsQueryGroupByNoneLazyQueryHookResult = ReturnType<typeof useDnsQueryGroupByNoneLazyQuery>;
export type DnsQueryGroupByNoneSuspenseQueryHookResult = ReturnType<typeof useDnsQueryGroupByNoneSuspenseQuery>;
export type DnsQueryGroupByNoneQueryResult = Apollo.QueryResult<DnsQueryGroupByNoneQuery, DnsQueryGroupByNoneQueryVariables>;
export const DnsQueryFilterValuesDocument = gql`
    query DnsQueryFilterValues($filters: [SurfaceProjectionFilterValuesFilters!]) {
  surfaceProjectionsDNSQueryFilterValues(filters: $filters) {
    domainName
    questionRecordType
    responseCode
    answerRecordType
  }
}
    `;

/**
 * __useDnsQueryFilterValuesQuery__
 *
 * To run a query within a React component, call `useDnsQueryFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDnsQueryFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDnsQueryFilterValuesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDnsQueryFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<DnsQueryFilterValuesQuery, DnsQueryFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DnsQueryFilterValuesQuery, DnsQueryFilterValuesQueryVariables>(DnsQueryFilterValuesDocument, options);
      }
export function useDnsQueryFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DnsQueryFilterValuesQuery, DnsQueryFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DnsQueryFilterValuesQuery, DnsQueryFilterValuesQueryVariables>(DnsQueryFilterValuesDocument, options);
        }
export function useDnsQueryFilterValuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DnsQueryFilterValuesQuery, DnsQueryFilterValuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DnsQueryFilterValuesQuery, DnsQueryFilterValuesQueryVariables>(DnsQueryFilterValuesDocument, options);
        }
export type DnsQueryFilterValuesQueryHookResult = ReturnType<typeof useDnsQueryFilterValuesQuery>;
export type DnsQueryFilterValuesLazyQueryHookResult = ReturnType<typeof useDnsQueryFilterValuesLazyQuery>;
export type DnsQueryFilterValuesSuspenseQueryHookResult = ReturnType<typeof useDnsQueryFilterValuesSuspenseQuery>;
export type DnsQueryFilterValuesQueryResult = Apollo.QueryResult<DnsQueryFilterValuesQuery, DnsQueryFilterValuesQueryVariables>;
export const DnsQueryGroupByNoneQuickFilterDocument = gql`
    query DnsQueryGroupByNoneQuickFilter($filters: [SurfaceProjectionsDNSQueryFilters!]) {
  surfaceProjectionsDNSQueryGroupByNone(
    input: {filters: $filters, offset: 0, limit: 100}
  ) {
    success
    totalCount
  }
}
    `;

/**
 * __useDnsQueryGroupByNoneQuickFilterQuery__
 *
 * To run a query within a React component, call `useDnsQueryGroupByNoneQuickFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDnsQueryGroupByNoneQuickFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDnsQueryGroupByNoneQuickFilterQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDnsQueryGroupByNoneQuickFilterQuery(baseOptions?: Apollo.QueryHookOptions<DnsQueryGroupByNoneQuickFilterQuery, DnsQueryGroupByNoneQuickFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DnsQueryGroupByNoneQuickFilterQuery, DnsQueryGroupByNoneQuickFilterQueryVariables>(DnsQueryGroupByNoneQuickFilterDocument, options);
      }
export function useDnsQueryGroupByNoneQuickFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DnsQueryGroupByNoneQuickFilterQuery, DnsQueryGroupByNoneQuickFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DnsQueryGroupByNoneQuickFilterQuery, DnsQueryGroupByNoneQuickFilterQueryVariables>(DnsQueryGroupByNoneQuickFilterDocument, options);
        }
export function useDnsQueryGroupByNoneQuickFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DnsQueryGroupByNoneQuickFilterQuery, DnsQueryGroupByNoneQuickFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DnsQueryGroupByNoneQuickFilterQuery, DnsQueryGroupByNoneQuickFilterQueryVariables>(DnsQueryGroupByNoneQuickFilterDocument, options);
        }
export type DnsQueryGroupByNoneQuickFilterQueryHookResult = ReturnType<typeof useDnsQueryGroupByNoneQuickFilterQuery>;
export type DnsQueryGroupByNoneQuickFilterLazyQueryHookResult = ReturnType<typeof useDnsQueryGroupByNoneQuickFilterLazyQuery>;
export type DnsQueryGroupByNoneQuickFilterSuspenseQueryHookResult = ReturnType<typeof useDnsQueryGroupByNoneQuickFilterSuspenseQuery>;
export type DnsQueryGroupByNoneQuickFilterQueryResult = Apollo.QueryResult<DnsQueryGroupByNoneQuickFilterQuery, DnsQueryGroupByNoneQuickFilterQueryVariables>;
export const DomainsDocument = gql`
    query Domains($offset: Int!, $limit: Int, $filters: [SurfaceProjectionDomainFilters!], $sortBy: SurfaceProjectionDomainSortBy, $search: String) {
  surfaceProjectionsDomainGroupByDomain(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
  ) {
    success
    domains {
      domainName
      assetToken
      surfaceState
      activeTechCount
      activeTechs
      openPortCount
      openPorts
      activeAsNameCount
      activeAsNames
      activeIpAddressCount
      activeCountryCount
      firstSeen
      disappeared
      activeCountries
      dnsRecordTypes
      dnsRecordTypesCount
      dnsResponseCodes
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useDomainsQuery__
 *
 * To run a query within a React component, call `useDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDomainsQuery(baseOptions: Apollo.QueryHookOptions<DomainsQuery, DomainsQueryVariables> & ({ variables: DomainsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
      }
export function useDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DomainsQuery, DomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
        }
export function useDomainsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DomainsQuery, DomainsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
        }
export type DomainsQueryHookResult = ReturnType<typeof useDomainsQuery>;
export type DomainsLazyQueryHookResult = ReturnType<typeof useDomainsLazyQuery>;
export type DomainsSuspenseQueryHookResult = ReturnType<typeof useDomainsSuspenseQuery>;
export type DomainsQueryResult = Apollo.QueryResult<DomainsQuery, DomainsQueryVariables>;
export const SurfaceProjectionsDomainDetailsDocument = gql`
    query SurfaceProjectionsDomainDetails($assetToken: String) {
  surfaceProjectionsDomainDetails(assetToken: $assetToken) {
    details {
      firstSeen
      disappeared
      domainName
      assetToken
      surfaceState
    }
    success
  }
}
    `;

/**
 * __useSurfaceProjectionsDomainDetailsQuery__
 *
 * To run a query within a React component, call `useSurfaceProjectionsDomainDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurfaceProjectionsDomainDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurfaceProjectionsDomainDetailsQuery({
 *   variables: {
 *      assetToken: // value for 'assetToken'
 *   },
 * });
 */
export function useSurfaceProjectionsDomainDetailsQuery(baseOptions?: Apollo.QueryHookOptions<SurfaceProjectionsDomainDetailsQuery, SurfaceProjectionsDomainDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurfaceProjectionsDomainDetailsQuery, SurfaceProjectionsDomainDetailsQueryVariables>(SurfaceProjectionsDomainDetailsDocument, options);
      }
export function useSurfaceProjectionsDomainDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurfaceProjectionsDomainDetailsQuery, SurfaceProjectionsDomainDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurfaceProjectionsDomainDetailsQuery, SurfaceProjectionsDomainDetailsQueryVariables>(SurfaceProjectionsDomainDetailsDocument, options);
        }
export function useSurfaceProjectionsDomainDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SurfaceProjectionsDomainDetailsQuery, SurfaceProjectionsDomainDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurfaceProjectionsDomainDetailsQuery, SurfaceProjectionsDomainDetailsQueryVariables>(SurfaceProjectionsDomainDetailsDocument, options);
        }
export type SurfaceProjectionsDomainDetailsQueryHookResult = ReturnType<typeof useSurfaceProjectionsDomainDetailsQuery>;
export type SurfaceProjectionsDomainDetailsLazyQueryHookResult = ReturnType<typeof useSurfaceProjectionsDomainDetailsLazyQuery>;
export type SurfaceProjectionsDomainDetailsSuspenseQueryHookResult = ReturnType<typeof useSurfaceProjectionsDomainDetailsSuspenseQuery>;
export type SurfaceProjectionsDomainDetailsQueryResult = Apollo.QueryResult<SurfaceProjectionsDomainDetailsQuery, SurfaceProjectionsDomainDetailsQueryVariables>;
export const AttackSurfaceDomainsPolicyDocument = gql`
    query AttackSurfaceDomainsPolicy($filters: [SurfaceProjectionDomainFilters!]) {
  surfaceProjectionsDomainGroupByDomain(offset: 0, limit: 0, filters: $filters) {
    success
    totalCount
  }
}
    `;

/**
 * __useAttackSurfaceDomainsPolicyQuery__
 *
 * To run a query within a React component, call `useAttackSurfaceDomainsPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttackSurfaceDomainsPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttackSurfaceDomainsPolicyQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAttackSurfaceDomainsPolicyQuery(baseOptions?: Apollo.QueryHookOptions<AttackSurfaceDomainsPolicyQuery, AttackSurfaceDomainsPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttackSurfaceDomainsPolicyQuery, AttackSurfaceDomainsPolicyQueryVariables>(AttackSurfaceDomainsPolicyDocument, options);
      }
export function useAttackSurfaceDomainsPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttackSurfaceDomainsPolicyQuery, AttackSurfaceDomainsPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttackSurfaceDomainsPolicyQuery, AttackSurfaceDomainsPolicyQueryVariables>(AttackSurfaceDomainsPolicyDocument, options);
        }
export function useAttackSurfaceDomainsPolicySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AttackSurfaceDomainsPolicyQuery, AttackSurfaceDomainsPolicyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AttackSurfaceDomainsPolicyQuery, AttackSurfaceDomainsPolicyQueryVariables>(AttackSurfaceDomainsPolicyDocument, options);
        }
export type AttackSurfaceDomainsPolicyQueryHookResult = ReturnType<typeof useAttackSurfaceDomainsPolicyQuery>;
export type AttackSurfaceDomainsPolicyLazyQueryHookResult = ReturnType<typeof useAttackSurfaceDomainsPolicyLazyQuery>;
export type AttackSurfaceDomainsPolicySuspenseQueryHookResult = ReturnType<typeof useAttackSurfaceDomainsPolicySuspenseQuery>;
export type AttackSurfaceDomainsPolicyQueryResult = Apollo.QueryResult<AttackSurfaceDomainsPolicyQuery, AttackSurfaceDomainsPolicyQueryVariables>;
export const DomainFilterValuesDocument = gql`
    query DomainFilterValues {
  surfaceProjectionsDomainFilterValues {
    port
    techs
    asNames
    activeCountries
    dnsRecordTypes
    dnsResponseCodes
  }
}
    `;

/**
 * __useDomainFilterValuesQuery__
 *
 * To run a query within a React component, call `useDomainFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainFilterValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomainFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<DomainFilterValuesQuery, DomainFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DomainFilterValuesQuery, DomainFilterValuesQueryVariables>(DomainFilterValuesDocument, options);
      }
export function useDomainFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DomainFilterValuesQuery, DomainFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DomainFilterValuesQuery, DomainFilterValuesQueryVariables>(DomainFilterValuesDocument, options);
        }
export function useDomainFilterValuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DomainFilterValuesQuery, DomainFilterValuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DomainFilterValuesQuery, DomainFilterValuesQueryVariables>(DomainFilterValuesDocument, options);
        }
export type DomainFilterValuesQueryHookResult = ReturnType<typeof useDomainFilterValuesQuery>;
export type DomainFilterValuesLazyQueryHookResult = ReturnType<typeof useDomainFilterValuesLazyQuery>;
export type DomainFilterValuesSuspenseQueryHookResult = ReturnType<typeof useDomainFilterValuesSuspenseQuery>;
export type DomainFilterValuesQueryResult = Apollo.QueryResult<DomainFilterValuesQuery, DomainFilterValuesQueryVariables>;
export const DomainsQuickFilterDocument = gql`
    query DomainsQuickFilter($filters: [SurfaceProjectionDomainFilters!]) {
  surfaceProjectionsDomainGroupByDomain(offset: 0, limit: 0, filters: $filters) {
    success
    totalCount
  }
}
    `;

/**
 * __useDomainsQuickFilterQuery__
 *
 * To run a query within a React component, call `useDomainsQuickFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsQuickFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsQuickFilterQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDomainsQuickFilterQuery(baseOptions?: Apollo.QueryHookOptions<DomainsQuickFilterQuery, DomainsQuickFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DomainsQuickFilterQuery, DomainsQuickFilterQueryVariables>(DomainsQuickFilterDocument, options);
      }
export function useDomainsQuickFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DomainsQuickFilterQuery, DomainsQuickFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DomainsQuickFilterQuery, DomainsQuickFilterQueryVariables>(DomainsQuickFilterDocument, options);
        }
export function useDomainsQuickFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DomainsQuickFilterQuery, DomainsQuickFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DomainsQuickFilterQuery, DomainsQuickFilterQueryVariables>(DomainsQuickFilterDocument, options);
        }
export type DomainsQuickFilterQueryHookResult = ReturnType<typeof useDomainsQuickFilterQuery>;
export type DomainsQuickFilterLazyQueryHookResult = ReturnType<typeof useDomainsQuickFilterLazyQuery>;
export type DomainsQuickFilterSuspenseQueryHookResult = ReturnType<typeof useDomainsQuickFilterSuspenseQuery>;
export type DomainsQuickFilterQueryResult = Apollo.QueryResult<DomainsQuickFilterQuery, DomainsQuickFilterQueryVariables>;
export const IPsGroupedByIpDocument = gql`
    query IPsGroupedByIP($offset: Int!, $limit: Int, $filters: [SurfaceProjectionFilters!], $sortBy: SurfaceProjectionSortBy, $search: String, $newFlow: Boolean) {
  surfaceProjectionsIPGroupByIP(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
    newFlow: $newFlow
  ) {
    success
    ips {
      active
      ipAddress
      domainCount
      firstSeen
      disappeared
      enriched
      countryName
      asn
      asDomain
      asName
      continentName
      ipVersion
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useIPsGroupedByIpQuery__
 *
 * To run a query within a React component, call `useIPsGroupedByIpQuery` and pass it any options that fit your needs.
 * When your component renders, `useIPsGroupedByIpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIPsGroupedByIpQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *      newFlow: // value for 'newFlow'
 *   },
 * });
 */
export function useIPsGroupedByIpQuery(baseOptions: Apollo.QueryHookOptions<IPsGroupedByIpQuery, IPsGroupedByIpQueryVariables> & ({ variables: IPsGroupedByIpQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPsGroupedByIpQuery, IPsGroupedByIpQueryVariables>(IPsGroupedByIpDocument, options);
      }
export function useIPsGroupedByIpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPsGroupedByIpQuery, IPsGroupedByIpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPsGroupedByIpQuery, IPsGroupedByIpQueryVariables>(IPsGroupedByIpDocument, options);
        }
export function useIPsGroupedByIpSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IPsGroupedByIpQuery, IPsGroupedByIpQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IPsGroupedByIpQuery, IPsGroupedByIpQueryVariables>(IPsGroupedByIpDocument, options);
        }
export type IPsGroupedByIpQueryHookResult = ReturnType<typeof useIPsGroupedByIpQuery>;
export type IPsGroupedByIpLazyQueryHookResult = ReturnType<typeof useIPsGroupedByIpLazyQuery>;
export type IPsGroupedByIpSuspenseQueryHookResult = ReturnType<typeof useIPsGroupedByIpSuspenseQuery>;
export type IPsGroupedByIpQueryResult = Apollo.QueryResult<IPsGroupedByIpQuery, IPsGroupedByIpQueryVariables>;
export const IPsGroupedByAsNameDocument = gql`
    query IPsGroupedByAsName($offset: Int!, $limit: Int, $filters: [SurfaceProjectionFilters!], $sortBy: SurfaceProjectionSortBy, $search: String, $newFlow: Boolean) {
  surfaceProjectionsIPGroupByAsName(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
    newFlow: $newFlow
  ) {
    success
    ips {
      asName
      asnCount
      ipAddressCount
      countryNameCount
      domainCount
      active
      firstSeen
      disappeared
      asDomain
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useIPsGroupedByAsNameQuery__
 *
 * To run a query within a React component, call `useIPsGroupedByAsNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useIPsGroupedByAsNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIPsGroupedByAsNameQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *      newFlow: // value for 'newFlow'
 *   },
 * });
 */
export function useIPsGroupedByAsNameQuery(baseOptions: Apollo.QueryHookOptions<IPsGroupedByAsNameQuery, IPsGroupedByAsNameQueryVariables> & ({ variables: IPsGroupedByAsNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPsGroupedByAsNameQuery, IPsGroupedByAsNameQueryVariables>(IPsGroupedByAsNameDocument, options);
      }
export function useIPsGroupedByAsNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPsGroupedByAsNameQuery, IPsGroupedByAsNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPsGroupedByAsNameQuery, IPsGroupedByAsNameQueryVariables>(IPsGroupedByAsNameDocument, options);
        }
export function useIPsGroupedByAsNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IPsGroupedByAsNameQuery, IPsGroupedByAsNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IPsGroupedByAsNameQuery, IPsGroupedByAsNameQueryVariables>(IPsGroupedByAsNameDocument, options);
        }
export type IPsGroupedByAsNameQueryHookResult = ReturnType<typeof useIPsGroupedByAsNameQuery>;
export type IPsGroupedByAsNameLazyQueryHookResult = ReturnType<typeof useIPsGroupedByAsNameLazyQuery>;
export type IPsGroupedByAsNameSuspenseQueryHookResult = ReturnType<typeof useIPsGroupedByAsNameSuspenseQuery>;
export type IPsGroupedByAsNameQueryResult = Apollo.QueryResult<IPsGroupedByAsNameQuery, IPsGroupedByAsNameQueryVariables>;
export const IPsGroupedByCountryDocument = gql`
    query IPsGroupedByCountry($offset: Int!, $limit: Int, $filters: [SurfaceProjectionFilters!], $sortBy: SurfaceProjectionSortBy, $search: String, $newFlow: Boolean) {
  surfaceProjectionsIPGroupByCountry(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
    newFlow: $newFlow
  ) {
    success
    ips {
      active
      asNameCount
      asnCount
      continentName
      countryName
      disappeared
      domainCount
      firstSeen
      ipAddressCount
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useIPsGroupedByCountryQuery__
 *
 * To run a query within a React component, call `useIPsGroupedByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIPsGroupedByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIPsGroupedByCountryQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *      newFlow: // value for 'newFlow'
 *   },
 * });
 */
export function useIPsGroupedByCountryQuery(baseOptions: Apollo.QueryHookOptions<IPsGroupedByCountryQuery, IPsGroupedByCountryQueryVariables> & ({ variables: IPsGroupedByCountryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPsGroupedByCountryQuery, IPsGroupedByCountryQueryVariables>(IPsGroupedByCountryDocument, options);
      }
export function useIPsGroupedByCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPsGroupedByCountryQuery, IPsGroupedByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPsGroupedByCountryQuery, IPsGroupedByCountryQueryVariables>(IPsGroupedByCountryDocument, options);
        }
export function useIPsGroupedByCountrySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IPsGroupedByCountryQuery, IPsGroupedByCountryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IPsGroupedByCountryQuery, IPsGroupedByCountryQueryVariables>(IPsGroupedByCountryDocument, options);
        }
export type IPsGroupedByCountryQueryHookResult = ReturnType<typeof useIPsGroupedByCountryQuery>;
export type IPsGroupedByCountryLazyQueryHookResult = ReturnType<typeof useIPsGroupedByCountryLazyQuery>;
export type IPsGroupedByCountrySuspenseQueryHookResult = ReturnType<typeof useIPsGroupedByCountrySuspenseQuery>;
export type IPsGroupedByCountryQueryResult = Apollo.QueryResult<IPsGroupedByCountryQuery, IPsGroupedByCountryQueryVariables>;
export const IPsGroupedByNoneDocument = gql`
    query IPsGroupedByNone($offset: Int!, $limit: Int, $filters: [SurfaceProjectionFilters!], $sortBy: SurfaceProjectionSortBy, $search: String, $newFlow: Boolean) {
  surfaceProjectionsIPGroupByNone(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
    newFlow: $newFlow
  ) {
    success
    ips {
      active
      ipAddress
      domainName
      firstSeen
      disappeared
      enriched
      countryName
      asn
      asDomain
      asName
      continentName
      ipVersion
      assetToken
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useIPsGroupedByNoneQuery__
 *
 * To run a query within a React component, call `useIPsGroupedByNoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useIPsGroupedByNoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIPsGroupedByNoneQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *      newFlow: // value for 'newFlow'
 *   },
 * });
 */
export function useIPsGroupedByNoneQuery(baseOptions: Apollo.QueryHookOptions<IPsGroupedByNoneQuery, IPsGroupedByNoneQueryVariables> & ({ variables: IPsGroupedByNoneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPsGroupedByNoneQuery, IPsGroupedByNoneQueryVariables>(IPsGroupedByNoneDocument, options);
      }
export function useIPsGroupedByNoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPsGroupedByNoneQuery, IPsGroupedByNoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPsGroupedByNoneQuery, IPsGroupedByNoneQueryVariables>(IPsGroupedByNoneDocument, options);
        }
export function useIPsGroupedByNoneSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IPsGroupedByNoneQuery, IPsGroupedByNoneQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IPsGroupedByNoneQuery, IPsGroupedByNoneQueryVariables>(IPsGroupedByNoneDocument, options);
        }
export type IPsGroupedByNoneQueryHookResult = ReturnType<typeof useIPsGroupedByNoneQuery>;
export type IPsGroupedByNoneLazyQueryHookResult = ReturnType<typeof useIPsGroupedByNoneLazyQuery>;
export type IPsGroupedByNoneSuspenseQueryHookResult = ReturnType<typeof useIPsGroupedByNoneSuspenseQuery>;
export type IPsGroupedByNoneQueryResult = Apollo.QueryResult<IPsGroupedByNoneQuery, IPsGroupedByNoneQueryVariables>;
export const IpFilterValuesDocument = gql`
    query IPFilterValues($filters: [SurfaceProjectionFilterValuesFilters!], $newFlow: Boolean) {
  surfaceProjectionsIPFilterValues(filters: $filters, newFlow: $newFlow) {
    ipVersion
    asDomain
    asn
    asName
    domainName
    countryName
    ipAddress
    continentName
  }
}
    `;

/**
 * __useIpFilterValuesQuery__
 *
 * To run a query within a React component, call `useIpFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIpFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIpFilterValuesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      newFlow: // value for 'newFlow'
 *   },
 * });
 */
export function useIpFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<IpFilterValuesQuery, IpFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IpFilterValuesQuery, IpFilterValuesQueryVariables>(IpFilterValuesDocument, options);
      }
export function useIpFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IpFilterValuesQuery, IpFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IpFilterValuesQuery, IpFilterValuesQueryVariables>(IpFilterValuesDocument, options);
        }
export function useIpFilterValuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IpFilterValuesQuery, IpFilterValuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IpFilterValuesQuery, IpFilterValuesQueryVariables>(IpFilterValuesDocument, options);
        }
export type IpFilterValuesQueryHookResult = ReturnType<typeof useIpFilterValuesQuery>;
export type IpFilterValuesLazyQueryHookResult = ReturnType<typeof useIpFilterValuesLazyQuery>;
export type IpFilterValuesSuspenseQueryHookResult = ReturnType<typeof useIpFilterValuesSuspenseQuery>;
export type IpFilterValuesQueryResult = Apollo.QueryResult<IpFilterValuesQuery, IpFilterValuesQueryVariables>;
export const OverviewAssetNetworkDocument = gql`
    query OverviewAssetNetwork($asset: String!) {
  surfaceProjectionsIPGroupByNone(
    offset: 0
    limit: 50000
    filters: [{name: Active, operator: ContainsAnyOf, value: ["true"]}, {name: DomainName, operator: ContainsAnyOf, value: [$asset]}]
  ) {
    success
    ips {
      ipAddress
      ipVersion
      asName
      countryName
    }
    hasMore
    offset
    totalCount
  }
  surfaceProjectionsPortGroupByNone(
    offset: 0
    limit: 50000
    filters: [{name: PortStatus, operator: IsAnyOf, value: ["Open"]}, {name: DomainName, operator: Equals, value: [$asset]}]
  ) {
    success
    ports {
      ipAddress
      port
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useOverviewAssetNetworkQuery__
 *
 * To run a query within a React component, call `useOverviewAssetNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewAssetNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewAssetNetworkQuery({
 *   variables: {
 *      asset: // value for 'asset'
 *   },
 * });
 */
export function useOverviewAssetNetworkQuery(baseOptions: Apollo.QueryHookOptions<OverviewAssetNetworkQuery, OverviewAssetNetworkQueryVariables> & ({ variables: OverviewAssetNetworkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverviewAssetNetworkQuery, OverviewAssetNetworkQueryVariables>(OverviewAssetNetworkDocument, options);
      }
export function useOverviewAssetNetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewAssetNetworkQuery, OverviewAssetNetworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverviewAssetNetworkQuery, OverviewAssetNetworkQueryVariables>(OverviewAssetNetworkDocument, options);
        }
export function useOverviewAssetNetworkSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OverviewAssetNetworkQuery, OverviewAssetNetworkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OverviewAssetNetworkQuery, OverviewAssetNetworkQueryVariables>(OverviewAssetNetworkDocument, options);
        }
export type OverviewAssetNetworkQueryHookResult = ReturnType<typeof useOverviewAssetNetworkQuery>;
export type OverviewAssetNetworkLazyQueryHookResult = ReturnType<typeof useOverviewAssetNetworkLazyQuery>;
export type OverviewAssetNetworkSuspenseQueryHookResult = ReturnType<typeof useOverviewAssetNetworkSuspenseQuery>;
export type OverviewAssetNetworkQueryResult = Apollo.QueryResult<OverviewAssetNetworkQuery, OverviewAssetNetworkQueryVariables>;
export const GetOverviewSeverityGraphDocument = gql`
    query GetOverviewSeverityGraph($input: PrioritizationSeverityGraphInput) {
  severityGraph(input: $input) {
    critical
    high
    low
    medium
    total
  }
}
    `;

/**
 * __useGetOverviewSeverityGraphQuery__
 *
 * To run a query within a React component, call `useGetOverviewSeverityGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewSeverityGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewSeverityGraphQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOverviewSeverityGraphQuery(baseOptions?: Apollo.QueryHookOptions<GetOverviewSeverityGraphQuery, GetOverviewSeverityGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverviewSeverityGraphQuery, GetOverviewSeverityGraphQueryVariables>(GetOverviewSeverityGraphDocument, options);
      }
export function useGetOverviewSeverityGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverviewSeverityGraphQuery, GetOverviewSeverityGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverviewSeverityGraphQuery, GetOverviewSeverityGraphQueryVariables>(GetOverviewSeverityGraphDocument, options);
        }
export function useGetOverviewSeverityGraphSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOverviewSeverityGraphQuery, GetOverviewSeverityGraphQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOverviewSeverityGraphQuery, GetOverviewSeverityGraphQueryVariables>(GetOverviewSeverityGraphDocument, options);
        }
export type GetOverviewSeverityGraphQueryHookResult = ReturnType<typeof useGetOverviewSeverityGraphQuery>;
export type GetOverviewSeverityGraphLazyQueryHookResult = ReturnType<typeof useGetOverviewSeverityGraphLazyQuery>;
export type GetOverviewSeverityGraphSuspenseQueryHookResult = ReturnType<typeof useGetOverviewSeverityGraphSuspenseQuery>;
export type GetOverviewSeverityGraphQueryResult = Apollo.QueryResult<GetOverviewSeverityGraphQuery, GetOverviewSeverityGraphQueryVariables>;
export const GetOverviewMostSevereAssetsDocument = gql`
    query GetOverviewMostSevereAssets($input: PrioritizationMostSevereAssetsInput) {
  mostSevereAssets(input: $input) {
    asset {
      id
      name
    }
    severityGraph {
      critical
      high
      low
      medium
    }
  }
}
    `;

/**
 * __useGetOverviewMostSevereAssetsQuery__
 *
 * To run a query within a React component, call `useGetOverviewMostSevereAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewMostSevereAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewMostSevereAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOverviewMostSevereAssetsQuery(baseOptions?: Apollo.QueryHookOptions<GetOverviewMostSevereAssetsQuery, GetOverviewMostSevereAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverviewMostSevereAssetsQuery, GetOverviewMostSevereAssetsQueryVariables>(GetOverviewMostSevereAssetsDocument, options);
      }
export function useGetOverviewMostSevereAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverviewMostSevereAssetsQuery, GetOverviewMostSevereAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverviewMostSevereAssetsQuery, GetOverviewMostSevereAssetsQueryVariables>(GetOverviewMostSevereAssetsDocument, options);
        }
export function useGetOverviewMostSevereAssetsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOverviewMostSevereAssetsQuery, GetOverviewMostSevereAssetsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOverviewMostSevereAssetsQuery, GetOverviewMostSevereAssetsQueryVariables>(GetOverviewMostSevereAssetsDocument, options);
        }
export type GetOverviewMostSevereAssetsQueryHookResult = ReturnType<typeof useGetOverviewMostSevereAssetsQuery>;
export type GetOverviewMostSevereAssetsLazyQueryHookResult = ReturnType<typeof useGetOverviewMostSevereAssetsLazyQuery>;
export type GetOverviewMostSevereAssetsSuspenseQueryHookResult = ReturnType<typeof useGetOverviewMostSevereAssetsSuspenseQuery>;
export type GetOverviewMostSevereAssetsQueryResult = Apollo.QueryResult<GetOverviewMostSevereAssetsQuery, GetOverviewMostSevereAssetsQueryVariables>;
export const GetPoliciesV2Document = gql`
    query GetPoliciesV2($pagination: PolicyTokenPaginationInput) {
  policyGetPolicies(pagination: $pagination) {
    policies {
      name
      id
      severity
      description
      filters {
        name
        operator
        value
      }
    }
    pagination {
      hasMore
      nextToken
    }
  }
}
    `;

/**
 * __useGetPoliciesV2Query__
 *
 * To run a query within a React component, call `useGetPoliciesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesV2Query({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetPoliciesV2Query(baseOptions?: Apollo.QueryHookOptions<GetPoliciesV2Query, GetPoliciesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPoliciesV2Query, GetPoliciesV2QueryVariables>(GetPoliciesV2Document, options);
      }
export function useGetPoliciesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPoliciesV2Query, GetPoliciesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPoliciesV2Query, GetPoliciesV2QueryVariables>(GetPoliciesV2Document, options);
        }
export function useGetPoliciesV2SuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPoliciesV2Query, GetPoliciesV2QueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPoliciesV2Query, GetPoliciesV2QueryVariables>(GetPoliciesV2Document, options);
        }
export type GetPoliciesV2QueryHookResult = ReturnType<typeof useGetPoliciesV2Query>;
export type GetPoliciesV2LazyQueryHookResult = ReturnType<typeof useGetPoliciesV2LazyQuery>;
export type GetPoliciesV2SuspenseQueryHookResult = ReturnType<typeof useGetPoliciesV2SuspenseQuery>;
export type GetPoliciesV2QueryResult = Apollo.QueryResult<GetPoliciesV2Query, GetPoliciesV2QueryVariables>;
export const GetBreachesBySeverityOverTimeDocument = gql`
    query GetBreachesBySeverityOverTime($input: SurfaceProjectionsBreachesBySeverityOverTimeInput!) {
  surfaceProjectionsBreachesBySeverityOverTime(input: $input) {
    entries {
      time
      appeared {
        critical
        high
        medium
        low
        information
      }
      reappeared {
        critical
        high
        medium
        low
        information
      }
      resolved {
        critical
        high
        medium
        low
        information
      }
      currentTotal {
        critical
        high
        medium
        low
        information
      }
    }
  }
}
    `;

/**
 * __useGetBreachesBySeverityOverTimeQuery__
 *
 * To run a query within a React component, call `useGetBreachesBySeverityOverTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreachesBySeverityOverTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBreachesBySeverityOverTimeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBreachesBySeverityOverTimeQuery(baseOptions: Apollo.QueryHookOptions<GetBreachesBySeverityOverTimeQuery, GetBreachesBySeverityOverTimeQueryVariables> & ({ variables: GetBreachesBySeverityOverTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBreachesBySeverityOverTimeQuery, GetBreachesBySeverityOverTimeQueryVariables>(GetBreachesBySeverityOverTimeDocument, options);
      }
export function useGetBreachesBySeverityOverTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBreachesBySeverityOverTimeQuery, GetBreachesBySeverityOverTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBreachesBySeverityOverTimeQuery, GetBreachesBySeverityOverTimeQueryVariables>(GetBreachesBySeverityOverTimeDocument, options);
        }
export function useGetBreachesBySeverityOverTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBreachesBySeverityOverTimeQuery, GetBreachesBySeverityOverTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBreachesBySeverityOverTimeQuery, GetBreachesBySeverityOverTimeQueryVariables>(GetBreachesBySeverityOverTimeDocument, options);
        }
export type GetBreachesBySeverityOverTimeQueryHookResult = ReturnType<typeof useGetBreachesBySeverityOverTimeQuery>;
export type GetBreachesBySeverityOverTimeLazyQueryHookResult = ReturnType<typeof useGetBreachesBySeverityOverTimeLazyQuery>;
export type GetBreachesBySeverityOverTimeSuspenseQueryHookResult = ReturnType<typeof useGetBreachesBySeverityOverTimeSuspenseQuery>;
export type GetBreachesBySeverityOverTimeQueryResult = Apollo.QueryResult<GetBreachesBySeverityOverTimeQuery, GetBreachesBySeverityOverTimeQueryVariables>;
export const GetPoliciesDocument = gql`
    query GetPolicies($pagination: PolicyTokenPaginationInput) {
  policyGetPolicies(pagination: $pagination) {
    policies {
      name
      id
      severity
      description
      filters {
        name
        operator
        value
      }
    }
    pagination {
      hasMore
      nextToken
    }
  }
}
    `;

/**
 * __useGetPoliciesQuery__
 *
 * To run a query within a React component, call `useGetPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
      }
export function useGetPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
        }
export function useGetPoliciesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
        }
export type GetPoliciesQueryHookResult = ReturnType<typeof useGetPoliciesQuery>;
export type GetPoliciesLazyQueryHookResult = ReturnType<typeof useGetPoliciesLazyQuery>;
export type GetPoliciesSuspenseQueryHookResult = ReturnType<typeof useGetPoliciesSuspenseQuery>;
export type GetPoliciesQueryResult = Apollo.QueryResult<GetPoliciesQuery, GetPoliciesQueryVariables>;
export const DeletePolicyDocument = gql`
    mutation DeletePolicy($input: PolicyDeletePolicyInput!) {
  policyDeletePolicy(deletePolicyInput: $input) {
    success
    error
  }
}
    `;
export type DeletePolicyMutationFn = Apollo.MutationFunction<DeletePolicyMutation, DeletePolicyMutationVariables>;

/**
 * __useDeletePolicyMutation__
 *
 * To run a mutation, you first call `useDeletePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyMutation, { data, loading, error }] = useDeletePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyMutation(baseOptions?: Apollo.MutationHookOptions<DeletePolicyMutation, DeletePolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePolicyMutation, DeletePolicyMutationVariables>(DeletePolicyDocument, options);
      }
export type DeletePolicyMutationHookResult = ReturnType<typeof useDeletePolicyMutation>;
export type DeletePolicyMutationResult = Apollo.MutationResult<DeletePolicyMutation>;
export type DeletePolicyMutationOptions = Apollo.BaseMutationOptions<DeletePolicyMutation, DeletePolicyMutationVariables>;
export const CreatePolicyDocument = gql`
    mutation CreatePolicy($input: PolicyCreatePolicyInput!) {
  policyCreatePolicy(createPolicyInput: $input) {
    success
    error
  }
}
    `;
export type CreatePolicyMutationFn = Apollo.MutationFunction<CreatePolicyMutation, CreatePolicyMutationVariables>;

/**
 * __useCreatePolicyMutation__
 *
 * To run a mutation, you first call `useCreatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyMutation, { data, loading, error }] = useCreatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePolicyMutation, CreatePolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePolicyMutation, CreatePolicyMutationVariables>(CreatePolicyDocument, options);
      }
export type CreatePolicyMutationHookResult = ReturnType<typeof useCreatePolicyMutation>;
export type CreatePolicyMutationResult = Apollo.MutationResult<CreatePolicyMutation>;
export type CreatePolicyMutationOptions = Apollo.BaseMutationOptions<CreatePolicyMutation, CreatePolicyMutationVariables>;
export const GetPolicyBreachesDocument = gql`
    query GetPolicyBreaches($input: SurfaceProjectionsBreachGroupByNoneInput) {
  surfaceProjectionsBreachGroupByNone(input: $input) {
    breaches {
      assetToken
      disappeared
      domainName
      firstSeen
      id
      policyId
      policyName
      severity
      status
      statusUpdated
    }
    hasMore
    offset
    success
    totalCount
  }
}
    `;

/**
 * __useGetPolicyBreachesQuery__
 *
 * To run a query within a React component, call `useGetPolicyBreachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyBreachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyBreachesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPolicyBreachesQuery(baseOptions?: Apollo.QueryHookOptions<GetPolicyBreachesQuery, GetPolicyBreachesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPolicyBreachesQuery, GetPolicyBreachesQueryVariables>(GetPolicyBreachesDocument, options);
      }
export function useGetPolicyBreachesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPolicyBreachesQuery, GetPolicyBreachesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPolicyBreachesQuery, GetPolicyBreachesQueryVariables>(GetPolicyBreachesDocument, options);
        }
export function useGetPolicyBreachesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPolicyBreachesQuery, GetPolicyBreachesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPolicyBreachesQuery, GetPolicyBreachesQueryVariables>(GetPolicyBreachesDocument, options);
        }
export type GetPolicyBreachesQueryHookResult = ReturnType<typeof useGetPolicyBreachesQuery>;
export type GetPolicyBreachesLazyQueryHookResult = ReturnType<typeof useGetPolicyBreachesLazyQuery>;
export type GetPolicyBreachesSuspenseQueryHookResult = ReturnType<typeof useGetPolicyBreachesSuspenseQuery>;
export type GetPolicyBreachesQueryResult = Apollo.QueryResult<GetPolicyBreachesQuery, GetPolicyBreachesQueryVariables>;
export const PortGroupByPortDocument = gql`
    query PortGroupByPort($offset: Int!, $limit: Int, $filters: [SurfaceProjectionPortFilters!], $sortBy: SurfaceProjectionPortSortBy, $search: String) {
  surfaceProjectionsPortGroupByPort(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
  ) {
    success
    ports {
      uuid
      port
      ipAddressCount
      ipAddressOpenCount
      domainCount
      domainOpenCount
      status
      firstScanned
      disappeared
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __usePortGroupByPortQuery__
 *
 * To run a query within a React component, call `usePortGroupByPortQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortGroupByPortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortGroupByPortQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePortGroupByPortQuery(baseOptions: Apollo.QueryHookOptions<PortGroupByPortQuery, PortGroupByPortQueryVariables> & ({ variables: PortGroupByPortQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortGroupByPortQuery, PortGroupByPortQueryVariables>(PortGroupByPortDocument, options);
      }
export function usePortGroupByPortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortGroupByPortQuery, PortGroupByPortQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortGroupByPortQuery, PortGroupByPortQueryVariables>(PortGroupByPortDocument, options);
        }
export function usePortGroupByPortSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PortGroupByPortQuery, PortGroupByPortQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PortGroupByPortQuery, PortGroupByPortQueryVariables>(PortGroupByPortDocument, options);
        }
export type PortGroupByPortQueryHookResult = ReturnType<typeof usePortGroupByPortQuery>;
export type PortGroupByPortLazyQueryHookResult = ReturnType<typeof usePortGroupByPortLazyQuery>;
export type PortGroupByPortSuspenseQueryHookResult = ReturnType<typeof usePortGroupByPortSuspenseQuery>;
export type PortGroupByPortQueryResult = Apollo.QueryResult<PortGroupByPortQuery, PortGroupByPortQueryVariables>;
export const PortGroupByNoneDocument = gql`
    query PortGroupByNone($offset: Int!, $limit: Int, $filters: [SurfaceProjectionPortFilters!], $sortBy: SurfaceProjectionPortSortBy, $search: String) {
  surfaceProjectionsPortGroupByNone(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
  ) {
    success
    ports {
      uuid
      port
      assetToken
      ipAddress
      domainName
      status
      firstScanned
      disappeared
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __usePortGroupByNoneQuery__
 *
 * To run a query within a React component, call `usePortGroupByNoneQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortGroupByNoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortGroupByNoneQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePortGroupByNoneQuery(baseOptions: Apollo.QueryHookOptions<PortGroupByNoneQuery, PortGroupByNoneQueryVariables> & ({ variables: PortGroupByNoneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortGroupByNoneQuery, PortGroupByNoneQueryVariables>(PortGroupByNoneDocument, options);
      }
export function usePortGroupByNoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortGroupByNoneQuery, PortGroupByNoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortGroupByNoneQuery, PortGroupByNoneQueryVariables>(PortGroupByNoneDocument, options);
        }
export function usePortGroupByNoneSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PortGroupByNoneQuery, PortGroupByNoneQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PortGroupByNoneQuery, PortGroupByNoneQueryVariables>(PortGroupByNoneDocument, options);
        }
export type PortGroupByNoneQueryHookResult = ReturnType<typeof usePortGroupByNoneQuery>;
export type PortGroupByNoneLazyQueryHookResult = ReturnType<typeof usePortGroupByNoneLazyQuery>;
export type PortGroupByNoneSuspenseQueryHookResult = ReturnType<typeof usePortGroupByNoneSuspenseQuery>;
export type PortGroupByNoneQueryResult = Apollo.QueryResult<PortGroupByNoneQuery, PortGroupByNoneQueryVariables>;
export const PortFilterValuesDocument = gql`
    query PortFilterValues($filters: [SurfaceProjectionFilterValuesFilters!]) {
  surfaceProjectionsPortFilterValues(filters: $filters) {
    domainName
    ipAddress
    port
    status
  }
}
    `;

/**
 * __usePortFilterValuesQuery__
 *
 * To run a query within a React component, call `usePortFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortFilterValuesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePortFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<PortFilterValuesQuery, PortFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortFilterValuesQuery, PortFilterValuesQueryVariables>(PortFilterValuesDocument, options);
      }
export function usePortFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortFilterValuesQuery, PortFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortFilterValuesQuery, PortFilterValuesQueryVariables>(PortFilterValuesDocument, options);
        }
export function usePortFilterValuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PortFilterValuesQuery, PortFilterValuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PortFilterValuesQuery, PortFilterValuesQueryVariables>(PortFilterValuesDocument, options);
        }
export type PortFilterValuesQueryHookResult = ReturnType<typeof usePortFilterValuesQuery>;
export type PortFilterValuesLazyQueryHookResult = ReturnType<typeof usePortFilterValuesLazyQuery>;
export type PortFilterValuesSuspenseQueryHookResult = ReturnType<typeof usePortFilterValuesSuspenseQuery>;
export type PortFilterValuesQueryResult = Apollo.QueryResult<PortFilterValuesQuery, PortFilterValuesQueryVariables>;
export const GetCurrentTeamDocument = gql`
    query getCurrentTeam {
  getCurrentTeam {
    teamToken
    name
    segment
  }
}
    `;

/**
 * __useGetCurrentTeamQuery__
 *
 * To run a query within a React component, call `useGetCurrentTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentTeamQuery, GetCurrentTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentTeamQuery, GetCurrentTeamQueryVariables>(GetCurrentTeamDocument, options);
      }
export function useGetCurrentTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentTeamQuery, GetCurrentTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentTeamQuery, GetCurrentTeamQueryVariables>(GetCurrentTeamDocument, options);
        }
export function useGetCurrentTeamSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentTeamQuery, GetCurrentTeamQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentTeamQuery, GetCurrentTeamQueryVariables>(GetCurrentTeamDocument, options);
        }
export type GetCurrentTeamQueryHookResult = ReturnType<typeof useGetCurrentTeamQuery>;
export type GetCurrentTeamLazyQueryHookResult = ReturnType<typeof useGetCurrentTeamLazyQuery>;
export type GetCurrentTeamSuspenseQueryHookResult = ReturnType<typeof useGetCurrentTeamSuspenseQuery>;
export type GetCurrentTeamQueryResult = Apollo.QueryResult<GetCurrentTeamQuery, GetCurrentTeamQueryVariables>;
export const TechGroupedByNoneDocument = gql`
    query TechGroupedByNone($offset: Int!, $limit: Int, $filters: [SurfaceProjectionTechFilters!], $sortBy: SurfaceProjectionTechSortBy, $search: String) {
  surfaceProjectionsTechGroupByNone(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
  ) {
    success
    techs {
      name
      active
      assetToken
      domainName
      firstSeen
      techId
      version
      serviceProtocol
      port
      categories
      disappeared
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useTechGroupedByNoneQuery__
 *
 * To run a query within a React component, call `useTechGroupedByNoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechGroupedByNoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechGroupedByNoneQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTechGroupedByNoneQuery(baseOptions: Apollo.QueryHookOptions<TechGroupedByNoneQuery, TechGroupedByNoneQueryVariables> & ({ variables: TechGroupedByNoneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TechGroupedByNoneQuery, TechGroupedByNoneQueryVariables>(TechGroupedByNoneDocument, options);
      }
export function useTechGroupedByNoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechGroupedByNoneQuery, TechGroupedByNoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TechGroupedByNoneQuery, TechGroupedByNoneQueryVariables>(TechGroupedByNoneDocument, options);
        }
export function useTechGroupedByNoneSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TechGroupedByNoneQuery, TechGroupedByNoneQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TechGroupedByNoneQuery, TechGroupedByNoneQueryVariables>(TechGroupedByNoneDocument, options);
        }
export type TechGroupedByNoneQueryHookResult = ReturnType<typeof useTechGroupedByNoneQuery>;
export type TechGroupedByNoneLazyQueryHookResult = ReturnType<typeof useTechGroupedByNoneLazyQuery>;
export type TechGroupedByNoneSuspenseQueryHookResult = ReturnType<typeof useTechGroupedByNoneSuspenseQuery>;
export type TechGroupedByNoneQueryResult = Apollo.QueryResult<TechGroupedByNoneQuery, TechGroupedByNoneQueryVariables>;
export const TechGroupedByNameVersionDocument = gql`
    query TechGroupedByNameVersion($offset: Int!, $limit: Int, $filters: [SurfaceProjectionTechFilters!], $sortBy: SurfaceProjectionTechSortBy, $search: String) {
  surfaceProjectionsTechGroupByNameVersion(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
  ) {
    success
    techs {
      name
      active
      firstSeen
      domainCount
      version
      categories
      disappeared
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useTechGroupedByNameVersionQuery__
 *
 * To run a query within a React component, call `useTechGroupedByNameVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechGroupedByNameVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechGroupedByNameVersionQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTechGroupedByNameVersionQuery(baseOptions: Apollo.QueryHookOptions<TechGroupedByNameVersionQuery, TechGroupedByNameVersionQueryVariables> & ({ variables: TechGroupedByNameVersionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TechGroupedByNameVersionQuery, TechGroupedByNameVersionQueryVariables>(TechGroupedByNameVersionDocument, options);
      }
export function useTechGroupedByNameVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechGroupedByNameVersionQuery, TechGroupedByNameVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TechGroupedByNameVersionQuery, TechGroupedByNameVersionQueryVariables>(TechGroupedByNameVersionDocument, options);
        }
export function useTechGroupedByNameVersionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TechGroupedByNameVersionQuery, TechGroupedByNameVersionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TechGroupedByNameVersionQuery, TechGroupedByNameVersionQueryVariables>(TechGroupedByNameVersionDocument, options);
        }
export type TechGroupedByNameVersionQueryHookResult = ReturnType<typeof useTechGroupedByNameVersionQuery>;
export type TechGroupedByNameVersionLazyQueryHookResult = ReturnType<typeof useTechGroupedByNameVersionLazyQuery>;
export type TechGroupedByNameVersionSuspenseQueryHookResult = ReturnType<typeof useTechGroupedByNameVersionSuspenseQuery>;
export type TechGroupedByNameVersionQueryResult = Apollo.QueryResult<TechGroupedByNameVersionQuery, TechGroupedByNameVersionQueryVariables>;
export const TechGroupedByNameDocument = gql`
    query TechGroupedByName($offset: Int!, $limit: Int, $filters: [SurfaceProjectionTechFilters!], $sortBy: SurfaceProjectionTechSortBy, $search: String) {
  surfaceProjectionsTechGroupByName(
    offset: $offset
    limit: $limit
    filters: $filters
    sortBy: $sortBy
    search: $search
  ) {
    success
    techs {
      name
      active
      firstSeen
      domainCount
      versionCount
      categories
      disappeared
    }
    hasMore
    offset
    totalCount
  }
}
    `;

/**
 * __useTechGroupedByNameQuery__
 *
 * To run a query within a React component, call `useTechGroupedByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechGroupedByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechGroupedByNameQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTechGroupedByNameQuery(baseOptions: Apollo.QueryHookOptions<TechGroupedByNameQuery, TechGroupedByNameQueryVariables> & ({ variables: TechGroupedByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TechGroupedByNameQuery, TechGroupedByNameQueryVariables>(TechGroupedByNameDocument, options);
      }
export function useTechGroupedByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechGroupedByNameQuery, TechGroupedByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TechGroupedByNameQuery, TechGroupedByNameQueryVariables>(TechGroupedByNameDocument, options);
        }
export function useTechGroupedByNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TechGroupedByNameQuery, TechGroupedByNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TechGroupedByNameQuery, TechGroupedByNameQueryVariables>(TechGroupedByNameDocument, options);
        }
export type TechGroupedByNameQueryHookResult = ReturnType<typeof useTechGroupedByNameQuery>;
export type TechGroupedByNameLazyQueryHookResult = ReturnType<typeof useTechGroupedByNameLazyQuery>;
export type TechGroupedByNameSuspenseQueryHookResult = ReturnType<typeof useTechGroupedByNameSuspenseQuery>;
export type TechGroupedByNameQueryResult = Apollo.QueryResult<TechGroupedByNameQuery, TechGroupedByNameQueryVariables>;
export const TechFilterValuesDocument = gql`
    query TechFilterValues($filters: [SurfaceProjectionFilterValuesFilters!]) {
  surfaceProjectionsTechFilterValues(filters: $filters) {
    name
    domainName
    port
    serviceProtocol
    version
  }
}
    `;

/**
 * __useTechFilterValuesQuery__
 *
 * To run a query within a React component, call `useTechFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechFilterValuesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTechFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<TechFilterValuesQuery, TechFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TechFilterValuesQuery, TechFilterValuesQueryVariables>(TechFilterValuesDocument, options);
      }
export function useTechFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechFilterValuesQuery, TechFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TechFilterValuesQuery, TechFilterValuesQueryVariables>(TechFilterValuesDocument, options);
        }
export function useTechFilterValuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TechFilterValuesQuery, TechFilterValuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TechFilterValuesQuery, TechFilterValuesQueryVariables>(TechFilterValuesDocument, options);
        }
export type TechFilterValuesQueryHookResult = ReturnType<typeof useTechFilterValuesQuery>;
export type TechFilterValuesLazyQueryHookResult = ReturnType<typeof useTechFilterValuesLazyQuery>;
export type TechFilterValuesSuspenseQueryHookResult = ReturnType<typeof useTechFilterValuesSuspenseQuery>;
export type TechFilterValuesQueryResult = Apollo.QueryResult<TechFilterValuesQuery, TechFilterValuesQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    token
    firstName
    lastName
    email
    role
    lastLogin
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  user {
    token
    role
    teams {
      name
      token
      isActive
      memberToken
      canChange
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetVulnerabilitiesByDomainDocument = gql`
    query GetVulnerabilitiesByDomain($pagination: VulnerabilitiesPaginationInput!, $sorting: VulnerabilitiesSortingInput, $filter: VulnerabilitiesFilterInput) {
  vulnerabilities(pagination: $pagination, sorting: $sorting, filter: $filter) {
    nodes {
      uuid
      finding_score {
        score
        severity
        version
      }
      finding_title
      last_seen
      location
      severity
      status
      hostname
    }
    total
  }
}
    `;

/**
 * __useGetVulnerabilitiesByDomainQuery__
 *
 * To run a query within a React component, call `useGetVulnerabilitiesByDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVulnerabilitiesByDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVulnerabilitiesByDomainQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetVulnerabilitiesByDomainQuery(baseOptions: Apollo.QueryHookOptions<GetVulnerabilitiesByDomainQuery, GetVulnerabilitiesByDomainQueryVariables> & ({ variables: GetVulnerabilitiesByDomainQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVulnerabilitiesByDomainQuery, GetVulnerabilitiesByDomainQueryVariables>(GetVulnerabilitiesByDomainDocument, options);
      }
export function useGetVulnerabilitiesByDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVulnerabilitiesByDomainQuery, GetVulnerabilitiesByDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVulnerabilitiesByDomainQuery, GetVulnerabilitiesByDomainQueryVariables>(GetVulnerabilitiesByDomainDocument, options);
        }
export function useGetVulnerabilitiesByDomainSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVulnerabilitiesByDomainQuery, GetVulnerabilitiesByDomainQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVulnerabilitiesByDomainQuery, GetVulnerabilitiesByDomainQueryVariables>(GetVulnerabilitiesByDomainDocument, options);
        }
export type GetVulnerabilitiesByDomainQueryHookResult = ReturnType<typeof useGetVulnerabilitiesByDomainQuery>;
export type GetVulnerabilitiesByDomainLazyQueryHookResult = ReturnType<typeof useGetVulnerabilitiesByDomainLazyQuery>;
export type GetVulnerabilitiesByDomainSuspenseQueryHookResult = ReturnType<typeof useGetVulnerabilitiesByDomainSuspenseQuery>;
export type GetVulnerabilitiesByDomainQueryResult = Apollo.QueryResult<GetVulnerabilitiesByDomainQuery, GetVulnerabilitiesByDomainQueryVariables>;
export const GetVulnerabilitiesByDomainFilterValuesDocument = gql`
    query GetVulnerabilitiesByDomainFilterValues {
  filterValues {
    title
  }
}
    `;

/**
 * __useGetVulnerabilitiesByDomainFilterValuesQuery__
 *
 * To run a query within a React component, call `useGetVulnerabilitiesByDomainFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVulnerabilitiesByDomainFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVulnerabilitiesByDomainFilterValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVulnerabilitiesByDomainFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<GetVulnerabilitiesByDomainFilterValuesQuery, GetVulnerabilitiesByDomainFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVulnerabilitiesByDomainFilterValuesQuery, GetVulnerabilitiesByDomainFilterValuesQueryVariables>(GetVulnerabilitiesByDomainFilterValuesDocument, options);
      }
export function useGetVulnerabilitiesByDomainFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVulnerabilitiesByDomainFilterValuesQuery, GetVulnerabilitiesByDomainFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVulnerabilitiesByDomainFilterValuesQuery, GetVulnerabilitiesByDomainFilterValuesQueryVariables>(GetVulnerabilitiesByDomainFilterValuesDocument, options);
        }
export function useGetVulnerabilitiesByDomainFilterValuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVulnerabilitiesByDomainFilterValuesQuery, GetVulnerabilitiesByDomainFilterValuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVulnerabilitiesByDomainFilterValuesQuery, GetVulnerabilitiesByDomainFilterValuesQueryVariables>(GetVulnerabilitiesByDomainFilterValuesDocument, options);
        }
export type GetVulnerabilitiesByDomainFilterValuesQueryHookResult = ReturnType<typeof useGetVulnerabilitiesByDomainFilterValuesQuery>;
export type GetVulnerabilitiesByDomainFilterValuesLazyQueryHookResult = ReturnType<typeof useGetVulnerabilitiesByDomainFilterValuesLazyQuery>;
export type GetVulnerabilitiesByDomainFilterValuesSuspenseQueryHookResult = ReturnType<typeof useGetVulnerabilitiesByDomainFilterValuesSuspenseQuery>;
export type GetVulnerabilitiesByDomainFilterValuesQueryResult = Apollo.QueryResult<GetVulnerabilitiesByDomainFilterValuesQuery, GetVulnerabilitiesByDomainFilterValuesQueryVariables>;
export const namedOperations = {
  Query: {
    GetScanProfilesForTeam: 'GetScanProfilesForTeam',
    TeamSurfaceMonitoringBalance: 'TeamSurfaceMonitoringBalance',
    GetAssetDetails: 'GetAssetDetails',
    GetBillingInternalForTeam: 'GetBillingInternalForTeam',
    DnsQueryGroupByNone: 'DnsQueryGroupByNone',
    DnsQueryFilterValues: 'DnsQueryFilterValues',
    DnsQueryGroupByNoneQuickFilter: 'DnsQueryGroupByNoneQuickFilter',
    Domains: 'Domains',
    SurfaceProjectionsDomainDetails: 'SurfaceProjectionsDomainDetails',
    AttackSurfaceDomainsPolicy: 'AttackSurfaceDomainsPolicy',
    DomainFilterValues: 'DomainFilterValues',
    DomainsQuickFilter: 'DomainsQuickFilter',
    IPsGroupedByIP: 'IPsGroupedByIP',
    IPsGroupedByAsName: 'IPsGroupedByAsName',
    IPsGroupedByCountry: 'IPsGroupedByCountry',
    IPsGroupedByNone: 'IPsGroupedByNone',
    IPFilterValues: 'IPFilterValues',
    OverviewAssetNetwork: 'OverviewAssetNetwork',
    GetOverviewSeverityGraph: 'GetOverviewSeverityGraph',
    GetOverviewMostSevereAssets: 'GetOverviewMostSevereAssets',
    GetPoliciesV2: 'GetPoliciesV2',
    GetBreachesBySeverityOverTime: 'GetBreachesBySeverityOverTime',
    GetPolicies: 'GetPolicies',
    GetPolicyBreaches: 'GetPolicyBreaches',
    PortGroupByPort: 'PortGroupByPort',
    PortGroupByNone: 'PortGroupByNone',
    PortFilterValues: 'PortFilterValues',
    getCurrentTeam: 'getCurrentTeam',
    TechGroupedByNone: 'TechGroupedByNone',
    TechGroupedByNameVersion: 'TechGroupedByNameVersion',
    TechGroupedByName: 'TechGroupedByName',
    TechFilterValues: 'TechFilterValues',
    GetUsers: 'GetUsers',
    GetUser: 'GetUser',
    GetVulnerabilitiesByDomain: 'GetVulnerabilitiesByDomain',
    GetVulnerabilitiesByDomainFilterValues: 'GetVulnerabilitiesByDomainFilterValues'
  },
  Mutation: {
    CreateApplicationScanProfileWithSchedule: 'CreateApplicationScanProfileWithSchedule',
    DeletePolicy: 'DeletePolicy',
    CreatePolicy: 'CreatePolicy'
  },
  Fragment: {
    BaseAsset: 'BaseAsset',
    OriginCategoriesOnAsset: 'OriginCategoriesOnAsset'
  }
}