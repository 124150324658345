import { useGetUserQuery } from "@/network/gen";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useEffect } from "react";

export function KeepSessionAlive(props: {
  client: ApolloClient<NormalizedCacheObject>;
}) {
  useGetUserQuery();

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date(sessionStorage.getItem("x-token-time") || 0);

      // If the x-token is older than 4 minutes, we should refetch any query to get a new token.
      if (new Date().getTime() - date.getTime() > 1000 * 60 * 4) {
        props.client.refetchQueries({ include: ["GetUser"] });
      }
    }, 50000);

    return () => {
      clearInterval(interval);
    };
  }, [props.client]);

  return null;
}
