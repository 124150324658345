import { init } from "@amplitude/analytics-browser";
import {
  GetBillingInternalForTeamDocument,
  GetBillingInternalForTeamQuery,
  GetCurrentTeamDocument,
  GetCurrentTeamQuery,
  GetUserDocument,
  GetUserQuery,
} from "@/network/gen";
import { ApolloQueryResult } from "@apollo/client";
import * as amplitude from "@amplitude/analytics-browser";
import { apolloClient } from "@/network/apolloClient";

export async function initializeAmplitude() {
  init(
    import.meta.env.MODE === "production"
      ? "2e3598273aff45ee483467e80b7b43b5"
      : "3d05734fbff9698bccbf50d6affaa37d",
    {
      ...getParamsFromSpimbra(),
      defaultTracking: {
        formInteractions: false,
        pageViews: false,
        attribution: false,
      },
      trackingOptions: {
        ipAddress: false,
      },
      serverZone: "EU",
    }
  );

  const billingInternalForTeam:
    | ApolloQueryResult<GetBillingInternalForTeamQuery>
    | undefined = await apolloClient?.query({
    query: GetBillingInternalForTeamDocument,
  });

  const user: ApolloQueryResult<GetUserQuery> | undefined =
    await apolloClient?.query({
      query: GetUserDocument,
    });

  const currentTeam: ApolloQueryResult<GetCurrentTeamQuery> | undefined =
    await apolloClient?.query({
      query: GetCurrentTeamDocument,
    });

  const teamBillingInternalCustomer =
    billingInternalForTeam?.data?.billingInternal.customer;

  if (user?.data?.user?.token) {
    amplitude.setUserId(user?.data?.user?.token);
  }

  if (currentTeam?.data?.getCurrentTeam?.teamToken) {
    amplitude.setGroup(
      "team",
      currentTeam?.data?.getCurrentTeam?.teamToken.toUpperCase()
    );
  }

  if (teamBillingInternalCustomer) {
    amplitude.setGroup(
      "planType",
      teamBillingInternalCustomer.planType ??
        teamBillingInternalCustomer.subscription?.planType ??
        "no-subscription-data"
    );

    amplitude.setGroup(
      "canUseApplicationScanning",
      teamBillingInternalCustomer.subscription
        ? (teamBillingInternalCustomer.subscription.scanProfileCapacity ?? 0) >
          0
          ? "true"
          : "false"
        : "no-subscription-data"
    );
    amplitude.setGroup(
      "canUseSurfaceMonitoring",
      teamBillingInternalCustomer.subscription
        ? (teamBillingInternalCustomer.subscription.surfaceMonitoringCapacity ??
            0) > 0
          ? "true"
          : "false"
        : "no-subscription-data"
    );
    amplitude.setGroup(
      "subscriptionStatus",
      teamBillingInternalCustomer.subscription?.status ?? "no-subscription-data"
    );
  }
}

function getParamsFromSpimbra() {
  const searchParams = new URLSearchParams(location.search);
  const deviceId = searchParams.get("ampDeviceId");
  const sessionId = searchParams.get("ampSessionId");

  if (deviceId && sessionId) {
    searchParams.delete("ampDeviceId");
    searchParams.delete("ampSessionId");
    window.history.replaceState(
      {},
      "",
      `${window.location.origin}${window.location.pathname}${
        searchParams?.toString() !== "" ? `?${searchParams.toString()}` : ""
      }`
    );

    return {
      deviceId,
      sessionId: parseInt(sessionId, 10),
    };
  }

  return {};
}
