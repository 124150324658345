export async function storeAuthToken() {
  try {
    const response = await fetch(
      import.meta.env.MODE === "production"
        ? "https://detectify.com/app_bridge/token"
        : "https://staging.detectify.net/app_bridge/token",
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
      }
    );

    if (response.ok) {
      const data = await response.json();
      sessionStorage.setItem("x-token", data?.token);
      sessionStorage.setItem("x-token-time", new Date().toString());
    }
  } catch {
    console.error("Unable to fetch authentication data.");
  }
}
