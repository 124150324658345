import { getSpimbraUrl } from "@/lib/spimbra";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  beforeLoad: async () => {
    // Redirect to Spimbra if in production or staging
    if (
      import.meta.env.MODE === "production" ||
      import.meta.env.MODE === "staging"
    ) {
      throw redirect({
        href: getSpimbraUrl({
          pathname: "/app",
        }),
        replace: true,
      });
    }
  },
});
