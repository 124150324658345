import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "@tanstack/react-router";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./network/apolloClient";
import "./index.css";
import { Provider as JotaiProvider } from "jotai";

import { initializeAmplitude } from "./lib/amplitude";
import { initializeSentry } from "./lib/sentry";

import { router } from "./lib/router";
import { TooltipProvider } from "./components/tooltip/Tooltip";
import { KeepSessionAlive } from "./components/keepSessionAlive/KeepSessionAlive";

// https://vite.dev/guide/build.html#load-error-handling
window.addEventListener("vite:preloadError", window.location.reload);

if (apolloClient) {
  initializeAmplitude();

  if (import.meta.env.PROD) {
    initializeSentry();
  }

  createRoot(document.getElementById("root")!).render(
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <KeepSessionAlive client={apolloClient} />
        <JotaiProvider>
          <TooltipProvider delayDuration={300}>
            <RouterProvider router={router} />
          </TooltipProvider>
        </JotaiProvider>
      </ApolloProvider>
    </StrictMode>
  );
}
