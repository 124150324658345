import { useLocation, useNavigate } from "@tanstack/react-router";
import { Button } from "../button/Button";
import { useEffect, useState } from "react";

export function ErrorBoundaryFallback(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [locationHrefOnMount] = useState(location.href);

  useEffect(() => {
    document.title = "Something went wrong :( | Detectify";
  }, []);

  // Reset error on location change
  useEffect(() => {
    if (location.href !== locationHrefOnMount) {
      // Resetting the error direcly just thows the same error again. Some time need to pass...
      setTimeout(() => {
        props.resetError?.();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="flex h-[100vh] flex-col items-center justify-center gap-6 py-6">
      <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl">
        Something went wrong :(
      </h1>
      {Object.keys(location.search).length > 0 && (
        <Button
          onClick={() => {
            navigate({
              search: undefined,
              reloadDocument: true,
            });
          }}
          size="lg"
        >
          Reset page
        </Button>
      )}
    </div>
  );
}
