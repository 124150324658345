import { MainNavigation } from "./MainNavigation";
import { UserPanel } from "./UserPanel";

export function LargeScreenSidebar() {
  return (
    <div className="fixed hidden h-full w-[12.5rem] flex-col bg-white text-gray-800 lg:flex">
      <UserPanel className="py-[.8125rem]" />
      <MainNavigation className="grow" />
    </div>
  );
}
