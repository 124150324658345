import { apolloClient } from "../../network/apolloClient";
import { GetAssetDetailsQuery, GetAssetDetailsDocument } from "@/network/gen";
import { ApolloQueryResult } from "@apollo/client";
import { notFound } from "@tanstack/react-router";

type LoaderProps = {
  params: {
    assetToken: string;
  };
};

export const loader = async (props: LoaderProps) => {
  const assetDetails: ApolloQueryResult<GetAssetDetailsQuery> | undefined =
    await apolloClient?.query({
      query: GetAssetDetailsDocument,
      variables: {
        assetToken: props.params.assetToken,
      },
    });

  if (!assetDetails?.data?.getAsset?.name) {
    throw notFound();
  }

  return {
    asset: assetDetails.data.getAsset,
  };
};
