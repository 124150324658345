import {
  createRouter,
  parseSearchWith,
  stringifySearchWith,
} from "@tanstack/react-router";
import { routeTree } from "../routeTree.gen";

import { NotFound } from "../components/notFound/NotFound";
import { filterRulesToGraphQlQuery } from "@/components/queryBuilderFilter/queryBuilderFilterUtils";
import { SortingDirection } from "@/network/gen";

// Main router instance
export const router = createRouter({
  defaultNotFoundComponent: NotFound,
  routeTree,
  parseSearch: parseSearchWith((value) => JSON.parse(decodeFromBinary(value))),
  stringifySearch: stringifySearchWith((value) =>
    encodeToBinary(JSON.stringify(value))
  ),
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// https://tanstack.com/router/latest/docs/framework/react/guide/custom-search-param-serialization
export function decodeFromBinary(str: string): string {
  return decodeURIComponent(
    Array.prototype.map
      .call(window.atob(str), function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

// https://tanstack.com/router/latest/docs/framework/react/guide/custom-search-param-serialization
function encodeToBinary(str: string): string {
  return window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
}

export function searchParamsToGraphQlQuery(searchParams: any) {
  const pageSize = 50;

  return {
    filters: searchParams.filters
      ? filterRulesToGraphQlQuery(searchParams.filters)
      : undefined,
    limit: pageSize,
    offset: searchParams.page ? (searchParams.page - 1) * pageSize : 0,
    search: searchParams.search?.toString(),
    sortBy: searchParams.sorting?.length
      ? ({
          name: searchParams.sorting[0]?.id,
          direction: searchParams.sorting[0]?.desc
            ? SortingDirection.Desc
            : SortingDirection.Asc,
        } as any)
      : undefined,
  };
}

export const filterOutSensitiveDataFromUrl = (urlOrPath: string): string => {
  const tokenRegex =
    /\b[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12}\b/gi;
  const filterRegex = /filters=[^&]+/g;
  const searchRegex = /search=[^&]+/g;

  return urlOrPath
    .replace(tokenRegex, "[token]")
    .replace(filterRegex, "filters=[hidden]")
    .replace(searchRegex, "search=[hidden]");
};
