import { createRootRoute } from "@tanstack/react-router";
import { Layout } from "../components/layout/Layout";
import { ErrorBoundaryFallback } from "@/components/errorBoundaryFallback/ErrorBoundaryFallback";

type SearchParams = {
  ampDeviceId?: string;
};

export const Route = createRootRoute({
  component: Layout,
  errorComponent: ErrorBoundaryFallback,
  validateSearch: (search: Record<string, unknown>): SearchParams => search,
});
