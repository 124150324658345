import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="font-averta"
      toastOptions={{
        classNames: {
          actionButton:
            "font-medium !rounded-sm hover:!bg-primary/90 !transition-colors focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-ring focus-visible:!ring-offset-2",
          closeButton:
            "focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-ring focus-visible:!ring-offset-2",
          title: "!font-normal",
          icon: "self-start mt-1",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
