import { SurfaceProjectionFilterOperator } from "@/network/gen";
import { Field, RuleType } from "react-querybuilder";
import { v4 as uuidv4 } from "uuid";

// Supported operators: https://git.detectify.net/surface-management/surface-event-projections/-/blob/staging/internal/repository/repo/filters.go
export const defaultFilterOperators = {
  count: [
    { name: SurfaceProjectionFilterOperator.GreaterThan, label: ">" },
    { name: SurfaceProjectionFilterOperator.LessThan, label: "<" },
    { name: SurfaceProjectionFilterOperator.IsAnyOf, label: "=" },
    { name: SurfaceProjectionFilterOperator.IsNoneOf, label: "!=" },
  ],
  enum: [
    { name: SurfaceProjectionFilterOperator.IsAnyOf, label: "is any of" },
    { name: SurfaceProjectionFilterOperator.IsNoneOf, label: "is none of" },
  ],
  string: [
    { name: SurfaceProjectionFilterOperator.Contains, label: "contains" },
    {
      name: SurfaceProjectionFilterOperator.DoesNotContain,
      label: "does not contain",
    },
    { name: SurfaceProjectionFilterOperator.StartsWith, label: "starts with" },
    {
      name: SurfaceProjectionFilterOperator.DoesNotStartWith,
      label: "does not start with",
    },
    { name: SurfaceProjectionFilterOperator.EndsWith, label: "ends with" },
    {
      name: SurfaceProjectionFilterOperator.DoesNotEndWith,
      label: "does not end with",
    },
    { name: SurfaceProjectionFilterOperator.IsAnyOf, label: "is any of" },
    { name: SurfaceProjectionFilterOperator.IsNoneOf, label: "is none of" },
  ],
  stringArray: [
    {
      name: SurfaceProjectionFilterOperator.ContainsAnyOf,
      label: "contain any of (A ⋂ B != ∅)",
    },
    {
      name: SurfaceProjectionFilterOperator.DoesNotContainAnyOf,
      label: "do not contain any of (A ⋂ B = ∅)",
    },
    {
      name: SurfaceProjectionFilterOperator.ContainsAllOf,
      label: "contain all of (⊇)",
    },
    {
      name: SurfaceProjectionFilterOperator.DoesNotContainAllOf,
      label: "do not contain all of (⊅)",
    },
    {
      name: SurfaceProjectionFilterOperator.ContainsItemNotInList,
      label: "do not only contain (⊄)",
    },
  ],
  time: [
    {
      name: SurfaceProjectionFilterOperator.IsInTheLast,
      label: "is in the last",
    },
    { name: SurfaceProjectionFilterOperator.Before, label: "is on or before" },
    { name: SurfaceProjectionFilterOperator.After, label: "is on or after" },
  ],
  timeWithEmpty: [
    {
      name: SurfaceProjectionFilterOperator.IsInTheLast,
      label: "is in the last",
    },
    { name: SurfaceProjectionFilterOperator.Before, label: "is on or before" },
    { name: SurfaceProjectionFilterOperator.After, label: "is on or after" },
    { name: SurfaceProjectionFilterOperator.Empty, label: "is empty" },
    { name: SurfaceProjectionFilterOperator.NotEmpty, label: "is not empty" },
  ],
};

export const defaultFilterValues = {
  time: {
    isInTheLast: [
      {
        value: "1 days",
        label: "day",
      },
      {
        value: "3 days",
        label: "3 days",
      },
      {
        value: "7 days",
        label: "7 days",
      },
      {
        value: "30 days",
        label: "30 days",
      },
      { value: "3 months", label: "3 months" },
      { value: "6 months", label: "6 months" },
      { value: "12 months", label: "12 months" },
    ],
  },
};

export const getValidRules = (filterRules: RuleType[]): RuleType[] =>
  filterRules?.filter(
    (rule) =>
      rule.value?.length > 0 ||
      rule.operator === "Empty" ||
      rule.operator === "NotEmpty"
  );

export const areFilterRulesEqual = (
  filterRules1: RuleType[],
  filterRules2: RuleType[]
): boolean =>
  JSON.stringify(
    filterRules1?.map((rule) => ({
      field: rule.field,
      operator: rule.operator,
      value: rule.value,
    }))
  ) ===
  JSON.stringify(
    filterRules2?.map((rule) => ({
      field: rule.field,
      operator: rule.operator,
      value: rule.value,
    }))
  );

export function filterRulesToGraphQlQuery(filterRules: RuleType[]) {
  function getFormattedValue(operator: string, value: any) {
    if (operator === "Empty" || operator === "NotEmpty") {
      return undefined;
    } else if (operator === "Before" || operator === "After") {
      // Value is a date from native datepicker => format to iso string
      return new Date(value).toISOString();
    } else if (Array.isArray(value)) {
      return value?.map((item: any) =>
        // Handles parsing numbers in list as string
        typeof item === "number" ? item.toString() : item
      );
    }

    return value;
  }

  const rules = getValidRules(filterRules)?.map((rule: any) => ({
    name: rule.field,
    operator: rule.operator,
    value: getFormattedValue(rule.operator, rule.value),
  }));

  return rules.length ? rules : undefined;
}

export const filterValuesToFilterOptions = (
  values?: Array<string | null> | null
) =>
  values && values.length > 0
    ? values
        .filter((value) => typeof value === "string")
        .map((value) => ({
          label: String(value),
          value,
        }))
    : [];

export function getFilterRuleId() {
  return uuidv4().slice(0, 5); // Id UUID is sliced so it dont't take up that much space in the URL. It is probably unique enough for filtering purposes.
}

export function getFilterRulesWithIds(filterRules: RuleType[]): RuleType[] {
  const ruleMap = new Map<string, RuleType>();

  // Remove duplicates by putting them in a map
  filterRules?.forEach((rule) => {
    const ruleWithId = {
      field: rule.field,
      id: rule.id?.length === 5 ? rule.id : getFilterRuleId(),
      operator: rule.operator,
      value: rule.value,
    };
    ruleMap.set(ruleWithId.id as string, ruleWithId);
  });

  return Array.from(ruleMap.values());
}

export function getFilterRulesWithAddedColumnFilterRule(
  columnId: string,
  filterFields: Field[],
  filterRules: RuleType[]
): RuleType[] {
  const field = filterFields?.find((field) => field.name === columnId);

  return [
    ...filterRules,
    {
      field: field?.name,
      id: getFilterRuleId(),
      operator: (field?.operators?.[0] as any)?.name,
    },
  ] as RuleType[];
}
