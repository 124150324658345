import { getFilterRuleId } from "@/components/queryBuilderFilter/queryBuilderFilterUtils";
import {
  SurfaceProjectionFilterOperator,
  SurfaceProjectionTechFiltersName,
} from "@/network/gen";
import { createFileRoute, redirect } from "@tanstack/react-router";

type SearchParams = {
  filters?: any;
  page?: any;
  search?: string;
  sorting?: any;
};

export const Route = createFileRoute("/technologies/")({
  beforeLoad: async () => {
    throw redirect({
      search: {
        filters: [
          {
            field: SurfaceProjectionTechFiltersName.Active,
            id: getFilterRuleId(),
            operator: SurfaceProjectionFilterOperator.ContainsAnyOf,
            value: "true",
          },
        ],
        sorting: [
          { id: SurfaceProjectionTechFiltersName.FirstSeen, desc: true },
        ],
      },
      to: "/technologies/group-by-technology",
      replace: true,
    });
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    // validate and parse the search params into a typed state

    return search;
  },
});
