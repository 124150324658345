import {
  ApolloClient,
  ApolloLink,
  from,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getSpimbraUrl } from "@/lib/spimbra";
import { storeAuthToken } from "@/lib/auth";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

await storeAuthToken();

function initializeApolloClient() {
  if (!sessionStorage.getItem("x-token")) {
    redirectToLogin();
    return;
  }

  const httpLink = createHttpLink({
    uri:
      import.meta.env.MODE === "production"
        ? "https://integrations.detectify.com/fedeo/graphql"
        : "https://integrations.staging.detectify.net/fedeo/graphql",
  });

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-token": sessionStorage.getItem("x-token"),
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if ((networkError as any)?.statusCode === 401) {
      redirectToLogin();
    }
  });

  // Checks all responses for a new x-token. If any, update sessionstorage.
  const responseLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const xToken = context.response.headers.get("x-token");

      if (xToken) {
        sessionStorage.setItem("x-token", xToken);
        sessionStorage.setItem("x-token-time", new Date().toString());
      }

      return response;
    });
  });

  return new ApolloClient({
    name: import.meta.env.MODE,
    cache: new InMemoryCache(),
    link: from([errorLink, authLink, responseLink, httpLink]),
  });
}

function redirectToLogin() {
  location.href = getSpimbraUrl({
    pathname: `/login`,
    search: {
      returnUrl: encodeURIComponent(location.href),
    },
  });
}

if (
  import.meta.env.MODE !== "production" &&
  import.meta.env.MODE !== "staging"
) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const apolloClient = initializeApolloClient();
