import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/domains/$assetToken/")({
  beforeLoad: async ({ params }) => {
    throw redirect({
      to: "/domains/$assetToken/vulnerabilities",
      params: { assetToken: params.assetToken },
      replace: true,
      search: {
        sorting: [{ id: "severityV3", desc: true }],
      },
    });
  },
});
