import { useEffect } from "react";
import { atom, useSetAtom } from "jotai";

export const windowWidthAtom = atom(window.innerWidth);

export function useWindowWidth() {
  const setWindowWidth = useSetAtom(windowWidthAtom);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const updateWindowWidth = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 200);
    };

    window.addEventListener("resize", updateWindowWidth);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, [setWindowWidth]);
}
