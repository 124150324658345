import { useLocation } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { UserPanel } from "./UserPanel";
import { MenuIcon, XIcon } from "lucide-react";
import { MainNavigation } from "./MainNavigation";

export function SmallScreenHeader() {
  const location = useLocation();
  const headerRef = useRef<HTMLElement>(null);
  const mobileNavDialogRef = useRef<HTMLDialogElement>(null);
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

  function closeMobileNav() {
    setMobileNavIsOpen(false);
    mobileNavDialogRef?.current?.close();
    document.body.style.overflow = "";
  }

  function openMobileNav() {
    if (mobileNavDialogRef?.current) {
      setMobileNavIsOpen(true);
      mobileNavDialogRef.current.style.marginTop = `${headerRef.current?.clientHeight ?? 0}px`;
      mobileNavDialogRef.current.show();
      document.body.style.overflow = "hidden";
    }
  }

  // Close navs on route change
  useEffect(() => {
    closeMobileNav();
  }, [location]);

  return (
    <header className="flex justify-between bg-white lg:hidden" ref={headerRef}>
      <UserPanel />
      <button
        onClick={
          mobileNavDialogRef.current?.open ? closeMobileNav : openMobileNav
        }
        className="p-[.9375rem]"
      >
        {mobileNavIsOpen ? (
          <XIcon className="h-6 w-6" />
        ) : (
          <MenuIcon className="h-6 w-6" />
        )}
      </button>
      <dialog
        ref={mobileNavDialogRef}
        className="bottom-0 top-0 z-50 h-auto w-full"
      >
        <MainNavigation />
      </dialog>
    </header>
  );
}
