import {
  ChevronDownIcon,
  Settings,
  Users,
  CreditCard,
  Check,
  Power,
  Repeat,
} from "lucide-react";
import { useGetUserQuery, useGetUsersQuery, User } from "@/network/gen";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover/Popover";
import { cn } from "@/lib/utils";

import { getSpimbraUrl } from "@/lib/spimbra";
import { Skeleton } from "@/components/skeleton/Skeleton";

export function UserPanel(props: { className?: string }) {
  const users = useGetUsersQuery()?.data?.users;
  const user = useGetUserQuery()?.data?.user;
  const currentUser: Partial<User> = {
    ...user,
    ...users?.find((item: Partial<User>) => item.token === user?.token),
  };
  const currentTeam = currentUser?.teams?.find((team) => team.isActive);
  const sortedTeams = currentUser?.teams
    ? [...currentUser.teams].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          "group flex items-center gap-2 pl-4 pr-4 text-left text-xs lg:pl-6",
          props.className
        )}
      >
        <span className="flex h-8 max-w-[8.5rem] flex-col justify-between">
          {currentTeam ? (
            <span className="block truncate font-medium">
              {currentTeam.name}
            </span>
          ) : (
            <Skeleton className="block h-3 w-[5rem] bg-gray-200" />
          )}
          {currentUser?.firstName ? (
            <span className="block truncate">
              {`${currentUser.firstName} ${currentUser.lastName ?? ""}`}
            </span>
          ) : (
            <span>
              <Skeleton className="mr-1 inline-block h-3 w-[2rem] bg-gray-200" />
              <Skeleton className="inline-block h-3 w-[3.5rem] bg-gray-200" />
            </span>
          )}
        </span>
        <span className="flex grow justify-end">
          <ChevronDownIcon className="h-4 w-4 transition-transform duration-300 group-data-[state=open]:rotate-[-180deg]" />
        </span>
      </PopoverTrigger>
      <PopoverContent className="ml-1 w-[12.5rem] rounded-lg border-none bg-neutral-800 px-0 pb-0 text-white">
        <div className="flex flex-col text-xs">
          <nav className="flex flex-col">
            <a
              href={getSpimbraUrl({ pathname: "/app/profile" })}
              className="px-4 pb-2 font-semibold hover:text-detectify"
            >
              <Settings className="mr-1 inline-block w-4" /> Account settings
            </a>
            <a
              href={getSpimbraUrl({ pathname: "/app/organization/members" })}
              className="px-4 py-2 font-semibold hover:text-detectify"
            >
              <Users className="mr-1 inline-block w-4" />
              Organization
            </a>
            <a
              href={getSpimbraUrl({ pathname: "/app/billing" })}
              className="px-4 py-2 font-semibold hover:text-detectify"
            >
              <CreditCard className="mr-1 inline-block w-4" /> Billing
            </a>

            {sortedTeams && (
              <div className="flex flex-col bg-white/5 py-2">
                <h4 className="px-4 py-1 text-neutral-300">
                  <Repeat className="mr-1 inline-block w-4" />
                  Switch team
                </h4>
                <ul>
                  {sortedTeams?.map((team) =>
                    team.isActive ? (
                      <li
                        className="flex items-center justify-between px-4 py-1 font-semibold text-detectify"
                        key={team.token}
                      >
                        {team?.name}
                        <Check className="w-5" strokeWidth={3} />
                      </li>
                    ) : (
                      <li key={team.token}>
                        {team.canChange && team.memberToken ? (
                          <a
                            className="block w-full px-4 py-2 text-left font-semibold hover:text-detectify"
                            href={getSpimbraUrl({
                              pathname: `/app/switch-team?token=${team.memberToken}`,
                            })}
                          >
                            {team.name}
                          </a>
                        ) : (
                          <span className="block px-4 py-2 font-semibold">
                            {team.name}
                          </span>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}

            <a
              className="flex items-center gap-2 self-end px-4 py-2 text-neutral-300 hover:text-pink-500"
              href={getSpimbraUrl({ pathname: "/app/logout" })}
              onClick={() => sessionStorage.clear()}
            >
              Sign out
              <Power className="w-4" />
            </a>
          </nav>
        </div>
      </PopoverContent>
    </Popover>
  );
}
