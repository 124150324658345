import { Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { SmallScreenHeader } from "./components/SmallScreenHeader";
import { LargeScreenSidebar } from "./components/LargeScreenSidebar";
import { ErrorBoundary } from "@sentry/react";
import { ErrorBoundaryFallback } from "../errorBoundaryFallback/ErrorBoundaryFallback";
import { Suspense } from "react";
import { SkipToMainContent } from "./components/SkipToMainContent";
import { Toaster } from "../toaster/Toaster";
import { useWindowWidth } from "@/hooks/useWindowWidth";

export function Layout() {
  useWindowWidth();

  return (
    <>
      <Suspense>
        <ErrorBoundary fallback={ErrorBoundaryFallback}>
          <SkipToMainContent />
          <SmallScreenHeader />
          <LargeScreenSidebar />
          <main id="main" className="px-4 lg:pl-[calc(12.5rem+24px)] lg:pr-6">
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Outlet />
            </ErrorBoundary>
          </main>
          <Toaster richColors expand />
        </ErrorBoundary>
      </Suspense>
      {import.meta.env.DEV && (
        <TanStackRouterDevtools position="bottom-right" />
      )}
    </>
  );
}
