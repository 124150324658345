import { getFilterRuleId } from "@/components/queryBuilderFilter/queryBuilderFilterUtils";
import {
  SurfaceProjectionFilterOperator,
  SurfaceProjectionPortFiltersName,
  SurfaceProjectionPortStatus,
} from "@/network/gen";
import { createFileRoute, redirect } from "@tanstack/react-router";

type SearchParams = {
  filters?: any;
  page?: any;
  search?: string;
  sorting?: any;
};

export const Route = createFileRoute("/ports/")({
  beforeLoad: async () => {
    throw redirect({
      to: "/ports/group-by-port",
      search: {
        filters: [
          {
            field: SurfaceProjectionPortFiltersName.PortStatus,
            id: getFilterRuleId(),
            operator: SurfaceProjectionFilterOperator.IsAnyOf,
            value: SurfaceProjectionPortStatus.Open,
          },
        ],
        sorting: [
          { id: SurfaceProjectionPortFiltersName.FirstScanned, desc: true },
        ],
      },
      replace: true,
    });
  },

  validateSearch: (search: Record<string, unknown>): SearchParams => {
    // validate and parse the search params into a typed state

    return search;
  },
});
