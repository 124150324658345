import { Role, useGetUserQuery } from "@/network/gen";
import { NetworkStatus } from "@apollo/client";
import { useEffect, useState } from "react";

export const usePermission = () => {
  const [role, setRole] = useState<Role>(Role.Viewer);
  const user = useGetUserQuery();

  useEffect(() => {
    if (user.data?.user?.role?.length !== 0) {
      setRole(user.data?.user.role as Role);
    }
  }, [user]);

  const isAdmin = role === Role.Admin;
  const isAdminOrEditor = [Role.Admin, Role.Editor].includes(role);
  const isAnyRole = true;

  return {
    loading: [NetworkStatus.loading, NetworkStatus.refetch].includes(
      user.networkStatus
    ),
    canViewApplicationScanningSettings: isAnyRole,
    canEditApplicationScanningSettings: isAdminOrEditor,
    canAddApplicationScanProfile: isAdminOrEditor,
    canRemoveApplicationScanProfile: isAdminOrEditor,
    canManageApplicationScanProfile: isAdminOrEditor,
    canEditRecordedLogin: isAdminOrEditor,
    canVerifyAsset: isAdminOrEditor,
    canAddAsset: isAdminOrEditor,
    canDeleteAsset: isAdminOrEditor,
    canManageSubscription: isAdmin,
    canManageBilling: isAdmin,
    canViewBilling: isAnyRole,
    canViewBillingHistory: isAnyRole,
    canViewSurfaceMonitoringSettings: isAdmin,
    canEditSurfaceMonitoringSettings: isAdmin,
    canManageSurfaceMonitoring: isAdmin,
    canManageUsers: isAdmin,
    canManageIntegrations: isAdminOrEditor,
    canManageApi: isAdmin,
    canManageConnectors: isAdminOrEditor,
    canManagePolicy: isAdminOrEditor,
  };
};
