import { createFileRoute } from "@tanstack/react-router";

type SearchParams = {
  filters?: any;
  page?: any;
  search?: string;
  sorting?: any;
};

export const Route = createFileRoute("/policies/breaches/")({
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    // validate and parse the search params into a typed state

    return search;
  },
});
