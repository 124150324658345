/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as IndexImport } from './routes/index'
import { Route as TechnologiesIndexImport } from './routes/technologies/index'
import { Route as PortsIndexImport } from './routes/ports/index'
import { Route as IpsIndexImport } from './routes/ips/index'
import { Route as DomainsIndexImport } from './routes/domains/index'
import { Route as DnsIndexImport } from './routes/dns/index'
import { Route as TechnologiesGroupByVersionIndexImport } from './routes/technologies/group-by-version/index'
import { Route as TechnologiesGroupByTechnologyIndexImport } from './routes/technologies/group-by-technology/index'
import { Route as TechnologiesGroupByNoneIndexImport } from './routes/technologies/group-by-none/index'
import { Route as PortsGroupByPortIndexImport } from './routes/ports/group-by-port/index'
import { Route as PortsGroupByNoneIndexImport } from './routes/ports/group-by-none/index'
import { Route as PoliciesBreachesIndexImport } from './routes/policies/breaches/index'
import { Route as IpsGroupByProviderIndexImport } from './routes/ips/group-by-provider/index'
import { Route as IpsGroupByNoneIndexImport } from './routes/ips/group-by-none/index'
import { Route as IpsGroupByIpIndexImport } from './routes/ips/group-by-ip/index'
import { Route as IpsGroupByCountryIndexImport } from './routes/ips/group-by-country/index'
import { Route as DomainsAssetTokenIndexImport } from './routes/domains/$assetToken/index'
import { Route as DomainsAssetTokenVulnerabilitiesIndexImport } from './routes/domains/$assetToken/vulnerabilities/index'
import { Route as DomainsAssetTokenTechnologiesIndexImport } from './routes/domains/$assetToken/technologies/index'
import { Route as DomainsAssetTokenPortsIndexImport } from './routes/domains/$assetToken/ports/index'
import { Route as DomainsAssetTokenIpsIndexImport } from './routes/domains/$assetToken/ips/index'
import { Route as DomainsAssetTokenDnsIndexImport } from './routes/domains/$assetToken/dns/index'

// Create Virtual Routes

const PoliciesIndexLazyImport = createFileRoute('/policies/')()

// Create/Update Routes

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const PoliciesIndexLazyRoute = PoliciesIndexLazyImport.update({
  id: '/policies/',
  path: '/policies/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/policies/index.lazy').then((d) => d.Route),
)

const TechnologiesIndexRoute = TechnologiesIndexImport.update({
  id: '/technologies/',
  path: '/technologies/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/technologies/index.lazy').then((d) => d.Route),
)

const PortsIndexRoute = PortsIndexImport.update({
  id: '/ports/',
  path: '/ports/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/ports/index.lazy').then((d) => d.Route))

const IpsIndexRoute = IpsIndexImport.update({
  id: '/ips/',
  path: '/ips/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/ips/index.lazy').then((d) => d.Route))

const DomainsIndexRoute = DomainsIndexImport.update({
  id: '/domains/',
  path: '/domains/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/domains/index.lazy').then((d) => d.Route))

const DnsIndexRoute = DnsIndexImport.update({
  id: '/dns/',
  path: '/dns/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/dns/index.lazy').then((d) => d.Route))

const TechnologiesGroupByVersionIndexRoute =
  TechnologiesGroupByVersionIndexImport.update({
    id: '/technologies/group-by-version/',
    path: '/technologies/group-by-version/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/technologies/group-by-version/index.lazy').then(
      (d) => d.Route,
    ),
  )

const TechnologiesGroupByTechnologyIndexRoute =
  TechnologiesGroupByTechnologyIndexImport.update({
    id: '/technologies/group-by-technology/',
    path: '/technologies/group-by-technology/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/technologies/group-by-technology/index.lazy').then(
      (d) => d.Route,
    ),
  )

const TechnologiesGroupByNoneIndexRoute =
  TechnologiesGroupByNoneIndexImport.update({
    id: '/technologies/group-by-none/',
    path: '/technologies/group-by-none/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/technologies/group-by-none/index.lazy').then(
      (d) => d.Route,
    ),
  )

const PortsGroupByPortIndexRoute = PortsGroupByPortIndexImport.update({
  id: '/ports/group-by-port/',
  path: '/ports/group-by-port/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/ports/group-by-port/index.lazy').then((d) => d.Route),
)

const PortsGroupByNoneIndexRoute = PortsGroupByNoneIndexImport.update({
  id: '/ports/group-by-none/',
  path: '/ports/group-by-none/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/ports/group-by-none/index.lazy').then((d) => d.Route),
)

const PoliciesBreachesIndexRoute = PoliciesBreachesIndexImport.update({
  id: '/policies/breaches/',
  path: '/policies/breaches/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/policies/breaches/index.lazy').then((d) => d.Route),
)

const IpsGroupByProviderIndexRoute = IpsGroupByProviderIndexImport.update({
  id: '/ips/group-by-provider/',
  path: '/ips/group-by-provider/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/ips/group-by-provider/index.lazy').then((d) => d.Route),
)

const IpsGroupByNoneIndexRoute = IpsGroupByNoneIndexImport.update({
  id: '/ips/group-by-none/',
  path: '/ips/group-by-none/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/ips/group-by-none/index.lazy').then((d) => d.Route),
)

const IpsGroupByIpIndexRoute = IpsGroupByIpIndexImport.update({
  id: '/ips/group-by-ip/',
  path: '/ips/group-by-ip/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/ips/group-by-ip/index.lazy').then((d) => d.Route),
)

const IpsGroupByCountryIndexRoute = IpsGroupByCountryIndexImport.update({
  id: '/ips/group-by-country/',
  path: '/ips/group-by-country/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/ips/group-by-country/index.lazy').then((d) => d.Route),
)

const DomainsAssetTokenIndexRoute = DomainsAssetTokenIndexImport.update({
  id: '/domains/$assetToken/',
  path: '/domains/$assetToken/',
  getParentRoute: () => rootRoute,
} as any)

const DomainsAssetTokenVulnerabilitiesIndexRoute =
  DomainsAssetTokenVulnerabilitiesIndexImport.update({
    id: '/domains/$assetToken/vulnerabilities/',
    path: '/domains/$assetToken/vulnerabilities/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/domains/$assetToken/vulnerabilities/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DomainsAssetTokenTechnologiesIndexRoute =
  DomainsAssetTokenTechnologiesIndexImport.update({
    id: '/domains/$assetToken/technologies/',
    path: '/domains/$assetToken/technologies/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/domains/$assetToken/technologies/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DomainsAssetTokenPortsIndexRoute =
  DomainsAssetTokenPortsIndexImport.update({
    id: '/domains/$assetToken/ports/',
    path: '/domains/$assetToken/ports/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/domains/$assetToken/ports/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DomainsAssetTokenIpsIndexRoute = DomainsAssetTokenIpsIndexImport.update({
  id: '/domains/$assetToken/ips/',
  path: '/domains/$assetToken/ips/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/domains/$assetToken/ips/index.lazy').then((d) => d.Route),
)

const DomainsAssetTokenDnsIndexRoute = DomainsAssetTokenDnsIndexImport.update({
  id: '/domains/$assetToken/dns/',
  path: '/domains/$assetToken/dns/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/domains/$assetToken/dns/index.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dns/': {
      id: '/dns/'
      path: '/dns'
      fullPath: '/dns'
      preLoaderRoute: typeof DnsIndexImport
      parentRoute: typeof rootRoute
    }
    '/domains/': {
      id: '/domains/'
      path: '/domains'
      fullPath: '/domains'
      preLoaderRoute: typeof DomainsIndexImport
      parentRoute: typeof rootRoute
    }
    '/ips/': {
      id: '/ips/'
      path: '/ips'
      fullPath: '/ips'
      preLoaderRoute: typeof IpsIndexImport
      parentRoute: typeof rootRoute
    }
    '/ports/': {
      id: '/ports/'
      path: '/ports'
      fullPath: '/ports'
      preLoaderRoute: typeof PortsIndexImport
      parentRoute: typeof rootRoute
    }
    '/technologies/': {
      id: '/technologies/'
      path: '/technologies'
      fullPath: '/technologies'
      preLoaderRoute: typeof TechnologiesIndexImport
      parentRoute: typeof rootRoute
    }
    '/policies/': {
      id: '/policies/'
      path: '/policies'
      fullPath: '/policies'
      preLoaderRoute: typeof PoliciesIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/domains/$assetToken/': {
      id: '/domains/$assetToken/'
      path: '/domains/$assetToken'
      fullPath: '/domains/$assetToken'
      preLoaderRoute: typeof DomainsAssetTokenIndexImport
      parentRoute: typeof rootRoute
    }
    '/ips/group-by-country/': {
      id: '/ips/group-by-country/'
      path: '/ips/group-by-country'
      fullPath: '/ips/group-by-country'
      preLoaderRoute: typeof IpsGroupByCountryIndexImport
      parentRoute: typeof rootRoute
    }
    '/ips/group-by-ip/': {
      id: '/ips/group-by-ip/'
      path: '/ips/group-by-ip'
      fullPath: '/ips/group-by-ip'
      preLoaderRoute: typeof IpsGroupByIpIndexImport
      parentRoute: typeof rootRoute
    }
    '/ips/group-by-none/': {
      id: '/ips/group-by-none/'
      path: '/ips/group-by-none'
      fullPath: '/ips/group-by-none'
      preLoaderRoute: typeof IpsGroupByNoneIndexImport
      parentRoute: typeof rootRoute
    }
    '/ips/group-by-provider/': {
      id: '/ips/group-by-provider/'
      path: '/ips/group-by-provider'
      fullPath: '/ips/group-by-provider'
      preLoaderRoute: typeof IpsGroupByProviderIndexImport
      parentRoute: typeof rootRoute
    }
    '/policies/breaches/': {
      id: '/policies/breaches/'
      path: '/policies/breaches'
      fullPath: '/policies/breaches'
      preLoaderRoute: typeof PoliciesBreachesIndexImport
      parentRoute: typeof rootRoute
    }
    '/ports/group-by-none/': {
      id: '/ports/group-by-none/'
      path: '/ports/group-by-none'
      fullPath: '/ports/group-by-none'
      preLoaderRoute: typeof PortsGroupByNoneIndexImport
      parentRoute: typeof rootRoute
    }
    '/ports/group-by-port/': {
      id: '/ports/group-by-port/'
      path: '/ports/group-by-port'
      fullPath: '/ports/group-by-port'
      preLoaderRoute: typeof PortsGroupByPortIndexImport
      parentRoute: typeof rootRoute
    }
    '/technologies/group-by-none/': {
      id: '/technologies/group-by-none/'
      path: '/technologies/group-by-none'
      fullPath: '/technologies/group-by-none'
      preLoaderRoute: typeof TechnologiesGroupByNoneIndexImport
      parentRoute: typeof rootRoute
    }
    '/technologies/group-by-technology/': {
      id: '/technologies/group-by-technology/'
      path: '/technologies/group-by-technology'
      fullPath: '/technologies/group-by-technology'
      preLoaderRoute: typeof TechnologiesGroupByTechnologyIndexImport
      parentRoute: typeof rootRoute
    }
    '/technologies/group-by-version/': {
      id: '/technologies/group-by-version/'
      path: '/technologies/group-by-version'
      fullPath: '/technologies/group-by-version'
      preLoaderRoute: typeof TechnologiesGroupByVersionIndexImport
      parentRoute: typeof rootRoute
    }
    '/domains/$assetToken/dns/': {
      id: '/domains/$assetToken/dns/'
      path: '/domains/$assetToken/dns'
      fullPath: '/domains/$assetToken/dns'
      preLoaderRoute: typeof DomainsAssetTokenDnsIndexImport
      parentRoute: typeof rootRoute
    }
    '/domains/$assetToken/ips/': {
      id: '/domains/$assetToken/ips/'
      path: '/domains/$assetToken/ips'
      fullPath: '/domains/$assetToken/ips'
      preLoaderRoute: typeof DomainsAssetTokenIpsIndexImport
      parentRoute: typeof rootRoute
    }
    '/domains/$assetToken/ports/': {
      id: '/domains/$assetToken/ports/'
      path: '/domains/$assetToken/ports'
      fullPath: '/domains/$assetToken/ports'
      preLoaderRoute: typeof DomainsAssetTokenPortsIndexImport
      parentRoute: typeof rootRoute
    }
    '/domains/$assetToken/technologies/': {
      id: '/domains/$assetToken/technologies/'
      path: '/domains/$assetToken/technologies'
      fullPath: '/domains/$assetToken/technologies'
      preLoaderRoute: typeof DomainsAssetTokenTechnologiesIndexImport
      parentRoute: typeof rootRoute
    }
    '/domains/$assetToken/vulnerabilities/': {
      id: '/domains/$assetToken/vulnerabilities/'
      path: '/domains/$assetToken/vulnerabilities'
      fullPath: '/domains/$assetToken/vulnerabilities'
      preLoaderRoute: typeof DomainsAssetTokenVulnerabilitiesIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dns': typeof DnsIndexRoute
  '/domains': typeof DomainsIndexRoute
  '/ips': typeof IpsIndexRoute
  '/ports': typeof PortsIndexRoute
  '/technologies': typeof TechnologiesIndexRoute
  '/policies': typeof PoliciesIndexLazyRoute
  '/domains/$assetToken': typeof DomainsAssetTokenIndexRoute
  '/ips/group-by-country': typeof IpsGroupByCountryIndexRoute
  '/ips/group-by-ip': typeof IpsGroupByIpIndexRoute
  '/ips/group-by-none': typeof IpsGroupByNoneIndexRoute
  '/ips/group-by-provider': typeof IpsGroupByProviderIndexRoute
  '/policies/breaches': typeof PoliciesBreachesIndexRoute
  '/ports/group-by-none': typeof PortsGroupByNoneIndexRoute
  '/ports/group-by-port': typeof PortsGroupByPortIndexRoute
  '/technologies/group-by-none': typeof TechnologiesGroupByNoneIndexRoute
  '/technologies/group-by-technology': typeof TechnologiesGroupByTechnologyIndexRoute
  '/technologies/group-by-version': typeof TechnologiesGroupByVersionIndexRoute
  '/domains/$assetToken/dns': typeof DomainsAssetTokenDnsIndexRoute
  '/domains/$assetToken/ips': typeof DomainsAssetTokenIpsIndexRoute
  '/domains/$assetToken/ports': typeof DomainsAssetTokenPortsIndexRoute
  '/domains/$assetToken/technologies': typeof DomainsAssetTokenTechnologiesIndexRoute
  '/domains/$assetToken/vulnerabilities': typeof DomainsAssetTokenVulnerabilitiesIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dns': typeof DnsIndexRoute
  '/domains': typeof DomainsIndexRoute
  '/ips': typeof IpsIndexRoute
  '/ports': typeof PortsIndexRoute
  '/technologies': typeof TechnologiesIndexRoute
  '/policies': typeof PoliciesIndexLazyRoute
  '/domains/$assetToken': typeof DomainsAssetTokenIndexRoute
  '/ips/group-by-country': typeof IpsGroupByCountryIndexRoute
  '/ips/group-by-ip': typeof IpsGroupByIpIndexRoute
  '/ips/group-by-none': typeof IpsGroupByNoneIndexRoute
  '/ips/group-by-provider': typeof IpsGroupByProviderIndexRoute
  '/policies/breaches': typeof PoliciesBreachesIndexRoute
  '/ports/group-by-none': typeof PortsGroupByNoneIndexRoute
  '/ports/group-by-port': typeof PortsGroupByPortIndexRoute
  '/technologies/group-by-none': typeof TechnologiesGroupByNoneIndexRoute
  '/technologies/group-by-technology': typeof TechnologiesGroupByTechnologyIndexRoute
  '/technologies/group-by-version': typeof TechnologiesGroupByVersionIndexRoute
  '/domains/$assetToken/dns': typeof DomainsAssetTokenDnsIndexRoute
  '/domains/$assetToken/ips': typeof DomainsAssetTokenIpsIndexRoute
  '/domains/$assetToken/ports': typeof DomainsAssetTokenPortsIndexRoute
  '/domains/$assetToken/technologies': typeof DomainsAssetTokenTechnologiesIndexRoute
  '/domains/$assetToken/vulnerabilities': typeof DomainsAssetTokenVulnerabilitiesIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dns/': typeof DnsIndexRoute
  '/domains/': typeof DomainsIndexRoute
  '/ips/': typeof IpsIndexRoute
  '/ports/': typeof PortsIndexRoute
  '/technologies/': typeof TechnologiesIndexRoute
  '/policies/': typeof PoliciesIndexLazyRoute
  '/domains/$assetToken/': typeof DomainsAssetTokenIndexRoute
  '/ips/group-by-country/': typeof IpsGroupByCountryIndexRoute
  '/ips/group-by-ip/': typeof IpsGroupByIpIndexRoute
  '/ips/group-by-none/': typeof IpsGroupByNoneIndexRoute
  '/ips/group-by-provider/': typeof IpsGroupByProviderIndexRoute
  '/policies/breaches/': typeof PoliciesBreachesIndexRoute
  '/ports/group-by-none/': typeof PortsGroupByNoneIndexRoute
  '/ports/group-by-port/': typeof PortsGroupByPortIndexRoute
  '/technologies/group-by-none/': typeof TechnologiesGroupByNoneIndexRoute
  '/technologies/group-by-technology/': typeof TechnologiesGroupByTechnologyIndexRoute
  '/technologies/group-by-version/': typeof TechnologiesGroupByVersionIndexRoute
  '/domains/$assetToken/dns/': typeof DomainsAssetTokenDnsIndexRoute
  '/domains/$assetToken/ips/': typeof DomainsAssetTokenIpsIndexRoute
  '/domains/$assetToken/ports/': typeof DomainsAssetTokenPortsIndexRoute
  '/domains/$assetToken/technologies/': typeof DomainsAssetTokenTechnologiesIndexRoute
  '/domains/$assetToken/vulnerabilities/': typeof DomainsAssetTokenVulnerabilitiesIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dns'
    | '/domains'
    | '/ips'
    | '/ports'
    | '/technologies'
    | '/policies'
    | '/domains/$assetToken'
    | '/ips/group-by-country'
    | '/ips/group-by-ip'
    | '/ips/group-by-none'
    | '/ips/group-by-provider'
    | '/policies/breaches'
    | '/ports/group-by-none'
    | '/ports/group-by-port'
    | '/technologies/group-by-none'
    | '/technologies/group-by-technology'
    | '/technologies/group-by-version'
    | '/domains/$assetToken/dns'
    | '/domains/$assetToken/ips'
    | '/domains/$assetToken/ports'
    | '/domains/$assetToken/technologies'
    | '/domains/$assetToken/vulnerabilities'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dns'
    | '/domains'
    | '/ips'
    | '/ports'
    | '/technologies'
    | '/policies'
    | '/domains/$assetToken'
    | '/ips/group-by-country'
    | '/ips/group-by-ip'
    | '/ips/group-by-none'
    | '/ips/group-by-provider'
    | '/policies/breaches'
    | '/ports/group-by-none'
    | '/ports/group-by-port'
    | '/technologies/group-by-none'
    | '/technologies/group-by-technology'
    | '/technologies/group-by-version'
    | '/domains/$assetToken/dns'
    | '/domains/$assetToken/ips'
    | '/domains/$assetToken/ports'
    | '/domains/$assetToken/technologies'
    | '/domains/$assetToken/vulnerabilities'
  id:
    | '__root__'
    | '/'
    | '/dns/'
    | '/domains/'
    | '/ips/'
    | '/ports/'
    | '/technologies/'
    | '/policies/'
    | '/domains/$assetToken/'
    | '/ips/group-by-country/'
    | '/ips/group-by-ip/'
    | '/ips/group-by-none/'
    | '/ips/group-by-provider/'
    | '/policies/breaches/'
    | '/ports/group-by-none/'
    | '/ports/group-by-port/'
    | '/technologies/group-by-none/'
    | '/technologies/group-by-technology/'
    | '/technologies/group-by-version/'
    | '/domains/$assetToken/dns/'
    | '/domains/$assetToken/ips/'
    | '/domains/$assetToken/ports/'
    | '/domains/$assetToken/technologies/'
    | '/domains/$assetToken/vulnerabilities/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DnsIndexRoute: typeof DnsIndexRoute
  DomainsIndexRoute: typeof DomainsIndexRoute
  IpsIndexRoute: typeof IpsIndexRoute
  PortsIndexRoute: typeof PortsIndexRoute
  TechnologiesIndexRoute: typeof TechnologiesIndexRoute
  PoliciesIndexLazyRoute: typeof PoliciesIndexLazyRoute
  DomainsAssetTokenIndexRoute: typeof DomainsAssetTokenIndexRoute
  IpsGroupByCountryIndexRoute: typeof IpsGroupByCountryIndexRoute
  IpsGroupByIpIndexRoute: typeof IpsGroupByIpIndexRoute
  IpsGroupByNoneIndexRoute: typeof IpsGroupByNoneIndexRoute
  IpsGroupByProviderIndexRoute: typeof IpsGroupByProviderIndexRoute
  PoliciesBreachesIndexRoute: typeof PoliciesBreachesIndexRoute
  PortsGroupByNoneIndexRoute: typeof PortsGroupByNoneIndexRoute
  PortsGroupByPortIndexRoute: typeof PortsGroupByPortIndexRoute
  TechnologiesGroupByNoneIndexRoute: typeof TechnologiesGroupByNoneIndexRoute
  TechnologiesGroupByTechnologyIndexRoute: typeof TechnologiesGroupByTechnologyIndexRoute
  TechnologiesGroupByVersionIndexRoute: typeof TechnologiesGroupByVersionIndexRoute
  DomainsAssetTokenDnsIndexRoute: typeof DomainsAssetTokenDnsIndexRoute
  DomainsAssetTokenIpsIndexRoute: typeof DomainsAssetTokenIpsIndexRoute
  DomainsAssetTokenPortsIndexRoute: typeof DomainsAssetTokenPortsIndexRoute
  DomainsAssetTokenTechnologiesIndexRoute: typeof DomainsAssetTokenTechnologiesIndexRoute
  DomainsAssetTokenVulnerabilitiesIndexRoute: typeof DomainsAssetTokenVulnerabilitiesIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DnsIndexRoute: DnsIndexRoute,
  DomainsIndexRoute: DomainsIndexRoute,
  IpsIndexRoute: IpsIndexRoute,
  PortsIndexRoute: PortsIndexRoute,
  TechnologiesIndexRoute: TechnologiesIndexRoute,
  PoliciesIndexLazyRoute: PoliciesIndexLazyRoute,
  DomainsAssetTokenIndexRoute: DomainsAssetTokenIndexRoute,
  IpsGroupByCountryIndexRoute: IpsGroupByCountryIndexRoute,
  IpsGroupByIpIndexRoute: IpsGroupByIpIndexRoute,
  IpsGroupByNoneIndexRoute: IpsGroupByNoneIndexRoute,
  IpsGroupByProviderIndexRoute: IpsGroupByProviderIndexRoute,
  PoliciesBreachesIndexRoute: PoliciesBreachesIndexRoute,
  PortsGroupByNoneIndexRoute: PortsGroupByNoneIndexRoute,
  PortsGroupByPortIndexRoute: PortsGroupByPortIndexRoute,
  TechnologiesGroupByNoneIndexRoute: TechnologiesGroupByNoneIndexRoute,
  TechnologiesGroupByTechnologyIndexRoute:
    TechnologiesGroupByTechnologyIndexRoute,
  TechnologiesGroupByVersionIndexRoute: TechnologiesGroupByVersionIndexRoute,
  DomainsAssetTokenDnsIndexRoute: DomainsAssetTokenDnsIndexRoute,
  DomainsAssetTokenIpsIndexRoute: DomainsAssetTokenIpsIndexRoute,
  DomainsAssetTokenPortsIndexRoute: DomainsAssetTokenPortsIndexRoute,
  DomainsAssetTokenTechnologiesIndexRoute:
    DomainsAssetTokenTechnologiesIndexRoute,
  DomainsAssetTokenVulnerabilitiesIndexRoute:
    DomainsAssetTokenVulnerabilitiesIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dns/",
        "/domains/",
        "/ips/",
        "/ports/",
        "/technologies/",
        "/policies/",
        "/domains/$assetToken/",
        "/ips/group-by-country/",
        "/ips/group-by-ip/",
        "/ips/group-by-none/",
        "/ips/group-by-provider/",
        "/policies/breaches/",
        "/ports/group-by-none/",
        "/ports/group-by-port/",
        "/technologies/group-by-none/",
        "/technologies/group-by-technology/",
        "/technologies/group-by-version/",
        "/domains/$assetToken/dns/",
        "/domains/$assetToken/ips/",
        "/domains/$assetToken/ports/",
        "/domains/$assetToken/technologies/",
        "/domains/$assetToken/vulnerabilities/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dns/": {
      "filePath": "dns/index.tsx"
    },
    "/domains/": {
      "filePath": "domains/index.tsx"
    },
    "/ips/": {
      "filePath": "ips/index.tsx"
    },
    "/ports/": {
      "filePath": "ports/index.tsx"
    },
    "/technologies/": {
      "filePath": "technologies/index.tsx"
    },
    "/policies/": {
      "filePath": "policies/index.lazy.tsx"
    },
    "/domains/$assetToken/": {
      "filePath": "domains/$assetToken/index.tsx"
    },
    "/ips/group-by-country/": {
      "filePath": "ips/group-by-country/index.tsx"
    },
    "/ips/group-by-ip/": {
      "filePath": "ips/group-by-ip/index.tsx"
    },
    "/ips/group-by-none/": {
      "filePath": "ips/group-by-none/index.tsx"
    },
    "/ips/group-by-provider/": {
      "filePath": "ips/group-by-provider/index.tsx"
    },
    "/policies/breaches/": {
      "filePath": "policies/breaches/index.tsx"
    },
    "/ports/group-by-none/": {
      "filePath": "ports/group-by-none/index.tsx"
    },
    "/ports/group-by-port/": {
      "filePath": "ports/group-by-port/index.tsx"
    },
    "/technologies/group-by-none/": {
      "filePath": "technologies/group-by-none/index.tsx"
    },
    "/technologies/group-by-technology/": {
      "filePath": "technologies/group-by-technology/index.tsx"
    },
    "/technologies/group-by-version/": {
      "filePath": "technologies/group-by-version/index.tsx"
    },
    "/domains/$assetToken/dns/": {
      "filePath": "domains/$assetToken/dns/index.tsx"
    },
    "/domains/$assetToken/ips/": {
      "filePath": "domains/$assetToken/ips/index.tsx"
    },
    "/domains/$assetToken/ports/": {
      "filePath": "domains/$assetToken/ports/index.tsx"
    },
    "/domains/$assetToken/technologies/": {
      "filePath": "domains/$assetToken/technologies/index.tsx"
    },
    "/domains/$assetToken/vulnerabilities/": {
      "filePath": "domains/$assetToken/vulnerabilities/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
