import {
  SurfaceProjectionDomainFilters,
  SurfaceProjectionDomainSortBy,
} from "@/network/gen";
import * as amplitude from "@amplitude/analytics-browser";

export function getSpimbraUrl(props: {
  pathname: string;
  search?: {
    filters?: SurfaceProjectionDomainFilters[];
    returnUrl?: string;
    sort?: SurfaceProjectionDomainSortBy;
  };
}): string {
  const domain =
    import.meta.env.MODE === "production"
      ? "https://detectify.com"
      : "https://staging.detectify.net";
  const url = new URL(props.pathname, domain);
  const amplitudeDeviceId = amplitude.getDeviceId();
  const amplitudeSessionId = amplitude.getSessionId();

  if (amplitudeDeviceId && amplitudeSessionId) {
    url.searchParams.append("ampDeviceId", amplitudeDeviceId);
    url.searchParams.append("ampSessionId", amplitudeSessionId.toString());
  }

  if (props.search?.filters) {
    url.searchParams.append(
      "filters",
      props.search.filters
        .map((filter) => {
          const serializedValue =
            filter.value !== undefined
              ? Array.isArray(filter.value)
                ? `[${filter.value.map((filterValue) => encodeURIComponent(filterValue))?.join(",")}]`
                : encodeURIComponent(filter.value ?? "")
              : "";

          return `${filter.name}:${filter.operator}:${serializedValue}`;
        })
        .join(`|`)
    );
  }

  if (props.search?.sort) {
    url.searchParams.append(
      "sort",
      `${props.search.sort.name}:${props.search.sort.direction}`
    );
  }

  if (props.search?.returnUrl) {
    url.searchParams.append("returnUrl", props.search.returnUrl);
  }

  return url.toString();
}
