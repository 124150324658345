import { SurfaceState } from "@/network/gen";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

// These breakpoints match the ones in the Tailwind config.
export const breakpoints = {
  sm: 480,
  md: 768,
  lg: 960,
  xl: 1440,
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const surfaceStates = [
  {
    label: "Fingerprinted technologies",
    value: SurfaceState.ServiceDetected,
  },
  {
    label: "Open port",
    value: SurfaceState.OpenPorts,
  },
  {
    label: "Resolving IP",
    value: SurfaceState.ActiveIPs,
  },
  {
    label: "DNS",
    value: SurfaceState.ActiveDns,
  },
  {
    label: "Unresolvable domain",
    value: SurfaceState.NoDns,
  },
];

export function getBadgeSeverityClassName(
  title?: "Critical" | "High" | "Medium" | "Low" | "Information" | null
) {
  switch (title) {
    case "Critical":
      return "text-white bg-[#b33461]";
    case "High":
      return "text-pink-950 bg-[#ffb7d1]";
    case "Medium":
      return "text-yellow-950 bg-[#feb389]";
    case "Low":
      return "text-yellow-950 bg-[#ffe499]";
    case "Information":
      return "text-slate-700 bg-[#bfd7fc]";
    default:
      return "";
  }
}
