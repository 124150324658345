export function SkipToMainContent() {
  return (
    <a
      href="#main"
      className="sr-only top-0 z-10 focus:not-sr-only focus:fixed"
    >
      <span className="block bg-white p-4 text-sm font-medium lg:w-[12.5rem] lg:p-6">
        Skip to main content
      </span>
    </a>
  );
}
