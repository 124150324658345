import * as amplitude from "@amplitude/analytics-browser";

// The type of events you want to track.
export type Category =
  | "assets-details"
  | "create-scan-profile"
  | "dns-queries-table"
  | "domains-table"
  | "ip-group-by-country-table"
  | "ip-group-by-ip-table"
  | "ip-group-by-none-table"
  | "ip-group-by-provider-table"
  | "knowledge-base-article"
  | "main-menu"
  | "network-graph"
  | "policies"
  | "policy-breaches-table"
  | "port-group-by-none-table"
  | "port-group-by-port-table"
  | "query-builder-filter"
  | "tech-group-by-none-table"
  | "tech-group-by-tech-table"
  | "tech-group-by-version-table"
  | "table"
  | "table-column-menu";

// The specific action that is taken.
export type Action =
  | "click-add-filter-rule"
  | "click-filter"
  | "click-move-left"
  | "click-move-right"
  | "click-on-node"
  | "click-pin-left"
  | "click-pin-right"
  | "click-remove-filter-rule"
  | "click-reset-filter"
  | "click-reset-width"
  | "click-unpin"
  | "contact-support"
  | "copy-to-clipboard"
  | "create"
  | "create-scan-error"
  | "create-scan-failed"
  | "create-scan-successful"
  | "create-policy-error"
  | "create-policy-success"
  | "delete-policy-successful"
  | "delete-policy-failed"
  | "link-to-billing-page"
  | "link-to-contact-support"
  | "open-create-policy-modal"
  | "open-kb-article"
  | "open-modal"
  | "open-product-changes"
  | "open-read-more-link"
  | "redirection"
  | "used-filters"
  | "used-search"
  | "used-suggested-filters"
  | "viewed-error-state"
  | "viewed-no-data-empty-state"
  | "viewed-suggested-filters";

// The title of the element that is being interacted with, to aid with analysis.
export type Name =
  | "asn-count-link"
  | "active-reached-max-capacity-without-payment"
  | "application-scan-create"
  | "assets-details"
  | "country-count-link"
  | "create-scan-profile-modal"
  | "deep-scan-tab"
  | "dns-count-link"
  | "dns-queries-table"
  | "domain-link"
  | "domains-count-link"
  | "domains-table"
  | "empty-state"
  | "empty-state-upgrade"
  | "from-auto-scaling-reached-capacity"
  | "ip-count-link"
  | "ip-group-by-country-table"
  | "ip-group-by-ip-table"
  | "ip-group-by-none-table"
  | "ip-group-by-provider-table"
  | "policy-breaches-table"
  | "ports-count-link"
  | "port-group-by-none-table"
  | "port-group-by-port-table"
  | "provider-count-link"
  | "surface-state-tooltip"
  | "tech-count-link"
  | "tech-group-by-none-table"
  | "tech-group-by-tech-table"
  | "tech-group-by-version-table"
  | "versions-count-link"
  | "trial-reached-max-capacity";

type Event = {
  attributes?: Record<string, string | number>;
  action: Action;
  category?: Category;
  name?: Name;
};

export function trackEvent(event: Event) {
  amplitude.track(event.action, {
    category: event.category,
    documentTitle: document.title,
    href: window.location,
    name: event.name,
    ...event.attributes,
  });
}
