import { cn } from "@/lib/utils";
import { FileRoutesByFullPath } from "@/routeTree.gen";
import { Link, useLocation, useMatchRoute } from "@tanstack/react-router";
import {
  ChevronDownIcon,
  ExternalLink,
  ShieldAlert,
  ShieldBan,
  ShieldCheck,
  ShieldX,
} from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../collapsible/Collapsible";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../dialog/Dialog";
import { trackEvent } from "@/lib/trackEvent";
import { getSpimbraUrl } from "@/lib/spimbra";
import { usePermission } from "@/hooks/usePermission";

const classNames = {
  heading:
    "uppercase mt-[1.875rem] mb-1 text-[.625rem] px-6 tracking-[.2em] text-neutral-700 font-medium",
  item: {
    default:
      "font-semibold transition-colors text-sm py-[.625rem] px-6 leading-[1.2em] block focus:outline-none focus-visible:bg-gray-200 text-neutral-700 hover:bg-gray-200",
    external: "!flex gap-1 items-center !py-2",
    selected:
      "bg-neutral-800 text-white before:content-['/'] before:absolute before:ml-[-.625rem] relative focus-visible:bg-neutral-600 hover:bg-neutral-800",
    subnav: "font-medium text-xs pl-9 py-[.5625rem]",
  },
};

type MainNavigationProps = {
  className?: string;
};

const isVulnerabilitiesRoute = (pathname: string) =>
  [
    "/vulnerabilities/open",
    "/vulnerabilities/fixed",
    "/vulnerabilities/accepted-risk",
    "/vulnerabilities/false-positive",
  ].includes(pathname);

export function MainNavigation(props: MainNavigationProps) {
  const location = useLocation();
  const [isVulnerabilitiesOpen, setIsVulnerabilitiesOpen] = useState(
    isVulnerabilitiesRoute(location.pathname)
  );
  const permissions = usePermission();
  const canManageIntegrations =
    !permissions.loading && permissions.canManageIntegrations;
  const canManageApi = !permissions.loading && permissions.canManageApi;

  useEffect(() => {
    setIsVulnerabilitiesOpen(isVulnerabilitiesRoute(location.pathname));
  }, [location.pathname]);

  return (
    <nav
      className={cn(
        "flex h-full flex-col justify-between overflow-y-auto border-t",
        props.className
      )}
    >
      <div className="pt-4">
        <MainNavigationItem href={getSpimbraUrl({ pathname: "/app/overview" })}>
          Overview
        </MainNavigationItem>

        {/* Attack Surface */}
        <h4 className={classNames.heading}>Attack Surface</h4>
        <MainNavigationItem
          to="/domains"
          search={{
            sorting: [
              {
                desc: false,
                id: "DomainName",
              },
            ],
          }}
        >
          Domains
        </MainNavigationItem>

        <MainNavigationItem to={"/dns"}>DNS</MainNavigationItem>
        <MainNavigationItem to={"/ips"}>IP Addresses</MainNavigationItem>
        <MainNavigationItem to={"/ports"}>Ports</MainNavigationItem>
        <MainNavigationItem to={"/technologies"}>
          Technologies
        </MainNavigationItem>

        {/* Findings */}
        <h4 className={classNames.heading}>Findings</h4>
        <Collapsible
          className="group"
          open={isVulnerabilitiesOpen}
          onOpenChange={setIsVulnerabilitiesOpen}
        >
          <CollapsibleTrigger
            className={cn(
              classNames.item.default,
              "flex w-full items-center justify-between"
            )}
          >
            Vulnerabilities
            <ChevronDownIcon className="h-4 w-4 transition-transform duration-300 group-data-[state=open]:rotate-[-180deg]" />
          </CollapsibleTrigger>
          <CollapsibleContent>
            <MainNavigationItem
              href={getSpimbraUrl({ pathname: "/app/vulnerabilities/open" })}
              subnav
            >
              <div className="flex items-center gap-2">
                <ShieldAlert className="h-4 w-4" /> Open
              </div>
            </MainNavigationItem>
            <MainNavigationItem
              href={getSpimbraUrl({ pathname: "/app/vulnerabilities/fixed" })}
              subnav
            >
              <div className="flex items-center gap-2">
                <ShieldCheck className="h-4 w-4" /> Fixed
              </div>
            </MainNavigationItem>
            <MainNavigationItem
              href={getSpimbraUrl({
                pathname: "/app/vulnerabilities/accepted-risk",
              })}
              subnav
            >
              <div className="flex items-center gap-2">
                <ShieldBan className="h-4 w-4" /> Accepted Risk
              </div>
            </MainNavigationItem>
            <MainNavigationItem
              href={getSpimbraUrl({
                pathname: "/app/vulnerabilities/false-positive",
              })}
              subnav
            >
              <div className="flex items-center gap-2">
                <ShieldX className="h-4 w-4" /> False Positive
              </div>
            </MainNavigationItem>
          </CollapsibleContent>
        </Collapsible>

        <MainNavigationItem to={"/policies"}>Policies</MainNavigationItem>
        <MainNavigationItem href={getSpimbraUrl({ pathname: "/results" })}>
          App Scan Reports
        </MainNavigationItem>

        {/* Scan Configurations */}
        <h4 className={classNames.heading}>Scan configuration</h4>
        <MainNavigationItem
          href={getSpimbraUrl({ pathname: "/app/surface-monitoring" })}
        >
          Surface Monitoring
        </MainNavigationItem>
        <MainNavigationItem
          href={getSpimbraUrl({ pathname: "/app/application-scanning" })}
        >
          Application Scanning
        </MainNavigationItem>

        {/* Connections */}
        <h4 className={classNames.heading}>Connections</h4>
        <MainNavigationItem
          href={getSpimbraUrl({ pathname: "/app/connectors" })}
        >
          DNS Connectors
        </MainNavigationItem>
        {canManageIntegrations && (
          <MainNavigationItem
            href={getSpimbraUrl({ pathname: "/app/integrations" })}
          >
            Integrations
          </MainNavigationItem>
        )}
        {canManageApi && (
          <MainNavigationItem
            href={getSpimbraUrl({ pathname: "/app/api-keys" })}
          >
            API
          </MainNavigationItem>
        )}
      </div>

      <div className="pb-6">
        {/* Support */}
        <h4 className={classNames.heading}>Support</h4>
        <MainNavigationItem
          href="https://changes.detectify.com"
          external
          onClick={() =>
            trackEvent({
              action: "open-product-changes",
              category: "main-menu",
            })
          }
        >
          Product updates
        </MainNavigationItem>
        <MainNavigationItem
          href="https://changes.detectify.com/en?category=new+tests"
          external
        >
          New tests
        </MainNavigationItem>
        <MainNavigationItem href="https://support.detectify.com" external>
          Knowledge base
        </MainNavigationItem>
        <Dialog>
          <DialogTrigger
            className={cn(classNames.item.default, "w-full text-left")}
          >
            Contact
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Get in touch</DialogTitle>
            </DialogHeader>
            <p>
              To contact us, you can send an email to{" "}
              <a className="text-link" href="mailto:support@detectify.com">
                support@detectify.com
              </a>
              .
            </p>
          </DialogContent>
        </Dialog>
      </div>
    </nav>
  );
}

type MainNavigationItemProps = {
  children: React.ReactNode;
  external?: boolean;
  href?: string;
  onClick?: () => void;
  to?: keyof FileRoutesByFullPath;
  search?: any;
  subnav?: boolean;
};

function MainNavigationItem(props: MainNavigationItemProps) {
  const matchRoute = useMatchRoute();

  if (props.href) {
    return (
      <a
        className={cn(
          classNames.item.default,
          props.external && classNames.item.external,
          props.subnav && classNames.item.subnav
        )}
        href={props.href}
        onClick={props.onClick}
        rel={props.external ? "noreferrer noopener" : undefined}
        target={props.external ? "_blank" : undefined}
      >
        {props.children} {props.external && <ExternalLink className="w-4" />}
      </a>
    );
  }

  if (props.to) {
    const isSelected = props?.to && matchRoute({ to: props.to, fuzzy: true });

    return (
      <Link
        className={cn(
          classNames.item.default,
          isSelected && classNames.item.selected
        )}
        onClick={props.onClick}
        to={props.to}
        search={props.search}
      >
        {props.children}
      </Link>
    );
  }
}
